import axios from "axios";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { Edit, Delete } from "@material-ui/icons";
import CancelIcon from "@material-ui/icons/Cancel";
import CreateProject from "./CreateProject";
import ReactLoading from "react-loading";
import { uniq } from "lodash";

const hostname = "https://ptsviewer.co.uk:10000";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

export default function CollapsibleTable() {
  const [projectList, setProjectList] = useState([]);
  const [buffering, setBuffering] = useState(false);

  useEffect(() => {
    setBuffering(true);
    axios
      .get(`${hostname}/getProjects`)
      .then((response) => {
        if (response.status === 200) {
          const vData = [];
          response.data.forEach((val) => {
            vData.push(val.name);
          });
          setProjectList(vData);
        } else {
          console.log("error occurred");
        }
        setBuffering(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const [open, setOpen] = React.useState(false);
  const [currentFiles, setCurrentFiles] = React.useState([]);
  const [currentProjectName, setCurrentProjectName] = React.useState("");
  const classes = useRowStyles();

  const setFiles = (project) => {
    axios
      .post(`${hostname}/getCSVFiles`, {
        projectName: project,
      })
      .then((response) => {
        //console.log("Here...", response.data);
        getNetworkSHPFiles(project, response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getNetworkSHPFiles = (project, data) => {
    // axios
    //   .post(`${hostname}/getProjectLayerWFS`, {
    //     projectName: project,
    //   })
    //   .then((response) => {
    //     const SHPFiles = processSHPResponse(project, response.data);
    //     const DataSHP = dataSHPResponse(project);
    //     setCurrentFiles({ ...data, ...SHPFiles, ...DataSHP });
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });

    if(project === "Anglesey") project = "Anglesey_1";

    var config = {
      method: 'get',
      url: `https://ptsviewer.com/geoserver/rest/workspaces/${project}/datastores`,
      headers: { 
        'Authorization': 'Basic YWRtaW46Z2Vvc2VydmVy'
      }
    };
    
    axios(config)
    .then(function (response) {
      //console.log(JSON.stringify(response.data));
      const SHPFiles = processSHPResponse(response.data);
      dataSHPResponse(project, data, SHPFiles);
      //setCurrentFiles({ ...data, ...SHPFiles });
    })
    .catch(function (error) {
      console.log(error);
    });
  };

  const dataSHPResponse = (project, data, SHPFiles) => {
    var output = [];
    console.log("ListProject.js getProjectLayers");
    axios
      .post(`${hostname}/getProjectLayers`, {
        projectName: project,
      })
      .then((response) => {
        //console.log(response.data);
        let res = response?.data?.[0]?.children.find(child => child.id === 100);
        if(res !== undefined) {
          res.children.forEach((child) => {
            output.push(child.jsonData.layerName);
          });
        }
        const dataSHP = {Data : output};
        setCurrentFiles({ ...data,...SHPFiles, ...dataSHP });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const processSHPResponse = (data) => {
    let output = [];
    if(data["dataStores"]["dataStore"]){
      Object.values(data["dataStores"]["dataStore"]).forEach((child) => {
        output.push(child.name);
      });
    }
    return { Network: uniq(output) };
  };

  const deleteProject = (projectName) => {
    axios
      .post(`${hostname}/deleteProject`, {
        projectName,
      })
      .then((response) => {
        projectList.splice(projectList.indexOf(projectName), 1);
        setProjectList([...projectList]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (buffering)
    return <ReactLoading color={"#00ACC1"} height="10%" width="10%" />;

  const renderTableRows = (list) => {
    return (
      <TableBody>
        {list.map((name, idx) => (
          <React.Fragment>
            <TableRow className={classes.root}>
              <TableCell>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => {
                    setOpen(open === false ? idx : false);
                    if (!open) {
                      setFiles(name);
                      setCurrentProjectName(name);
                    }
                  }}
                >
                  {open === idx ? (
                    <CancelIcon />
                  ) : (
                    <Edit style={{ color: "skyblue" }} />
                  )}
                </IconButton>
              </TableCell>
              <TableCell component="th" scope="row">
                {name}
              </TableCell>
              <TableCell>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => deleteProject(name)}
                >
                  <Delete style={{ color: "red" }} />
                </IconButton>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                style={{ paddingBottom: 0, paddingTop: 0 }}
                colSpan={6}
              >
                <Collapse in={open === idx} timeout="auto" unmountOnExit>
                  <Box margin={1}>
                    <Typography variant="h6" gutterBottom component="div">
                      Update project Details
                    </Typography>
                    <CreateProject
                      mode="edit"
                      data={currentFiles}
                      name={currentProjectName}
                    />
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </React.Fragment>
        ))}
      </TableBody>
    );
  };

  return (
    <TableContainer>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Edit</TableCell>
            <TableCell align="left">Project Name</TableCell>
            <TableCell align="left">Delete</TableCell>
          </TableRow>
        </TableHead>
        {renderTableRows(projectList)}
      </Table>
    </TableContainer>
  );
}
