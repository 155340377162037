import axios from "axios";
import React, { Component } from "react";
import { Box } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Button, TextField, Icon } from "@material-ui/core";
// import ReactLoading from "react-loading";
// import Snack from "components/Snackbar/Snackbar.js";
import UploadService from "../../services/uploadDocs";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import PostAddIcon from "@material-ui/icons/PostAdd";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import {cloneDeep} from "lodash";

const hostname = "https://ptsviewer.co.uk:10000";
const shpHostName = "https://ptsviewer.com:10090";

const useStyles = (theme) => ({
  errorText: {
    color: "red",
    margin: "0 5px",
  },
  errorBorder: {
    border: "1px solid red",
    borderRadius: "5px",
  },
  field: {
    marginTop: "15px",
    "&:input": {
      padding: "10.5px 14px",
    },
  },
  saveButtonContainer: {
    textAlign: "center",
    marginTop: "15px",
  },
  label: {
    fontFamily: ' "Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: "400",
    fontSize: "17px",
    marginTop: "10px",
    display: "inline-block",
  },
  container: {
    display: "flex",
    alignItems: "center",
  },
  largeBtn: {
    padding: "1.5px 20px",
    borderRadius: "50px",
  },
  chip: {
    margin: theme.spacing(0.5),
    width: "fit-content",
    marginTop: "20px",
    marginRight: "10px",
  },
  deletedChip: {
    margin: theme.spacing(0.5),
    width: "fit-content",
    marginTop: "20px",
    marginRight: "10px",
    background: "red",
    color: "white",
  },
  forUpdateChip: {
    margin: theme.spacing(0.5),
    width: "fit-content",
    marginTop: "20px",
    marginRight: "10px",
    background: "skyblue",
    color: "black",
  },
  filesContainer: {
    padding: "15px 0 25px 0px",
    borderBottom: "1px solid gainsboro",
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
});

class DocsUploadComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentFile: undefined,
      uploading: false,
      hasError: false,
      projectName: props?.name || "",

      DataSHPFiles: [],
      ParameterCSVFiles: [],
      SHPFiles: [],
      SCRIMFiles: [],
      CSVFiles_Section: [],
      CSVFiles_Sample: [],
      CSVFiles_Scheme: [],
      CSVFiles_Import: [],
      CSVFiles_Scrim: [],
      CSVFiles_Modeling: [],
      SearchFiles: [],

      new_CSVFiles_Section: [],
      new_CSVFiles_Sample: [],
      new_CSVFiles_Scheme: [],
      new_CSVFiles_Import: [],
      new_CSVFiles_Scrim: [],
      new_CSVFiles_Modeling: [],
      new_SHPFiles: [],
      new_SCRIMFiles: [],
      new_DataSHPFiles: [],
      new_ParameterCSVFiles: [],
      new_SearchFiles: [],

      deleted_SHPFiles: [],
      deleted_SCRIMFiles: [],
      deleted_DataSHPFiles: [],
      deleted_ParameterCSVFiles: [],
      deleted_CSVFiles_Section: [],
      deleted_CSVFiles_Sample: [],
      deleted_CSVFiles_Scheme: [],
      deleted_CSVFiles_Import: [],
      deleted_CSVFiles_Scrim: [],
      deleted_CSVFiles_Modeling: [],
      deleted_SearchFiles: [],

      fileType: "csv",

      progressInfos_Sample: [],
      progressInfos_Section: [],
      progressInfos_Scheme: [],
      progressInfos_Shp: [],
      progressInfo_Import: [],
      progressInfos_Scrim: [],
      progressInfos_Modeling: [],
      progressInfos_DataShp: [],
      progressInfos_SCRIM_Report: [],
      progressInfos_ParameterCSV: [],
      progressInfos_SearchFile: [],

      message: [],
      fileInfos: [],
      open: false,
    };
  }

  componentWillReceiveProps(props) {
    this.setState({
      DataSHPFiles: props?.data["Data"] || [],
      ParameterCSVFiles: props?.data["ParameterData"] || [],
      SHPFiles: props?.data?.["Network"] || [],
      SCRIMFiles: props?.data?.["SCRIMReport"] || [],
      CSVFiles_Section: props?.data?.["Section"] || [],
      CSVFiles_Sample: props?.data?.["Sample"] || [],
      CSVFiles_Scheme: props?.data?.["Scheme-Lane"] || [],
      CSVFiles_Import: props?.data?.["ImportData"] || [],
      CSVFiles_Scrim: props?.data?.["SCRIMData"] || [],
      CSVFiles_Modeling: props?.data?.["Modeling"] || [],
      SearchFiles: props?.data?.["SearchFile"] || [],
    });
  }

  selectCSVFile_Section(event) {
    const { CSVFiles_Section, new_CSVFiles_Section } = this.state;
    const { mode } = this.props;
    if (mode === "edit") {
      this.setState({
        new_CSVFiles_Section: [...new_CSVFiles_Section, ...event.target.files],
      });
    } else {
      this.setState({
        CSVFiles_Section: [...CSVFiles_Section, ...event.target.files],
      });
    }
  }

  selectCSVFile_Sample(event) {
    const { CSVFiles_Sample, new_CSVFiles_Sample } = this.state;
    const { mode } = this.props;
    if (mode === "edit") {
      this.setState({
        new_CSVFiles_Sample: [...new_CSVFiles_Sample, ...event.target.files],
      });
    } else {
      this.setState({
        CSVFiles_Sample: [...CSVFiles_Sample, ...event.target.files],
      });
    }
  }

  selectCSVFile_Scheme(event) {
    const { CSVFiles_Scheme, new_CSVFiles_Scheme } = this.state;
    const { mode } = this.props;
    if (mode === "edit") {
      this.setState({
        new_CSVFiles_Scheme: [...new_CSVFiles_Scheme, ...event.target.files],
      });
    } else {
      this.setState({
        CSVFiles_Scheme: [...CSVFiles_Scheme, ...event.target.files],
      });
    }
  }

  selectCSVFile_Import(event) {
    const { CSVFiles_Import, new_CSVFiles_Import } = this.state;
    const { mode } = this.props;
    if (mode === "edit") {
      this.setState({
        new_CSVFiles_Import: [...new_CSVFiles_Import, ...event.target.files],
      });
    } else {
      this.setState({
        CSVFiles_Import: [...CSVFiles_Import, ...event.target.files],
      });
    }
  }

  selectSHPFile(event) {
    const { SHPFiles, new_SHPFiles } = this.state;
    const { mode } = this.props;
    if (mode === "edit") {
      this.setState({
        new_SHPFiles: [...new_SHPFiles, ...event.target.files],
      });
    } else {
      this.setState({
        SHPFiles: [...SHPFiles, ...event.target.files],
      });
    }
  }
  selectSCRIMFile(event) {
    const { SCRIMFiles, new_SCRIMFiles } = this.state;
    const { mode } = this.props;
    if (mode === "edit") {
      this.setState({
        new_SCRIMFiles: [...new_SCRIMFiles, ...event.target.files],
      });
    } else {
      this.setState({
        SCRIMFiles: [...SCRIMFiles, ...event.target.files],
      });
    }
  }

  selectDataSHPFile(event) {
    const { DataSHPFiles, new_DataSHPFiles } = this.state;
    const { mode } = this.props;
    if (mode === "edit") {
      this.setState({
        new_DataSHPFiles: [...new_DataSHPFiles, ...event.target.files],
      });
    } else {
      this.setState({
        DataSHPFiles: [...DataSHPFiles, ...event.target.files],
      });
    }
  }

  selectParameterCSVFile(event) {
    const { ParameterCSVFiles, new_ParameterCSVFiles } = this.state;
    const { mode } = this.props;
    if (mode === "edit") {
      this.setState({
        new_ParameterCSVFiles: [
          ...new_ParameterCSVFiles,
          ...event.target.files,
        ],
      });
    } else {
      this.setState({
        ParameterCSVFiles: [...ParameterCSVFiles, ...event.target.files],
      });
    }
  }

  selectSearchFile(event) {
    const { SearchFiles, new_SearchFiles } = this.state;
    const { mode } = this.props;
    if (mode === "edit") {
      this.setState({
        new_SearchFiles: [...new_SearchFiles, ...event.target.files],
      });
    } else {
      this.setState({
        SearchFiles: [...SearchFiles, ...event.target.files],
      });
    }
  }

  selectCSVFile_Scrim(event) {
    const { CSVFiles_Scrim, new_CSVFiles_Scrim } = this.state;
    const { mode } = this.props;
    if (mode === "edit") {
      this.setState({
        new_CSVFiles_Scrim: [...new_CSVFiles_Scrim, ...event.target.files],
      });
    } else {
      this.setState({
        CSVFiles_Scrim: [...CSVFiles_Scrim, ...event.target.files],
      });
    }
  }
  selectCSVFile_Modeling(event) {
    const { CSVFiles_Modeling, new_CSVFiles_Modeling } = this.state;
    const { mode } = this.props;
    if (mode === "edit") {
      this.setState({
        new_CSVFiles_Modeling: [...new_CSVFiles_Modeling, ...event.target.files],
      });
    } else {
      this.setState({
        CSVFiles_Modeling: [...CSVFiles_Modeling, ...event.target.files],
      });
    }
  }

  async upload(hostname, endPoint, folderName, idx, file) {
    let _progressInfos;
    if (folderName === "Sample") {
      _progressInfos = [...this.state.progressInfos_Sample];
    } else if (folderName === "Section") {
      _progressInfos = [...this.state.progressInfos_Section];
    } else if (folderName === "Scheme-Lane") {
      _progressInfos = [...this.state.progressInfos_Scheme];
    } else if (folderName === "SHP") {
      _progressInfos = [...this.state.progressInfos_Shp];
    } else if (folderName === "ImportData") {
      _progressInfos = [...this.state.progressInfos_Import];
    } else if (folderName === "SCRIMData") {
      _progressInfos = [...this.state.progressInfos_Scrim];
    } else if (folderName === "Modeling") {
      _progressInfos = [...this.state.progressInfos_Modeling];
    } else if (folderName === "Data") {
      _progressInfos = [...this.state.progressInfos_DataShp];
    } else if (folderName === "ParameterData") {
      _progressInfos = [...this.state.progressInfos_ParameterCSV];
    } else if (folderName === "SearchFile") {
      _progressInfos = [...this.state.progressInfos_SearchFile];
    } else if (folderName === "SCRIMReport") {
      _progressInfos = [...this.state.progressInfos_SCRIM_Report];
    }

    await UploadService.upload(
      hostname,
      endPoint,
      this.state.projectName,
      folderName,
      file,
      (event) => {
        _progressInfos[idx].percentage = Math.round(
          (100 * event.loaded) / event.total
        );

        this.setState({
          _progressInfos,
        });
      }
    )
      .then(() => {
        _progressInfos[idx].status = "Completed";
        this.setState({
          _progressInfos,
        });
      })
      .catch(() => {
        _progressInfos[idx].status = "Failed";
        _progressInfos[idx].percentage = 0;
        this.setState((prev) => {
          let nextMessage = [
            ...prev.message,
            "Could not upload the file: " + file.name,
          ];
          return {
            message: nextMessage,
          };
        });
      });
  }

  uploadFiles(hostname, endPoint, folderName, fileArray) {
    const selectedFiles = fileArray;
    let _progressInfos = [];
    for (let i = 0; i < selectedFiles.length; i++) {
      _progressInfos.push({
        percentage: 0,
        fileName: selectedFiles[i].name,
        status: null,
      });
    }
    if (folderName === "Sample") {
      this.setState({ progressInfos_Sample: _progressInfos }, async () => {
        for (let i = 0; i < selectedFiles.length; i++) {
          await this.upload(
            hostname,
            endPoint,
            folderName,
            i,
            selectedFiles[i]
          );
        }
      });
    } else if (folderName === "Section") {
      this.setState({ progressInfos_Section: _progressInfos }, async () => {
        for (let i = 0; i < selectedFiles.length; i++) {
          await this.upload(
            hostname,
            endPoint,
            folderName,
            i,
            selectedFiles[i]
          );
        }
      });
    } else if (folderName === "Scheme-Lane") {
      this.setState({ progressInfos_Scheme: _progressInfos }, async () => {
        for (let i = 0; i < selectedFiles.length; i++) {
          await this.upload(
            hostname,
            endPoint,
            folderName,
            i,
            selectedFiles[i]
          );
        }
      });
    } else if (folderName === "SHP") {
      this.setState({ progressInfos_Shp: _progressInfos }, async () => {
        for (let i = 0; i < selectedFiles.length; i++) {
          await this.upload(
            hostname,
            endPoint,
            folderName,
            i,
            selectedFiles[i]
          );
        }
      });
    } else if (folderName === "ImportData") {
      this.setState({ progressInfos_Import: _progressInfos }, async () => {
        for (let i = 0; i < selectedFiles.length; i++) {
          await this.upload(
            hostname,
            endPoint,
            folderName,
            i,
            selectedFiles[i]
          );
        }
      });
    } else if (folderName === "SCRIMData") {
      this.setState({ progressInfos_Scrim: _progressInfos }, async () => {
        for (let i = 0; i < selectedFiles.length; i++) {
          await this.upload(
            hostname,
            endPoint,
            folderName,
            i,
            selectedFiles[i]
          );
        }
      });
    } else if (folderName === "Modeling") {
      this.setState({ progressInfos_Modeling: _progressInfos }, async () => {
        for (let i = 0; i < selectedFiles.length; i++) {
          await this.upload(
            hostname,
            endPoint,
            folderName,
            i,
            selectedFiles[i]
          );
        }
      });
    } else if (folderName === "Data") {
      this.setState({ progressInfos_DataShp: _progressInfos }, async () => {
        for (let i = 0; i < selectedFiles.length; i++) {
          await this.upload(
            hostname,
            endPoint,
            folderName,
            i,
            selectedFiles[i]
          );
        }
      });
    } else if (folderName === "ParameterData") {
      this.setState(
        { progressInfos_ParameterCSV: _progressInfos },
        async () => {
          for (let i = 0; i < selectedFiles.length; i++) {
            await this.upload(
              hostname,
              endPoint,
              folderName,
              i,
              selectedFiles[i]
            );
          }
        }
      );
    } else if (folderName === "SearchFile") {
      this.setState({ progressInfos_SearchFile: _progressInfos }, async () => {
        for (let i = 0; i < selectedFiles.length; i++) {
          await this.upload(
            hostname,
            endPoint,
            folderName,
            i,
            selectedFiles[i]
          );
        }
      });
    } else if (folderName === "SCRIMReport") {
      this.setState({ progressInfos_SCRIM_Report: _progressInfos }, async () => {
        for (let i = 0; i < selectedFiles.length; i++) {
          await this.upload(
            hostname,
            endPoint,
            folderName,
            i,
            selectedFiles[i]
          );
        }
      });
    }
  }

  async deleteFiles(endpoint, projectName, folderName, fileName) {
    const dataToSend = {
      projectName: projectName,
      data: folderName + "/" + fileName,
    };
    let response = await axios.delete(hostname + endpoint, {
      data: dataToSend,
    });
  }

  deleteStore(projectName, fileName) {
    if(projectName === "Anglesey") projectName = "Anglesey_1";
    var config = {
      method: "delete",
      url: `https://ptsviewer.com/geoserver/rest/workspaces/${projectName}/datastores/${fileName}?recurse=true`,
      headers: {
        Authorization: "Basic YWRtaW46Z2Vvc2VydmVy",
      },
    };

    axios(config)
      .then(function (response) {
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  createProject(mode) {
    if (mode === "edit") {
      const {
        projectName,
        new_CSVFiles_Section,
        new_CSVFiles_Sample,
        new_CSVFiles_Scheme,
        new_CSVFiles_Import,
        new_CSVFiles_Scrim,
        new_CSVFiles_Modeling,
        new_SHPFiles,
        new_SCRIMFiles,
        new_DataSHPFiles,
        new_ParameterCSVFiles,
        new_SearchFiles,
        deleted_CSVFiles_Section,
        deleted_CSVFiles_Sample,
        deleted_CSVFiles_Scheme,
        deleted_CSVFiles_Import,
        deleted_CSVFiles_Scrim,
        deleted_CSVFiles_Modeling,
        deleted_DataSHPFiles,
        deleted_ParameterCSVFiles,
        deleted_SearchFiles,
        deleted_SHPFiles,
        deleted_SCRIMFiles,
        DataSHPFiles,
        ParameterCSVFiles,
        SearchFiles,
        SHPFiles,
        SCRIMFiles,
        CSVFiles_Section,
        CSVFiles_Sample,
        CSVFiles_Scheme,
        CSVFiles_Import,
        CSVFiles_Scrim,
        CSVFiles_Modeling,
      } = this.state;

      console.log("new_search_files", new_SearchFiles);

      for (let i = 0; i < deleted_CSVFiles_Section.length; i++) {
        this.deleteFiles("/deleteFile",projectName,"Section",deleted_CSVFiles_Section[i]);
        CSVFiles_Section.splice(CSVFiles_Section.indexOf(deleted_CSVFiles_Section[i]),1);
      }

      for (let i = 0; i < deleted_CSVFiles_Sample.length; i++) {
        this.deleteFiles("/deleteFile",projectName,"Sample",deleted_CSVFiles_Sample[i]);
        CSVFiles_Sample.splice(CSVFiles_Sample.indexOf(deleted_CSVFiles_Sample[i]),1);
      }

      for (let i = 0; i < deleted_CSVFiles_Scheme.length; i++) {
        this.deleteFiles("/deleteFile",projectName,"Scheme-Lane",deleted_CSVFiles_Scheme[i]);
        CSVFiles_Scheme.splice(CSVFiles_Scheme.indexOf(deleted_CSVFiles_Scheme[i]),1);
      }

      for (let i = 0; i < deleted_CSVFiles_Import.length; i++) {
        this.deleteFiles("/deleteFile",projectName,"ImportData",deleted_CSVFiles_Import[i]);
        CSVFiles_Import.splice(CSVFiles_Import.indexOf(deleted_CSVFiles_Import[i]),1);
      }

      for (let i = 0; i < deleted_CSVFiles_Scrim.length; i++) {
        this.deleteFiles("/deleteFile",projectName,"SCRIMData",deleted_CSVFiles_Scrim[i]);
        CSVFiles_Scrim.splice(CSVFiles_Scrim.indexOf(deleted_CSVFiles_Scrim[i]),1);
      }

      for (let i = 0; i < deleted_CSVFiles_Modeling.length; i++) {
        this.deleteFiles("/deleteFile",projectName,"Modeling",deleted_CSVFiles_Modeling[i]);
        CSVFiles_Modeling.splice(CSVFiles_Modeling.indexOf(deleted_CSVFiles_Modeling[i]),1);
      }

      for (let i = 0; i < deleted_DataSHPFiles.length; i++) {
        this.deleteStore(projectName, deleted_DataSHPFiles[i]);
        this.deleteFiles("/deleteFile",projectName,"Data",deleted_DataSHPFiles[i]);
        DataSHPFiles.splice(DataSHPFiles.indexOf(deleted_DataSHPFiles[i]),1);
      }

      for (let i = 0; i < deleted_ParameterCSVFiles.length; i++) {
        this.deleteFiles("/deleteFile",projectName,"ParameterData",deleted_ParameterCSVFiles[i]);
        ParameterCSVFiles.splice(ParameterCSVFiles.indexOf(deleted_ParameterCSVFiles[i]),1);
      }

      for(let i = 0; i < deleted_SearchFiles.length; i++){
        this.deleteFiles("/deleteFile",projectName,"SearchFile",deleted_SearchFiles[i]);
        SearchFiles.splice(SearchFiles.indexOf(deleted_SearchFiles[i]),1);
      }

      for (let i = 0; i < deleted_SHPFiles.length; i++) {
        this.deleteStore(projectName, deleted_SHPFiles[i]);
        this.deleteFiles("/deleteFile",projectName,"SHP",deleted_SHPFiles[i]);
        SHPFiles.splice(SHPFiles.indexOf(deleted_SHPFiles[i]),1);
      }
      for (let i = 0; i < deleted_SCRIMFiles.length; i++) {
        this.deleteStore(projectName, deleted_SCRIMFiles[i]);
        this.deleteFiles("/deleteFile",projectName,"SCRIMReport",deleted_SCRIMFiles[i]);
        SCRIMFiles.splice(SCRIMFiles.indexOf(deleted_SCRIMFiles[i]),1);
      }

      this.setState({
        deleted_CSVFiles_Section: cloneDeep([]),
        deleted_CSVFiles_Sample: cloneDeep([]),
        deleted_CSVFiles_Scheme: cloneDeep([]),
        deleted_CSVFiles_Import: cloneDeep([]),
        deleted_CSVFiles_Scrim: cloneDeep([]),
        deleted_CSVFiles_Modeling: cloneDeep([]),
        deleted_DataSHPFiles: cloneDeep([]),
        deleted_DataSCRIMFiles: cloneDeep([]),
        deleted_ParameterCSVFiles: cloneDeep([]),
        deleted_SearchFiles: cloneDeep([]),
        DataSHPFiles,
        ParameterCSVFiles,
        SearchFiles,
        SHPFiles,
        SCRIMFiles,
        CSVFiles_Section,
        CSVFiles_Sample,
        CSVFiles_Scheme,
        CSVFiles_Import,
        CSVFiles_Scrim,
        CSVFiles_Modeling,
      });

      this.uploadFiles(hostname,"/uploadFile","Section",new_CSVFiles_Section);
      this.uploadFiles(hostname, "/uploadFile", "Sample", new_CSVFiles_Sample);
      this.uploadFiles(hostname,"/uploadFile","Scheme-Lane",new_CSVFiles_Scheme);
      this.uploadFiles(hostname,"/uploadFile","ImportData",new_CSVFiles_Import);
      this.uploadFiles(hostname,"/uploadFile","SCRIMData",new_CSVFiles_Scrim);
      this.uploadFiles(hostname,"/uploadFile","SCRIMReport",new_SCRIMFiles);
      this.uploadFiles(hostname,"/uploadFile","Modeling",new_CSVFiles_Modeling);
      this.uploadFiles(shpHostName, "/uploadSHP", "SHP", new_SHPFiles);
      this.uploadFiles(shpHostName, "/uploadSHP", "Data", new_DataSHPFiles);
      this.uploadFiles(hostname,"/uploadFile","ParameterData",new_ParameterCSVFiles);
      this.uploadFiles(hostname, "/uploadFile", "SearchFile", new_SearchFiles);
    } else {
      this.setState({
        open: true,
        uploadMessage: "Upload Started..",
      });

      const {
        projectName,
        CSVFiles_Section,
        CSVFiles_Sample,
        CSVFiles_Scheme,
        CSVFiles_Import,
        CSVFiles_Scrim,
        SCRIMFiles,
        CSVFiles_Modeling,
        SHPFiles,
        DataSHPFiles,
        ParameterCSVFiles,
        SearchFiles,
      } = this.state;

      const data = {
        name: projectName,
      };

      axios
        .post(`${hostname}/addProject`, data)
        .then((response) => {
          //Now upload CSV and SHP files
          this.uploadFiles(hostname,"/uploadFile","Section",CSVFiles_Section);
          this.uploadFiles(hostname, "/uploadFile", "Sample", CSVFiles_Sample);
          this.uploadFiles(hostname,"/uploadFile","Scheme-Lane",CSVFiles_Scheme);
          this.uploadFiles(hostname,"/uploadFile","ImportData",CSVFiles_Import);
          this.uploadFiles(hostname,"/uploadFile","SCRIMData",CSVFiles_Scrim);
          this.uploadFiles(hostname,"/uploadFile","SCRIMReport",SCRIMFiles);
          this.uploadFiles(hostname,"/uploadFile","Modeling",CSVFiles_Modeling);
          this.uploadFiles(shpHostName,"/uploadSHP","Data",DataSHPFiles);
          this.uploadFiles(hostname,"/uploadFile","ParameterData",ParameterCSVFiles,
          this.uploadFiles(hostname, "/uploadFile", "SearchFile", SearchFiles));
        })
        .catch((error) => {
          console.error(error);
        });

      const token = `admin:geoserver`;
      const encodedToken = Buffer.from(token).toString("base64");

      const wrData = {
        workspace: {
          name: projectName,
        },
      };

      projectName === "Anglesey" ? (projectName = "Anglesey_1") : projectName;

      const config = {
        method: "post",
        url: `https://ptsviewer.com/geoserver/rest/workspaces`,
        headers: { Authorization: "Basic " + encodedToken },
        data: wrData,
      };

      //add workspace -> add project folder -> add all SHP files
      axios(config)
        .then((response) => {
          axios
            .post(`${shpHostName}/addProject2`, data)
            .then((response) => {
              this.uploadFiles(shpHostName, "/uploadSHP", "SHP", SHPFiles);
            })
            .catch((error) => {
              console.log("Add project 2 error");
            });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  onDeleteCSVFiles_Section = (idx) => {
    let { CSVFiles_Section, deleted_CSVFiles_Section } = this.state;
    const { mode } = this.props;

    if (mode === "edit") {
      let deletedFile = CSVFiles_Section[idx];
      if (Array.isArray(deletedFile)) {
        deletedFile = deletedFile[0];
      }

      const isExistIdx = deleted_CSVFiles_Section.indexOf(
        deletedFile?.name || deletedFile
      );

      if (isExistIdx !== -1) {
        deleted_CSVFiles_Section.splice(isExistIdx, 1);
      } else {
        deleted_CSVFiles_Section.push(deletedFile);
      }

      this.setState({
        deleted_CSVFiles_Section,
      });
    } else {
      CSVFiles_Section.splice(idx, 1);
      this.setState({
        CSVFiles_Section,
      });
    }
  };

  onDeleteCSVFiles_Sample = (idx) => {
    let { CSVFiles_Sample, deleted_CSVFiles_Sample } = this.state;

    const { mode } = this.props;

    if (mode === "edit") {
      let deletedFile = CSVFiles_Sample[idx];
      if (Array.isArray(deletedFile)) {
        deletedFile = deletedFile[0];
      }

      const isExistIdx = deleted_CSVFiles_Sample.indexOf(
        deletedFile?.name || deletedFile
      );

      if (isExistIdx !== -1) {
        deleted_CSVFiles_Sample.splice(isExistIdx, 1);
        console.log(deleted_CSVFiles_Sample, "deleted_CSVFiles_Sample");
      } else {
        deleted_CSVFiles_Sample.push(deletedFile);
      }

      this.setState({
        deleted_CSVFiles_Sample,
      });
    } else {
      CSVFiles_Sample.splice(idx, 1);
      this.setState({
        CSVFiles_Sample,
      });
    }
  };

  onDeleteCSVFiles_Scheme = (idx) => {
    let { CSVFiles_Scheme, deleted_CSVFiles_Scheme } = this.state;
    const { mode } = this.props;

    if (mode === "edit") {
      let deletedFile = CSVFiles_Scheme[idx];
      if (Array.isArray(deletedFile)) {
        deletedFile = deletedFile[0];
      }

      const isExistIdx = deleted_CSVFiles_Scheme.indexOf(
        deletedFile?.name || deletedFile
      );

      if (isExistIdx !== -1) {
        deleted_CSVFiles_Scheme.splice(isExistIdx, 1);
        console.log(deleted_CSVFiles_Scheme, "deleted_CSVFiles_Scheme");
      } else {
        deleted_CSVFiles_Scheme.push(deletedFile);
      }

      this.setState({
        deleted_CSVFiles_Scheme,
      });
    } else {
      CSVFiles_Scheme.splice(idx, 1);
      this.setState({
        CSVFiles_Scheme,
      });
    }
  };

  onDeleteCSVFiles_Import = (idx) => {
    let { CSVFiles_Import, deleted_CSVFiles_Import } = this.state;

    const { mode } = this.props;

    if (mode === "edit") {
      let deletedFile = CSVFiles_Import[idx];
      if (Array.isArray(deletedFile)) {
        deletedFile = deletedFile[0];
      }

      const isExistIdx = deleted_CSVFiles_Import.indexOf(
        deletedFile?.name || deletedFile
      );

      if (isExistIdx !== -1) {
        deleted_CSVFiles_Import.splice(isExistIdx, 1);
        console.log(deleted_CSVFiles_Import, "deleted_CSVFiles_Import");
      } else {
        deleted_CSVFiles_Import.push(deletedFile);
      }

      this.setState({
        deleted_CSVFiles_Import,
      });
    } else {
      CSVFiles_Import.splice(idx, 1);
      this.setState({
        CSVFiles_Import,
      });
    }
  };

  onDeleteCSVFiles_Scrim = (idx) => {
    let { CSVFiles_Scrim, deleted_CSVFiles_Scrim } = this.state;

    const { mode } = this.props;

    if (mode === "edit") {
      let deletedFile = CSVFiles_Scrim[idx];
      if (Array.isArray(deletedFile)) {
        deletedFile = deletedFile[0];
      }

      const isExistIdx = deleted_CSVFiles_Scrim.indexOf(
        deletedFile?.name || deletedFile
      );

      if (isExistIdx !== -1) {
        deleted_CSVFiles_Scrim.splice(isExistIdx, 1);
        console.log(deleted_CSVFiles_Scrim, "deleted_CSVFiles_Scrim");
      } else {
        deleted_CSVFiles_Scrim.push(deletedFile);
      }

      this.setState({
        deleted_CSVFiles_Scrim,
      });
    } else {
      CSVFiles_Scrim.splice(idx, 1);
      this.setState({
        CSVFiles_Scrim,
      });
    }
  };

  onDeleteCSVFiles_Modeling = (idx) => {
    let { CSVFiles_Modeling, deleted_CSVFiles_Modeling } = this.state;

    const { mode } = this.props;

    if (mode === "edit") {
      let deletedFile = CSVFiles_Modeling[idx];
      if (Array.isArray(deletedFile)) {
        deletedFile = deletedFile[0];
      }

      const isExistIdx = deleted_CSVFiles_Modeling.indexOf(
        deletedFile?.name || deletedFile
      );

      if (isExistIdx !== -1) {
        deleted_CSVFiles_Modeling.splice(isExistIdx, 1);
        console.log(deleted_CSVFiles_Modeling, "deleted_CSVFiles_Modeling");
      } else {
        deleted_CSVFiles_Modeling.push(deletedFile);
      }

      this.setState({
        deleted_CSVFiles_Modeling,
      });
    } else {
      CSVFiles_Modeling.splice(idx, 1);
      this.setState({
        CSVFiles_Modeling,
      });
    }
  };

  onDeleteSHPFiles = (idx) => {
    let { SHPFiles, deleted_SHPFiles } = this.state;

    const { mode } = this.props;

    if (mode === "edit") {
      let deletedFile = SHPFiles[idx];
      if (Array.isArray(deletedFile)) {
        deletedFile = deletedFile[0];
      }

      const isExistIdx = deleted_SHPFiles.indexOf(
        deletedFile?.name || deletedFile
      );

      if (isExistIdx !== -1) {
        deleted_SHPFiles.splice(isExistIdx, 1);
        console.log(deleted_SHPFiles, "deleted_SHPFiles");
      } else {
        deleted_SHPFiles.push(deletedFile);
      }

      this.setState({
        deleted_SHPFiles,
      });
    } else {
      SHPFiles.splice(idx, 1);
      this.setState({
        SHPFiles,
      });
    }
  };
  onDeleteSCRIMFiles = (idx) => {
    let { SCRIMFiles, deleted_SCRIMFiles } = this.state;

    const { mode } = this.props;

    if (mode === "edit") {
      let deletedFile = SCRIMFiles[idx];
      if (Array.isArray(deletedFile)) {
        deletedFile = deletedFile[0];
      }

      const isExistIdx = deleted_SCRIMFiles.indexOf(
        deletedFile?.name || deletedFile
      );

      if (isExistIdx !== -1) {
        deleted_SCRIMFiles.splice(isExistIdx, 1);
        console.log(deleted_SCRIMFiles, "deleted_SCRIMFiles");
      } else {
        deleted_SCRIMFiles.push(deletedFile);
      }

      this.setState({
        deleted_SCRIMFiles,
      });
    } else {
      SCRIMFiles.splice(idx, 1);
      this.setState({
        SCRIMFiles,
      });
    }
  };

  onDeleteDataSHPFiles = (idx) => {
    let { DataSHPFiles, deleted_DataSHPFiles } = this.state;

    const { mode } = this.props;

    if (mode === "edit") {
      let deletedFile = DataSHPFiles[idx];
      if (Array.isArray(deletedFile)) {
        deletedFile = deletedFile[0];
      }

      const isExistIdx = deleted_DataSHPFiles.indexOf(
        deletedFile?.name || deletedFile
      );

      if (isExistIdx !== -1) {
        deleted_DataSHPFiles.splice(isExistIdx, 1);
        console.log(deleted_DataSHPFiles, "deleted_DataSHPFiles");
      } else {
        deleted_DataSHPFiles.push(deletedFile);
      }

      this.setState({
        deleted_DataSHPFiles,
      });
    } else {
      DataSHPFiles.splice(idx, 1);
      this.setState({
        DataSHPFiles,
      });
    }
  };

  onDeleteParameterCSVFiles = (idx) => {
    let { ParameterCSVFiles, deleted_ParameterCSVFiles } = this.state;

    const { mode } = this.props;

    if (mode === "edit") {
      let deletedFile = ParameterCSVFiles[idx];
      if (Array.isArray(deletedFile)) {
        deletedFile = deletedFile[0];
      }

      const isExistIdx = deleted_ParameterCSVFiles.indexOf(
        deletedFile?.name || deletedFile
      );

      if (isExistIdx !== -1) {
        deleted_ParameterCSVFiles.splice(isExistIdx, 1);
        console.log(deleted_ParameterCSVFiles, "deleted_ParameterCSVFiles");
      } else {
        deleted_ParameterCSVFiles.push(deletedFile);
      }

      this.setState({
        deleted_ParameterCSVFiles,
      });
    } else {
      ParameterCSVFiles.splice(idx, 1);
      this.setState({
        ParameterCSVFiles,
      });
    }
  };

  onDeleteSearchFiles = (idx) => {
    let { SearchFiles, deleted_SearchFiles } = this.state;

    const { mode } = this.props;

    if (mode === "edit") {
      let deletedFile = SearchFiles[idx];
      if (Array.isArray(deletedFile)) {
        deletedFile = deletedFile[0];
      }

      const isExistIdx = deleted_SearchFiles.indexOf(
        deletedFile?.name || deletedFile
      );

      if (isExistIdx !== -1) {
        deleted_SearchFiles.splice(isExistIdx, 1);
        console.log(deleted_SearchFiles, "deleted_SearchFiles");
      } else {
        deleted_SearchFiles.push(deletedFile);
      }

      this.setState({
        deleted_SearchFiles,
      });
    } else {
      SearchFiles.splice(idx, 1);
      this.setState({
        SearchFiles,
      });
    }
  };

  render() {
    const {
      hasError,
      DataSHPFiles,
      SHPFiles,
      SCRIMFiles,
      CSVFiles_Section,
      CSVFiles_Sample,
      CSVFiles_Scheme,
      CSVFiles_Import,
      CSVFiles_Scrim,
      CSVFiles_Modeling,
      deleted_DataSHPFiles,
      deleted_SHPFiles,
      deleted_SCRIMFiles,
      deleted_CSVFiles_Section,
      deleted_CSVFiles_Sample,
      deleted_CSVFiles_Scheme,
      deleted_CSVFiles_Import,
      deleted_CSVFiles_Scrim,
      deleted_CSVFiles_Modeling,
      projectName,
      progressInfos_Section,
      progressInfos_Sample,
      progressInfos_Scheme,
      progressInfos_Shp,
      progressInfos_SCRIM_Report,
      progressInfos_DataShp,
      progressInfos_Import,
      progressInfos_Scrim,
      progressInfos_Modeling,
      new_CSVFiles_Section,
      new_CSVFiles_Sample,
      new_CSVFiles_Scheme,
      new_CSVFiles_Import,
      new_CSVFiles_Scrim,
      new_CSVFiles_Modeling,
      new_SHPFiles,
      new_SCRIMFiles,
      new_DataSHPFiles,
      ParameterCSVFiles,
      new_ParameterCSVFiles,
      deleted_ParameterCSVFiles,
      deleted_SearchFiles,
      new_SearchFiles,
      SearchFiles,
      progressInfos_ParameterCSV,
      progressInfos_SearchFile,
    } = this.state;

    const { classes, mode = "create" } = this.props;

    let uploadBox = (percentage) => (
      <div>
        <Box width="100%" mr={1}>
          <LinearProgress variant="determinate" value={percentage} />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">
            {percentage}
          </Typography>
        </Box>
      </div>
    );

    return (
      <div>
        {/* <Snack message={uploadMessage} open={open} color="danger" close/> */}
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box>
                    <span className="h5">
                      {this.props.data ? "Update" : "Create New"} Project
                    </span>
                  </Box>
                </Box>
              </CardHeader>
              <CardBody className="mt-3">
                <GridContainer flex={true} spacing={3}>
                  <div className={classes.filesContainer}>
                    <GridItem xs={3}>
                      <span className={classes.label}>Project name</span>
                    </GridItem>
                    <GridItem xs={9}>
                      <TextField
                        className={`${classes.field} ${
                          hasError && !projectName && classes.errorBorder
                        }`}
                        fullWidth
                        label=""
                        variant="outlined"
                        id="mui-theme-provider-outlined-input"
                        value={projectName}
                        placeholder="Enter Project name"
                        onChange={(e) =>
                          this.setState({ projectName: e.target.value })
                        }
                      />
                    </GridItem>
                  </div>
                </GridContainer>

                <GridContainer flex={true} spacing={3}>
                  <div className={classes.filesContainer}>
                    <GridItem xs={3}>
                      <span className={classes.label}>
                        Section Level PCI CSV
                      </span>
                    </GridItem>
                    <GridItem xs={9} alignItems="center">
                      <div>
                        {CSVFiles_Section.length
                          ? CSVFiles_Section.map((data, idx) => (
                              <Chip
                                label={data?.name || data}
                                onDelete={() =>
                                  this.onDeleteCSVFiles_Section(idx)
                                }
                                variant="outlined"
                                className={
                                  deleted_CSVFiles_Section.includes(
                                    data?.name || data
                                  )
                                    ? classes.deletedChip
                                    : classes.chip
                                }
                              />
                            ))
                          : null}
                        {mode === "edit" && new_CSVFiles_Section.length ? (
                          <div>
                            {new_CSVFiles_Section.map((data, idx) => (
                              <Chip
                                label={data?.name || data}
                                onDelete={() => {
                                  new_CSVFiles_Section.splice(idx, 1);
                                  this.setState({ new_CSVFiles_Section });
                                }}
                                variant="outlined"
                                className={classes.forUpdateChip}
                                title="Mark for Update"
                              />
                            ))}
                            <hr />
                          </div>
                        ) : null}

                        <label htmlFor="btn-upload-1">
                          <input
                            id="btn-upload-1"
                            name="btn-upload-1"
                            style={{ display: "none" }}
                            type="file"
                            onChange={(e) => this.selectCSVFile_Section(e)}
                            multiple
                          />
                          <Button
                            className={`btn-choose ${classes.field} ${classes.largeBtn} `}
                            variant="outlined"
                            component="span"
                            color="default"
                            size="large"
                            startIcon={<PostAddIcon />}
                          >
                            Add CSV Files
                          </Button>
                        </label>
                      </div>
                    </GridItem>
                  </div>
                </GridContainer>

                <GridContainer flex={true} spacing={3}>
                  <div className={classes.filesContainer}>
                    <GridItem xs={3}>
                      <span className={classes.label}>
                        Sample Level PCI CSV
                      </span>
                    </GridItem>
                    <GridItem xs={9}>
                      <div>
                        {CSVFiles_Sample.length
                          ? CSVFiles_Sample.map((data, idx) => (
                              <Chip
                                label={data?.name || data}
                                onDelete={() =>
                                  this.onDeleteCSVFiles_Sample(idx)
                                }
                                variant="outlined"
                                className={
                                  deleted_CSVFiles_Sample.includes(
                                    data?.name || data
                                  )
                                    ? classes.deletedChip
                                    : classes.chip
                                }
                              />
                            ))
                          : null}
                        {mode === "edit" && new_CSVFiles_Sample.length ? (
                          <div>
                            {new_CSVFiles_Sample.map((data, idx) => (
                              <Chip
                                label={data?.name || data}
                                onDelete={() => {
                                  new_CSVFiles_Sample.splice(idx, 1);
                                  this.setState({ new_CSVFiles_Sample });
                                }}
                                variant="outlined"
                                className={classes.forUpdateChip}
                                title="Mark for Update"
                              />
                            ))}
                            <hr />
                          </div>
                        ) : null}

                        <label htmlFor="btn-upload-2">
                          <input
                            id="btn-upload-2"
                            name="btn-upload-2"
                            style={{ display: "none" }}
                            type="file"
                            onChange={(e) => this.selectCSVFile_Sample(e)}
                            multiple
                          />
                          <Button
                            className={`btn-choose ${classes.field} ${classes.largeBtn} `}
                            variant="outlined"
                            component="span"
                            color="default"
                            size="large"
                            startIcon={<PostAddIcon />}
                          >
                            Add CSV Files
                          </Button>
                        </label>
                      </div>
                    </GridItem>
                  </div>
                </GridContainer>

                <GridContainer flex={true} spacing={3}>
                  <div className={classes.filesContainer}>
                    <GridItem xs={3}>
                      <span className={classes.label}>
                        Scheme Level CSV File
                      </span>
                    </GridItem>
                    <GridItem xs={9}>
                      <div>
                        {CSVFiles_Scheme.length
                          ? CSVFiles_Scheme.map((data, idx) => (
                              <Chip
                                label={data?.name || data}
                                onDelete={() =>
                                  this.onDeleteCSVFiles_Scheme(idx)
                                }
                                variant="outlined"
                                className={
                                  deleted_CSVFiles_Scheme.includes(
                                    data?.name || data
                                  )
                                    ? classes.deletedChip
                                    : classes.chip
                                }
                              />
                            ))
                          : null}
                        {mode === "edit" && new_CSVFiles_Scheme.length ? (
                          <div>
                            {new_CSVFiles_Scheme.map((data, idx) => (
                              <Chip
                                label={data?.name || data}
                                onDelete={() => {
                                  new_CSVFiles_Scheme.splice(idx, 1);
                                  this.setState({ new_CSVFiles_Scheme });
                                }}
                                variant="outlined"
                                className={classes.forUpdateChip}
                                title="Mark for Update"
                              />
                            ))}
                            <hr />
                          </div>
                        ) : null}
                        <label htmlFor="btn-upload-3">
                          <input
                            id="btn-upload-3"
                            name="btn-upload-3"
                            style={{ display: "none" }}
                            type="file"
                            onChange={(e) => this.selectCSVFile_Scheme(e)}
                            multiple
                          />
                          <Button
                            className={`btn-choose ${classes.field} ${classes.largeBtn} `}
                            variant="outlined"
                            component="span"
                            color="default"
                            size="large"
                            startIcon={<PostAddIcon />}
                          >
                            Add CSV Files
                          </Button>
                        </label>
                      </div>
                    </GridItem>
                  </div>
                </GridContainer>

                <GridContainer flex={true} spacing={3}>
                  <div className={classes.filesContainer}>
                    <GridItem xs={3}>
                      <span className={classes.label}>
                        Import Data CSV File
                      </span>
                    </GridItem>
                    <GridItem xs={9}>
                      <div>
                        {CSVFiles_Import.length
                          ? CSVFiles_Import.map((data, idx) => (
                              <Chip
                                label={data?.name || data}
                                onDelete={() =>
                                  this.onDeleteCSVFiles_Import(idx)
                                }
                                variant="outlined"
                                className={
                                  deleted_CSVFiles_Import.includes(
                                    data?.name || data
                                  )
                                    ? classes.deletedChip
                                    : classes.chip
                                }
                              />
                            ))
                          : null}
                        {mode === "edit" && new_CSVFiles_Import.length ? (
                          <div>
                            {new_CSVFiles_Import.map((data, idx) => (
                              <Chip
                                label={data?.name || data}
                                onDelete={() => {
                                  new_CSVFiles_Import.splice(idx, 1);
                                  this.setState({ new_CSVFiles_Import });
                                }}
                                variant="outlined"
                                className={classes.forUpdateChip}
                                title="Mark for Update"
                              />
                            ))}
                            <hr />
                          </div>
                        ) : null}
                        <label htmlFor="btn-upload-4">
                          <input
                            id="btn-upload-4"
                            name="btn-upload-4"
                            style={{ display: "none" }}
                            type="file"
                            onChange={(e) => this.selectCSVFile_Import(e)}
                            multiple
                          />
                          <Button
                            className={`btn-choose ${classes.field} ${classes.largeBtn} `}
                            variant="outlined"
                            component="span"
                            color="default"
                            size="large"
                            startIcon={<PostAddIcon />}
                          >
                            Add CSV Files
                          </Button>
                        </label>
                      </div>
                    </GridItem>
                  </div>
                </GridContainer>

                <GridContainer flex={true} spacing={3}>
                  <div className={classes.filesContainer}>
                    <GridItem xs={3}>
                      <span className={classes.label}>
                        SCRIM Data CSV File
                      </span>
                    </GridItem>
                    <GridItem xs={9}>
                      <div>
                        {CSVFiles_Scrim.length
                          ? CSVFiles_Scrim.map((data, idx) => (
                              <Chip
                                label={data?.name || data}
                                onDelete={() =>
                                  this.onDeleteCSVFiles_Scrim(idx)
                                }
                                variant="outlined"
                                className={
                                  deleted_CSVFiles_Scrim.includes(
                                    data?.name || data
                                  )
                                    ? classes.deletedChip
                                    : classes.chip
                                }
                              />
                            ))
                          : null}
                        {mode === "edit" && new_CSVFiles_Scrim.length ? (
                          <div>
                            {new_CSVFiles_Scrim.map((data, idx) => (
                              <Chip
                                label={data?.name || data}
                                onDelete={() => {
                                  new_CSVFiles_Scrim.splice(idx, 1);
                                  this.setState({ new_CSVFiles_Scrim });
                                }}
                                variant="outlined"
                                className={classes.forUpdateChip}
                                title="Mark for Update"
                              />
                            ))}
                            <hr />
                          </div>
                        ) : null}
                        <label htmlFor="btn-upload-5">
                          <input
                            id="btn-upload-5"
                            name="btn-upload-5"
                            style={{ display: "none" }}
                            type="file"
                            onChange={(e) => this.selectCSVFile_Scrim(e)}
                            multiple
                          />
                          <Button
                            className={`btn-choose ${classes.field} ${classes.largeBtn} `}
                            variant="outlined"
                            component="span"
                            color="default"
                            size="large"
                            startIcon={<PostAddIcon />}
                          >
                            Add CSV Files
                          </Button>
                        </label>
                      </div>
                    </GridItem>
                  </div>
                </GridContainer>

                <GridContainer flex={true} spacing={3}>
                  <div className={classes.filesContainer}>
                    <GridItem xs={3}>
                      <span className={classes.label}>
                        Modeling Data CSV File
                      </span>
                    </GridItem>
                    <GridItem xs={9}>
                      <div>
                        {CSVFiles_Modeling.length
                          ? CSVFiles_Modeling.map((data, idx) => (
                              <Chip
                                label={data?.name || data}
                                onDelete={() =>
                                  this.onDeleteCSVFiles_Modeling(idx)
                                }
                                variant="outlined"
                                className={
                                  deleted_CSVFiles_Modeling.includes(
                                    data?.name || data
                                  )
                                    ? classes.deletedChip
                                    : classes.chip
                                }
                              />
                            ))
                          : null}
                        {mode === "edit" && new_CSVFiles_Modeling.length ? (
                          <div>
                            {new_CSVFiles_Modeling.map((data, idx) => (
                              <Chip
                                label={data?.name || data}
                                onDelete={() => {
                                  new_CSVFiles_Modeling.splice(idx, 1);
                                  this.setState({ new_CSVFiles_Modeling });
                                }}
                                variant="outlined"
                                className={classes.forUpdateChip}
                                title="Mark for Update"
                              />
                            ))}
                            <hr />
                          </div>
                        ) : null}
                        <label htmlFor="btn-upload-modeling">
                          <input
                            id="btn-upload-modeling"
                            name="btn-upload-modeling"
                            style={{ display: "none" }}
                            type="file"
                            onChange={(e) => this.selectCSVFile_Modeling(e)}
                            multiple
                          />
                          <Button
                            className={`btn-choose ${classes.field} ${classes.largeBtn} `}
                            variant="outlined"
                            component="span"
                            color="default"
                            size="large"
                            startIcon={<PostAddIcon />}
                          >
                            Add CSV Files
                          </Button>
                        </label>
                      </div>
                    </GridItem>
                  </div>
                </GridContainer>

                <GridContainer flex={true} spacing={3}>
                  <div className={classes.filesContainer}>
                    <GridItem xs={3}>
                      <span className={classes.label}>Network SHP File</span>
                    </GridItem>
                    <GridItem xs={9}>
                      <div>
                        {SHPFiles.length
                          ? SHPFiles.map((data, idx) => (
                              <Chip
                                label={data?.name || data}
                                onDelete={() => this.onDeleteSHPFiles(idx)}
                                variant="outlined"
                                className={
                                  deleted_SHPFiles.includes(data?.name || data)
                                    ? classes.deletedChip
                                    : classes.chip
                                }
                              />
                            ))
                          : null}
                        {mode === "edit" && new_SHPFiles.length ? (
                          <div>
                            {new_SHPFiles.map((data, idx) => (
                              <Chip
                                label={data?.name || data}
                                onDelete={() => {
                                  new_SHPFiles.splice(idx, 1);
                                  this.setState({ new_SHPFiles });
                                }}
                                variant="outlined"
                                className={classes.forUpdateChip}
                                title="Mark for Update"
                              />
                            ))}
                            <hr />
                          </div>
                        ) : null}
                        <label htmlFor="btn-shv-upload">
                          <input
                            id="btn-shv-upload"
                            name="btn-shv-upload"
                            style={{ display: "none" }}
                            type="file"
                            onChange={(e) => this.selectSHPFile(e)}
                            multiple
                          />
                          <Button
                            className={`btn-choose ${classes.field} ${classes.largeBtn} `}
                            variant="outlined"
                            component="span"
                            color="default"
                            size="large"
                            startIcon={<PostAddIcon />}
                          >
                            Add SHP Files
                          </Button>
                        </label>
                      </div>
                    </GridItem>
                  </div>
                </GridContainer>
                <GridContainer flex={true} spacing={3}>
                  <div className={classes.filesContainer}>
                    <GridItem xs={3}>
                      <span className={classes.label}>Data SHP File</span>
                    </GridItem>
                    <GridItem xs={9}>
                      <div>
                        {DataSHPFiles.length
                          ? DataSHPFiles.map((data, idx) => (
                              <Chip
                                label={data?.name || data}
                                onDelete={() => this.onDeleteDataSHPFiles(idx)}
                                variant="outlined"
                                className={
                                  deleted_DataSHPFiles.includes(
                                    data?.name || data
                                  )
                                    ? classes.deletedChip
                                    : classes.chip
                                }
                              />
                            ))
                          : null}
                        {mode === "edit" && new_DataSHPFiles.length ? (
                          <div>
                            {new_DataSHPFiles.map((data, idx) => (
                              <Chip
                                label={data?.name || data}
                                onDelete={() => {
                                  new_DataSHPFiles.splice(idx, 1);
                                  this.setState({ new_DataSHPFiles });
                                }}
                                variant="outlined"
                                className={classes.forUpdateChip}
                                title="Mark for Update"
                              />
                            ))}
                            <hr />
                          </div>
                        ) : null}
                        <label htmlFor="btn-data-shv-upload">
                          <input
                            id="btn-data-shv-upload"
                            name="btn-data-shv-upload"
                            style={{ display: "none" }}
                            type="file"
                            onChange={(e) => this.selectDataSHPFile(e)}
                            multiple
                          />
                          <Button
                            className={`btn-choose ${classes.field} ${classes.largeBtn} `}
                            variant="outlined"
                            component="span"
                            color="default"
                            size="large"
                            startIcon={<PostAddIcon />}
                          >
                            Add Data SHP Files
                          </Button>
                        </label>
                      </div>
                    </GridItem>
                  </div>
                </GridContainer>
                <GridContainer flex={true} spacing={3}>
                  <div className={classes.filesContainer}>
                    <GridItem xs={3}>
                      <span className={classes.label}>Parameter Data CSV File</span>
                    </GridItem>
                    <GridItem xs={9}>
                      <div>
                        {ParameterCSVFiles.length
                          ? ParameterCSVFiles.map((data, idx) => (
                              <Chip
                                label={data?.name || data}
                                onDelete={() =>
                                  this.onDeleteParameterCSVFiles(idx)
                                }
                                variant="outlined"
                                className={
                                  deleted_ParameterCSVFiles.includes(
                                    data?.name || data
                                  )
                                    ? classes.deletedChip
                                    : classes.chip
                                }
                              />
                            ))
                          : null}
                        {mode === "edit" && new_ParameterCSVFiles.length ? (
                          <div>
                            {new_ParameterCSVFiles.map((data, idx) => (
                              <Chip
                                label={data?.name || data}
                                onDelete={() => {
                                  new_ParameterCSVFiles.splice(idx, 1);
                                  this.setState({ new_ParameterCSVFiles });
                                }}
                                variant="outlined"
                                className={classes.forUpdateChip}
                                title="Mark for Update"
                              />
                            ))}
                            <hr />
                          </div>
                        ) : null}
                        <label htmlFor="btn-data-parameter-csv-data">
                          <input
                            id="btn-data-parameter-csv-data"
                            name="btn-data-parameter-csv-data"
                            style={{ display: "none" }}
                            type="file"
                            onChange={(e) => this.selectParameterCSVFile(e)}
                            multiple
                          />
                          <Button
                            className={`btn-choose ${classes.field} ${classes.largeBtn} `}
                            variant="outlined"
                            component="span"
                            color="default"
                            size="large"
                            startIcon={<PostAddIcon />}
                          >
                            Add Parameter CSV Files
                          </Button>
                        </label>
                      </div>
                    </GridItem>
                  </div>
                </GridContainer>

                <GridContainer flex={true} spacing={3}>
                  <div className={classes.filesContainer}>
                    <GridItem xs={3}>
                      <span className={classes.label}>Search CSV File</span>
                    </GridItem>
                    <GridItem xs={9}>
                      <div>
                        {SearchFiles.length
                          ? SearchFiles.map((data, idx) => (
                              <Chip
                                label={data?.name || data}
                                onDelete={() =>
                                  this.onDeleteSearchFiles(idx)
                                }
                                variant="outlined"
                                className={
                                  deleted_SearchFiles.includes(
                                    data?.name || data
                                  )
                                    ? classes.deletedChip
                                    : classes.chip
                                }
                              />
                            ))
                          : null}
                        {mode === "edit" && new_SearchFiles.length ? (
                          <div>
                            {new_SearchFiles.map((data, idx) => (
                              <Chip
                                label={data?.name || data}
                                onDelete={() => {
                                  new_SearchFiles.splice(idx, 1);
                                  this.setState({ new_SearchFiles });
                                }}
                                variant="outlined"
                                className={classes.forUpdateChip}
                                title="Mark for Update"
                              />
                            ))}
                            <hr />
                          </div>
                        ) : null}
                        <label htmlFor="btn-data-search-csv-data">
                          <input
                            id="btn-data-search-csv-data"
                            name="btn-data-search-csv-data"
                            style={{ display: "none" }}
                            type="file"
                            onChange={(e) => this.selectSearchFile(e)}
                            multiple
                          />
                          <Button
                            className={`btn-choose ${classes.field} ${classes.largeBtn} `}
                            variant="outlined"
                            component="span"
                            color="default"
                            size="large"
                            startIcon={<PostAddIcon />}
                          >
                            Add Search CSV File
                          </Button>
                        </label>
                      </div>
                    </GridItem>
                  </div>
                </GridContainer>

                <GridContainer flex={true} spacing={3}>
                  <div className={classes.filesContainer}>
                    <GridItem xs={3}>
                      <span className={classes.label}>Scrim Report File</span>
                    </GridItem>
                    <GridItem xs={9}>
                      <div>
                        {SCRIMFiles.length
                          ? SCRIMFiles.map((data, idx) => (
                              <Chip
                                label={data?.name || data}
                                onDelete={() =>
                                  this.onDeleteSCRIMFiles(idx)
                                }
                                variant="outlined"
                                className={
                                  deleted_SCRIMFiles.includes(
                                    data?.name || data
                                  )
                                    ? classes.deletedChip
                                    : classes.chip
                                }
                              />
                            ))
                          : null}
                        {mode === "edit" && new_SCRIMFiles.length ? (
                          <div>
                            {new_SCRIMFiles.map((data, idx) => (
                              <Chip
                                label={data?.name || data}
                                onDelete={() => {
                                  new_SCRIMFiles.splice(idx, 1);
                                  this.setState({ new_SCRIMFiles });
                                }}
                                variant="outlined"
                                className={classes.forUpdateChip}
                                title="Mark for Update"
                              />
                            ))}
                            <hr />
                          </div>
                        ) : null}
                        <label htmlFor="btn-data-scrim-report">
                          <input
                            id="btn-data-scrim-report"
                            name="btn-data-scrim-report"
                            style={{ display: "none" }}
                            type="file"
                            onChange={(e) => this.selectSCRIMFile(e)}
                            multiple
                          />
                          <Button
                            className={`btn-choose ${classes.field} ${classes.largeBtn} `}
                            variant="outlined"
                            component="span"
                            color="default"
                            size="large"
                            startIcon={<PostAddIcon />}
                          >
                            Add Scrim Report File
                          </Button>
                        </label>
                      </div>
                    </GridItem>
                  </div>
                </GridContainer>

                <div className={classes.saveButtonContainer}>
                  <Button
                    variant="contained"
                    onClick={() => this.createProject(mode)}
                    size="large"
                    endIcon={<Icon>send</Icon>}
                  >
                    Upload
                  </Button>
                </div>

                <div>
                  {progressInfos_Section &&
                    progressInfos_Section.map((progressInfo, index) => (
                      <div className="mb-2" key={index}>
                        <span>Section Level Files : </span>
                        {progressInfo.status ? (
                          <span>{progressInfo.status}</span>
                        ) : (
                          uploadBox(progressInfo.percentage)
                        )}
                      </div>
                    ))}
                </div>

                <div>
                  {progressInfos_Sample &&
                    progressInfos_Sample.map((progressInfo, index) => (
                      <div className="mb-2" key={index}>
                        <span>Sample Level Files : </span>
                        {progressInfo.status ? (
                          <span>{progressInfo.status}</span>
                        ) : (
                          uploadBox(progressInfo.percentage)
                        )}
                      </div>
                    ))}
                </div>

                <div>
                  {progressInfos_Scheme &&
                    progressInfos_Scheme.map((progressInfo, index) => (
                      <div className="mb-2" key={index}>
                        <span>Scheme Level Files : </span>
                        {progressInfo.status ? (
                          <span>{progressInfo.status}</span>
                        ) : (
                          uploadBox(progressInfo.percentage)
                        )}
                      </div>
                    ))}
                </div>

                <div>
                  {progressInfos_Shp &&
                    progressInfos_Shp.map((progressInfo, index) => (
                      <div className="mb-2" key={index}>
                        <span>SHP Files : </span>
                        {progressInfo.status ? (
                          <span>{progressInfo.status}</span>
                        ) : (
                          uploadBox(progressInfo.percentage)
                        )}
                      </div>
                    ))}
                </div>

                <div>
                  {progressInfos_Import &&
                    progressInfos_Import.map((progressInfo, index) => (
                      <div className="mb-2" key={index}>
                        <span>Import Data Files : </span>
                        {progressInfo.status ? (
                          <span>{progressInfo.status}</span>
                        ) : (
                          uploadBox(progressInfo.percentage)
                        )}
                      </div>
                    ))}
                </div>


                <div>
                  {progressInfos_Scrim &&
                    progressInfos_Scrim.map((progressInfo, index) => (
                      <div className="mb-2" key={index}>
                        <span>SCRIM Data Files : </span>
                        {progressInfo.status ? (
                          <span>{progressInfo.status}</span>
                        ) : (
                          uploadBox(progressInfo.percentage)
                        )}
                      </div>
                    ))}
                </div>

                <div>
                  {progressInfos_Modeling &&
                    progressInfos_Modeling.map((progressInfo, index) => (
                      <div className="mb-2" key={index}>
                        <span>Modeling Data Files : </span>
                        {progressInfo.status ? (
                          <span>{progressInfo.status}</span>
                        ) : (
                          uploadBox(progressInfo.percentage)
                        )}
                      </div>
                    ))}
                </div>

                <div>
                  {progressInfos_DataShp &&
                    progressInfos_DataShp.map((progressInfo, index) => (
                      <div className="mb-2" key={index}>
                        <span>Data SHP Files : </span>
                        {progressInfo.status ? (
                          <span>{progressInfo.status}</span>
                        ) : (
                          uploadBox(progressInfo.percentage)
                        )}
                      </div>
                    ))}
                </div>
                <div>
                  {progressInfos_ParameterCSV &&
                    progressInfos_ParameterCSV.map((progressInfo, index) => (
                      <div className="mb-2" key={index}>
                        <span>Parameter CSV Files : </span>
                        {progressInfo.status ? (
                          <span>{progressInfo.status}</span>
                        ) : (
                          uploadBox(progressInfo.percentage)
                        )}
                      </div>
                    ))}
                </div>
                <div>
                  {progressInfos_SearchFile &&
                    progressInfos_SearchFile.map((progressInfo, index) => (
                      <div className="mb-2" key={index}>
                        <span>Search CSV Files : </span>
                        {progressInfo.status ? (
                          <span>{progressInfo.status}</span>
                        ) : (
                          uploadBox(progressInfo.percentage)
                        )}
                      </div>
                    ))}
                </div>
                <div>
                  {progressInfos_SCRIM_Report &&
                    progressInfos_SCRIM_Report.map((progressInfo, index) => (
                      <div className="mb-2" key={index}>
                        <span>SCRIM Report Files : </span>
                        {progressInfo.status ? (
                          <span>{progressInfo.status}</span>
                        ) : (
                          uploadBox(progressInfo.percentage)
                        )}
                      </div>
                    ))}
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withRouter(withStyles(useStyles)(DocsUploadComponent));
