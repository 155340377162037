import React from "react";
import { find } from "lodash";
import MappingTable from "../components/MappingTable";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

export default function ModelingDataMapping({
  mappingData,
  dropdownValues,
  config,
  setMappingData,
}) {
  const { prefix, suffix, mapping } = config;

  const convertFilterData = (data) => {
    //console.log("SCRIM Data " , data);
    var rows = [
      {
        lane: "Lane 1",
        name: "Lane 1",
        mappingOptions: dropdownValues,
        mapValue:
          mapping[ "Lane 1"] && mapping[ "Lane 1"][0] &&
          mapping[ "Lane 1"][0]?.replace(prefix, "")?.replace(suffix, ""),
      },
      {
        lane: "Lane 2",
        name: "Lane 2",
        mappingOptions: dropdownValues,
        mapValue:
          mapping[ "Lane 2"] && mapping[ "Lane 2"][0] &&
          mapping[ "Lane 2"][0]?.replace(prefix, "")?.replace(suffix, ""),
      },
      {
        lane: "Lane 3",
        name: "Lane 3",
        mappingOptions: dropdownValues,
        mapValue:
          mapping[ "Lane 3"] && mapping[ "Lane 3"][0] &&
          mapping[ "Lane 3"][0]?.replace(prefix, "")?.replace(suffix, ""),
      },
      {
        lane: "RLane 1",
        name: "RLane 1",
        mappingOptions: dropdownValues,
        mapValue:
          mapping[ "RLane 1"] && mapping[ "RLane 1"][0] &&
          mapping[ "RLane 1"][0]?.replace(prefix, "")?.replace(suffix, ""),
      },
    ];
    return rows;
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <MappingTable
            tableHeaderColor="primary"
            tableHead={["Lanes", "File"]}
            tableData={convertFilterData(mappingData)}
            tableTitle="Lane - Modeling Data Mapping"
            setMappingData={setMappingData}
          />
        </GridItem>
      </GridContainer>
    </>
  );
}
