import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import AssessmentIcon from "@material-ui/icons/Assessment";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import DashboardComponent from "views/Dashboard/DashboardComponent.js";
import VideoDashboardComponent from "views/VideoComponent/VideoDashboardComponent.js";
import UserInteractionComponent from "views/UserInteraction/UserInteractionComponent.js";
import ProjectUploadComponent from "views/ProjectSetup/ProjectUploadComponent.js";
import DocsUploadComponent from "views/DocsUploadComponent/DocsUploadComponent";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import SettingsIcon from "@material-ui/icons/Settings";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "User Dashboard",
    icon: Person,
    component: DashboardComponent,
    layout: "/admin",
  },
  {
    path: "/project-dashboard",
    name: "Project Dashboard",
    icon: AccountTreeIcon,
    component: VideoDashboardComponent,
    layout: "/admin",
  },
  {
    path: "/user-interaction",
    name: "Interaction Statistics",
    icon: AssessmentIcon,
    component: UserInteractionComponent,
    layout: "/admin",
  },
  {
    path: "/docs-upload",
    name: "Create Project",
    icon: CloudUploadIcon,
    component: DocsUploadComponent,
    layout: "/admin",
  },
  {
    path: "/project-upload",
    name: "Project Settings",
    icon: SettingsIcon,
    component: ProjectUploadComponent,
    layout: "/admin",
  },
];

export default dashboardRoutes;
