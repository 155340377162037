import React from "react";
// react plugin for creating charts
// import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

export default function Dashboard(props) {
  const classes = useStyles();

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success">
              <h4 className={classes.cardTitleWhite}>
                User Details
              </h4>
              <p className={classes.cardCategoryWhite}>
                Total Users : {props.resData.length}
              </p>
            </CardHeader>
            <CardBody>
              <div style={{
                position:"relative",
                left:"calc(100% - 150px)"
              }}>
                <Button color="info" onClick={props.handleCreate}>
                  CREATE NEW
                </Button>
              </div>
              <Table
                tableHeaderColor="primary"
                tableHead={["ID", "Name", "Password", "Expiry Date"]}
                tableData={props.resData}
                handleDeleteRow={props.handleDeleteRow}
                handleEditRow={props.handleEditRow}
                hideInfo
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
