import React from "react";
import { find } from "lodash";
import MappingTable from "../components/MappingTable";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

export default function SectionLevelLayerMapping({
  mappingData,
  dropdownValues,
  config,
  setMappingData,
}) {
  const { prefix, suffix, mapping } = config;
  const convertFilterData = (data) => {
    var rows = [];
    data.forEach((childData) => {
      const { children } = childData;
      const dataObj = find(children, { text: "Section Level Survey" });

      if (dataObj) {
        const { text: section, children } = dataObj;
        children.forEach((childData3) => {
          const { id, text, children } = childData3;
          children.forEach(({ id: lane, text: laneTxt }) => {
            // console.log(mapping[lane])
            rows.push({
              section,
              name: text,
              lane,
              laneTxt,
              mappingOptions: dropdownValues,
              mapValue:
                mapping[lane] && mapping[lane][0] &&
                mapping[lane][0]?.replace(prefix, "")?.replace(suffix, ""),
              mapValue2:
                mapping[lane] &&
                mapping[lane].length > 1 && mapping[lane][1] &&
                mapping[lane][1]?.replace(prefix, "")?.replace(suffix, ""),
              id,
            });
          });
        });
      }
    });
    return rows;
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <MappingTable
            tableHeaderColor="primary"
            tableHead={[
              "Level",
              "Survey Name",
              "Lanes",
              "Left Lane CSV",
              "Right Lane CSV",
            ]}
            tableData={convertFilterData(mappingData)}
            tableTitle="Lane - PCI Mapping Section-Level"
            showRightDropdown={true}
            setMappingData={setMappingData}
          />
        </GridItem>
      </GridContainer>
    </>
  );
}
