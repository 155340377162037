import React, { useEffect, useState } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/";
import { CloseRounded } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import ColorPicker from "material-ui-color-picker";
import {worksProgramFields} from '../../../constants';

import {
  Button,
  TextField,
  Fade,
  Backdrop,
  Modal,
  MenuItem,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontWeight: "bold",
    fontSize: "20px",
    borderBottom: "1px solid #dcdcdc73",
    paddingBottom: "5px",
  },
  modalContent: {
    display: "grid",
    maxHeight: "400px",
    overflow: "auto",
  },
  field: {
    marginTop: "10px",
  },
  errorText: {
    color: "red",
    margin: "0 5px",
  },
  errorBorder: {
    border: "1px solid red",
    borderRadius: "5px",
  },
  saveButtonContainer: {
    textAlign: "center",
    marginTop: "15px",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3),
    borderRadius: "5px",
    width: "35%",
  },
  w30: {
    width: "30%",
  },
}));

export default function AddFormFieldModal({
  visibility,
  handleClose,
  editField,
  fields,
  setFields,
  updateFields
}) {
  const classes = useStyles();

  const [selectedField, setSelectedField] = useState('');
  const [optionName, setOptionName] = useState('');
  const [options, setOptions] = useState([]);
  const [dependentValue, setDependentValue] = useState('');
  const [isDisbaled, setIsDisabled] = useState(true);
  //check if fields is not undefined
  if (!fields) {
    fields = {};
  }
  var fieldNames = Object.keys(worksProgramFields).filter(v => {
    return !Object.keys(fields).includes(v);
  });

  useEffect(() => {
    if(editField){
      setSelectedField(editField.split('//')[0]);
      if(editField.split('//').length>1){
        setDependentValue(editField.split('//')[1]);
      }
      if(fields[editField]?.options){
        setOptions([...fields[editField].options]);
      }
    }
  }, [editField])
  
  useEffect(() => {
    if(!visibility){
      setOptionName('');
      setDependentValue('');
      setOptions([]);
      setSelectedField('');
      setIsDisabled(true);
    }
  }, [visibility])
  
  const onAddOption = () => {
    setOptions(o=>[...o, optionName]);
    setOptionName('');
    setIsDisabled(false);
  }

  const updateOption = (i, value) => {
    setOptions(o => {
      o[i] = value;
      return [...o];
    })
    setIsDisabled(false);

  }

  const onDone = () => {
    var all_options = options;
    all_options = all_options.filter(n=>{
      return (n && n!=' ')
    });
    var new_field = {};
    if(optionName){
      all_options = [...all_options, optionName];
    }
    if(all_options.length){
      new_field = {
        "type": 'select',
        options: all_options
      }
    }else{
      new_field = {
        "type": 'text'
      }
    }
    if(worksProgramFields[selectedField]?.dependsOn){
      new_field['dependsOn'] = worksProgramFields[selectedField].dependsOn;
    }
    var new_fields;
    setFields(f=>{
      if(worksProgramFields[selectedField]?.dependsOn){
        f[selectedField + '//' + dependentValue] = new_field;
      }else{
        f[selectedField] = new_field;
      }
      new_fields = f;
      return f;
    })
    updateFields(new_fields);
    handleClose();
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={visibility}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={visibility}>
          <div className={classes.paper} style={{maxHeight: '600px', overflowY: 'scroll'}}>
            <div className={classes.modalHeader}>
              <span>
                Add Field
              </span>
              <span onClick={() => handleClose()}>
                <CloseRounded />
              </span>
            </div>
            <div style={{ width: "100%" }}>
              
              <div style={{ marginTop: "10px" }}>
                <TextField
                  select
                  label="Field Name"
                  variant="outlined"
                  id="mui-theme-provider-outlined-input"
                  value={selectedField}
                  onChange={(e) => {
                    setSelectedField(e.target.value);
                  }}
                  style={{ width: "100%", marginBottom: '10px' }}
                  InputProps={{
                    className: classes.textField,
                  }}
                >
                  {editField ?
                    [editField.split('//')[0]].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))
                  :
                    fieldNames.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))
                  }
                </TextField>
              </div>

              {worksProgramFields[selectedField]?.dependsOn &&
                <TextField
                  select
                  label={`Depends on ${worksProgramFields[selectedField]?.dependsOn}`}
                  variant="outlined"
                  id="mui-theme-provider-outlined-input"
                  value={dependentValue}
                  onChange={(e) => {
                    setDependentValue(e.target.value);
                  }}
                  style={{ width: "100%", margin: '10px 0' }}
                  InputProps={{
                    className: classes.textField,
                  }}
                >
                  {fields[worksProgramFields[selectedField]?.dependsOn]?.options.map((option) => (
                    <MenuItem disabled={fields[`${selectedField}//${option}`]} key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              }

              {options.map((opt,i)=>(
                <TextField
                  style={{ width: "100%", marginBottom: '10px' }}
                  label={`Option ${i+1}`}
                  variant="outlined"
                  id="mui-theme-provider-outlined-input"
                  key={i}
                  value={options[i]}
                  onChange={(e) => updateOption(i, e.target.value)}
                />
              ))}
              {(!selectedField || worksProgramFields[selectedField]?.type == 'select') &&
                <TextField
                  style={{ width: "100%" }}
                  label={`Option ${options.length+1}`}
                  variant="outlined"
                  id="mui-theme-provider-outlined-input"
                  value={optionName}
                  onChange={(e) => setOptionName(e.target.value)}
                />
              }
              <Button
                variant="contained"
                style={{ marginTop: "5px" }}
                onClick={onAddOption}
                disabled={worksProgramFields[selectedField]?.type == 'text'}
              >
                {worksProgramFields[selectedField]?.type == 'text' ?
                  'Text Field'
                :
                  'Add Option'
                }
              </Button>

            </div>
            <div className={classes.saveButtonContainer}>
              <Button 
                onClick={onDone} 
                variant="contained"
                disabled={
                  isDisbaled
                }
              >
                Done 
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
