import React, { useState } from "react";
import { map, cloneDeep, findIndex } from "lodash";

import { makeStyles } from "@material-ui/core/styles";
import { Button, Chip, Paper, Box } from "@material-ui/core";
import { AddCircle, Edit, Delete } from "@material-ui/icons";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import ConfirmModal from "./components/ConfirmModal";
import AddDistressModal from "./components/AddDistressModal";
import DistressTable from "./components/DistressTable";

const styles = {
  surveyCard: {
    display: "flex",
    justifyContent: "left",
    flexWrap: "wrap",
    listStyle: "none",
    alignItems: "center",
    padding: "5px 10px",
    marginBottom: "10px",
    border: "1px solid whitesmoke",
  },
  surveyInfoCard: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "80%",
  },
  surveyActionCard: {
    display: "flex",
    justifyContent: "flex-end",
    width: "20%",
  },
  root: {
    display: "flex",
    justifyContent: "left",
    flexWrap: "wrap",
    listStyle: "none",
    flexDirection: "row",
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  gap: {
    marginTop: "60px",
  },
  cardTitleWhite: {
    cursor: "pointer",
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  chip: {
    margin: "8px",
  },
  text: {
    fontWeight: "bolder",
    marginRight: "20px",
  },
};
const useStyles = makeStyles(styles);

export default function DistressDashboard({
  distressLayerData,
  layerData,
  dropdownValues,
  config,
  project,
  setDistressLayerData,
}) {
  const getPrevRules = () => {
    const prevData = [];
    if (distressLayerData) {
      distressLayerData.children.forEach(({ jsonData }) => {
        prevData.push(jsonData);
      });
    }

    return prevData;
  };

  const prevDistressData = getPrevRules();
  const classes = useStyles();
  const [addSurveyModalOpen, setAddSurveyModalOpen] = useState(false);
  const [selectedSurvey, setSelectedSurvey] = useState(null);

  const [rules, setRules] = useState(prevDistressData);
  const [selectedProject, setSelectedProject] = useState([]);
  const [edit, setEdit] = useState(false);

  const [editRowIndex, setEditRowIndex] = useState(-1);

  const { prefix, suffix, mapping } = config;

  const handleSurveyAddRow = () => {
    setSelectedSurvey(null);
    setAddSurveyModalOpen(true);
  };

  const renderAddFilterModal = () => {
    return addSurveyModalOpen ? (
      <AddDistressModal
        visibility={addSurveyModalOpen}
        handleClose={() => {
          setAddSurveyModalOpen(false);
          setEdit(false);
          setSelectedProject([]);
        }}
        onSaveHandler={onSaveHandler}
        project={project}
        selectedProject={selectedProject}
        selectedSurvey={selectedSurvey}
        edit={edit}
        // checkForDuplicate={checkForDuplicate}
        mappingData={convertFilterData(layerData)}
      />
    ) : null;
  };

  const convertFilterData = (data) => {
    let rows = [];
    data.forEach((childData) => {
      const { children } = childData;

      children.forEach((childData2, idx) => {
        const { text: section, children } = childData2;

        children.forEach((childData3) => {
          const { id, text, children } = childData3;
          if(children){
          children.forEach(({ id: lane, text: laneTxt }) => {
            rows.push({
              section,
              name: text,
              lane,
              laneTxt,
              mappingOptions: dropdownValues,
              mapValue:
                mapping[lane] &&  mapping[lane][0] &&
                mapping[lane][0].replace(prefix, "").replace(suffix, ""),
              id,
            });
          });
        }
        });
      });
    });
    return rows;
  };

  const convertTableData = (data) => {
    let rows = [];
    data.forEach((childData) => {
      const { distName, layerName, attrName, rules, zoomLevel } = childData;
      rows.push([distName, layerName, attrName, rules, zoomLevel]);
    });
    return rows;
  };

  const handleDeleteRow = (idx) => {
    let newRules = [...rules];
    newRules.splice(idx, 1);
    setRules(newRules);
    distressLayerData.children.splice(idx, 1);
    setDistressLayerData(distressLayerData);
  };

  const handleEditRow = (idx, propx) => {
    setEdit(true);
    setEditRowIndex(idx);
    setSelectedProject(propx);
    setAddSurveyModalOpen(true);
  };

  const getSHPObject = (obj) => {
    const updatedObj = {
      icon: "./assets/map.png",
      id: `distress-shp-${obj.distName}-${obj.attrName}`,
      state: {
        selected: false,
      },
      text: obj.distName,
      jsonData: obj,
    };
    return updatedObj;
  };

  const onSaveHandler = (nRule, edit) => {
    if (edit) {
      let newRules = [...rules];
      newRules[editRowIndex] = nRule;
      setRules(newRules);
    } else {
      let newRules = [...rules, nRule];
      setRules(newRules);
    }

    if (!distressLayerData) {
      distressLayerData = {
        id: 100,
        text: "Distress SHP",
        state: {
          selected: false,
        },
        icon: "./assets/map.png",
        children: [getSHPObject(nRule)],
      };
    } else {
      if (edit) {
        distressLayerData.children[editRowIndex] = getSHPObject(nRule);
      } else {
        distressLayerData.children.push(getSHPObject(nRule));
      }
    }
    setDistressLayerData(distressLayerData);
  };

  return (
    <GridContainer>
      {renderAddFilterModal()}
      <GridItem xs={12} sm={12} md={12} key={2}>
        <div key={1}>
          <Card>
            <CardHeader color="success">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <span>
                    <b>Distress Data Settings</b>
                  </span>
                </Box>
                <Box>
                  <span onClick={() => handleSurveyAddRow()}>
                    <AddCircle
                      style={{
                        verticalAlign: "bottom",
                        marginRight: "10px",
                      }}
                    />
                    Add Distress
                  </span>
                </Box>
              </Box>
            </CardHeader>
            <CardBody>
              <DistressTable
                tableHeaderColor="primary"
                tableHead={[
                  "Distress Name",
                  "Layer Name",
                  "Attribute Name",
                  "Total Conditions",
                ]}
                tableData={convertTableData(rules)}
                handleDeleteRow={handleDeleteRow}
                handleEditRow={handleEditRow}
              />
            </CardBody>
          </Card>
          <div className={classes.gap}></div>
        </div>
      </GridItem>
    </GridContainer>
  );
}
