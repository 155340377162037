import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/";
import { CloseRounded } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";

import { Button, TextField, Fade, Backdrop, Modal } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontWeight: "bold",
    fontSize: "20px",
    borderBottom: "1px solid #dcdcdc73",
    paddingBottom: "5px",
  },
  modalContent: {
    display: "grid",
    maxHeight: "400px",
    overflow: "auto",
  },
  field: {
    marginTop: "10px",
  },
  errorText: {
    color: "red",
    margin: "0 5px",
  },
  errorBorder: {
    border: "1px solid red",
    borderRadius: "5px",
  },
  saveButtonContainer: {
    textAlign: "center",
    marginTop: "15px",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3),
    borderRadius: "5px",
    width: "35%",
  },
  w30: {
    width: "30%",
  },
}));

export default function AddSurveyModal({
  visibility,
  handleClose,
  onSave,
  selectedSurvey,
  checkForDuplicate,
}) {
  const classes = useStyles();
  const [data, setData] = useState({ ...selectedSurvey });
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");

  const onAddBtnClick = () => {
    const { surveyName } = data;
    if (!surveyName) {
      setError(true);
      setMessage("Survey name is required.");
    } else if (surveyName && checkForDuplicate(surveyName, selectedSurvey)) {
      setError(true);
      setMessage("Survey with same name already exist");
    } else {
      setMessage("");
      setError(false);
      setData({});
      handleClose();
      onSave(data);
    }
  };

  const missingIndex = (a, n) => {
    let total = ((n + 1) * (n + 2)) / 2;
    for (let i = 0; i < n; i++) {
      let laneNumber = a[i].replace(/[^0-9.]/g, "");
      total -= parseInt(laneNumber);
    }
    if (total === 0) total = 1;
    return total;
  };

  const onAddLaneBtnClick = () => {
    if (!data.lanes) {
      setData({
        ...data,
        lanes: ["Lane 1"],
      });
    } else {
      setData({
        ...data,
        lanes: [...data.lanes, `Lane ${data.lanes.length + 1}`],
      });
    }
  };

  const onDeleteLane = (idx) => {
    data.lanes.splice(idx, 1);
    setData({ ...data });
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={visibility}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={visibility}>
          <div className={classes.paper}>
            <div className={classes.modalHeader}>
              <span>
                Add Survey
                {error && <span className={classes.errorText}> {message}</span>}
              </span>
              <span onClick={() => handleClose()}>
                <CloseRounded />
              </span>
            </div>
            <div className={classes.modalContent}>
              <TextField
                className={`${classes.field} ${
                  error && !data?.surveyName && classes.errorBorder
                }`}
                label="Survey Name"
                variant="outlined"
                id="mui-theme-provider-outlined-input"
                value={data?.surveyName}
                onChange={(e) =>
                  setData({ ...data, surveyName: e.target.value })
                }
              />
              {data?.lanes?.map((lane, idx) => (
                <OutlinedInput
                  labelWidth={0.1}
                  key={idx}
                  className={`${classes.field} ${
                    error && !lane && classes.errorBorder
                  }`}
                  label={false}
                  variant="outlined"
                  id="mui-theme-provider-outlined-input"
                  value={lane}
                  onChange={(e) => {
                    data.lanes[idx] = e.target.value;
                    setData({
                      ...data,
                    });
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => onDeleteLane(idx)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              ))}

              <Button
                variant="contained"
                className={`${classes.field} ${classes.w30}`}
                onClick={() => onAddLaneBtnClick()}
                disabled={data?.lanes?.length > 4}
              >
                Add {data?.lanes?.length ? "More" : ""} Lane
              </Button>
            </div>
            <div className={classes.saveButtonContainer}>
              <Button variant="contained" onClick={() => onAddBtnClick()}>
                {selectedSurvey === null ? "Save" : "Update"}{" "}
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
