import React, { useEffect, useState } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/";
import { CloseRounded } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import ColorPicker from "material-ui-color-picker";

import {
  Button,
  TextField,
  Fade,
  Backdrop,
  Modal,
  MenuItem,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontWeight: "bold",
    fontSize: "20px",
    borderBottom: "1px solid #dcdcdc73",
    paddingBottom: "5px",
  },
  modalContent: {
    display: "grid",
    maxHeight: "400px",
    overflow: "auto",
  },
  field: {
    marginTop: "10px",
  },
  errorText: {
    color: "red",
    margin: "0 5px",
  },
  errorBorder: {
    border: "1px solid red",
    borderRadius: "5px",
  },
  saveButtonContainer: {
    textAlign: "center",
    marginTop: "15px",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3),
    borderRadius: "5px",
    width: "35%",
    overflow: "auto",
    height: "700px",
  },
  w30: {
    width: "30%",
  },
}));

export default function AddDistressModal({
  visibility,
  handleClose,
  mappingData,
  project,
  onSaveHandler,
  selectedProject,
  edit,
}) {
  const classes = useStyles();
  const [attributes, setAttributes] = useState([]);
  const [attribute, setAttribute] = useState("");
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [layer, setLayer] = useState("");

  const [distressName, setDistressName] = useState("");
  const [zoomLevel, setZoomLevel] = useState(15);

  const [rules, setRules] = useState([]);

  useEffect(() => {
    if (selectedProject.length !== 0) {
      if (selectedProject[2]) {
        setAttribute(selectedProject[2]);
      }

      if (selectedProject[1]) {
        setLayer(selectedProject[1]);
      }

      if (selectedProject[0]) {
        setDistressName(selectedProject[0]);
      }

      if (selectedProject[3]) {
        setRules(selectedProject[3]);
      }
      if (selectedProject[4]) {
        setZoomLevel(selectedProject[4]);
      }
      laneClickHandler(selectedProject[1]);
    }
  }, []);

  const onAddBtnClick = () => {
    onSaveHandler(
      {
        distName: distressName,
        layerName: layer,
        attrName: attribute,
        rules: rules,
        zoomLevel: zoomLevel,
      },
      edit
    );

    handleClose();
  };
  function addRule(valueType, value, min, max, colorType, currentColor, minColor, maxColor, currentWidth) {
    let rule = {
      colorType,
      width: currentWidth,
    }
    if (colorType == 'gradient') {
      rule.minColor = minColor;
      rule.maxColor = maxColor;
    } else {
      rule.color = currentColor;
    }

    if (valueType == 'range') {
      rule.min = min;
      rule.max = max;
    } else {
      rule.value = value;
    }
    const newRules = [
      ...rules,
      rule,
    ];

    setRules(newRules);
  }
  const onDeleteCondition = (idx) => {
    let newRules = [...rules];
    newRules.splice(idx, 1);
    setRules(newRules);
  };

  const { mappingOptions: options } = mappingData[0];

  const laneClickHandler = (option) => {
    const token = `admin:geoserver`;
    const encodedToken = Buffer.from(token).toString("base64");

    if(project === "Anglesey") project = "Anglesey_1";

    const config = {
      method: "get",
      url: `https://ptsviewer.com/geoserver/rest/workspaces/${project}/datastores/${option}/featuretypes/${option}.json`,
      headers: { Authorization: "Basic " + encodedToken },
    };

    axios(config)
      .then((response) => {
        const data = response.data;
        if (data !== null) {
          let arr = data.featureType.attributes.attribute;
          setAttributes(arr);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={visibility}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={visibility}>
          <div className={classes.paper}>
            <div className={classes.modalHeader}>
              <span>
                Add Distress
                {error && <span className={classes.errorText}> {message}</span>}
              </span>
              <span onClick={() => handleClose()}>
                <CloseRounded />
              </span>
            </div>
            <div style={{ width: "100%" }}>
              <TextField
                style={{ width: "100%" }}
                label="Distress Name"
                variant="outlined"
                id="mui-theme-provider-outlined-input"
                value={distressName}
                onChange={(e) => setDistressName(e.target.value)}
              />
              <TextField
                style={{ width: "100%" }}
                label="Zoom Level"
                variant="outlined"
                id="mui-theme-provider-outlined-input"
                value={zoomLevel}
                type="number"
                onChange={(e) => setZoomLevel(e.target.value)}
              />
              <div style={{ marginTop: "10px" }}>
                {options.length ? (
                  <TextField
                    select
                    label="Select Layer"
                    variant="outlined"
                    id="mui-theme-provider-outlined-input"
                    value={layer}
                    onChange={(e) => {
                      setLayer(e.target.value);
                      laneClickHandler(e.target.value);
                    }}
                    style={{ width: "100%" }}
                    InputProps={{
                      className: classes.textField,
                    }}
                  >
                    {options.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : null}
              </div>
              <div style={{ marginTop: "10px" }}>
                {attributes.length ? (
                  <TextField
                    select
                    label="Entity"
                    variant="outlined"
                    id="attributes"
                    value={attribute}
                    // defaultValue={attribute}
                    onChange={(e) => setAttribute(e.target.value)}
                    style={{ width: "100%" }}
                    InputProps={{
                      className: classes.textField,
                    }}
                  >
                    {attributes.map((option) => (
                      <MenuItem key={option.name} value={option.name}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : null}
              </div>
              <div style={{ marginTop: "10px" }}>
                {rules.map((rule, idx) => (
                  <div
                    key={idx}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      height: "48px",
                      justifyContent: "space-between",
                      alignItems: "center",
                      border: "1px solid black",
                      color: "black",
                      borderRadius: "4px",
                      margin: "4px",
                    }}
                  >
                    {rule.condition ? (
                      <div style={{ marginLeft: "12px", marginRight: "12px" }}>
                        {rule.condition}
                      </div>
                    ) : (
                      <>
                        <div
                          style={{ marginLeft: "12px", marginRight: "12px" }}
                        >
                          {rule.min}
                        </div>
                        <div
                          style={{ marginLeft: "12px", marginRight: "12px" }}
                        >
                          {rule.max}
                        </div>
                      </>
                    )}

                    <div style={{ marginLeft: "12px", marginRight: "12px" }}>
                      {rule.value}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                      }}
                    >

                      {rule.color ? <>
                        <div
                          style={{
                            width: "18px",
                            height: "18px",
                            backgroundColor: `${rule.color}`,
                            borderRadius: "4px",
                            marginLeft: "5px",
                            marginRight: "5px",
                          }}
                        ></div>
                        <div>{rule.color}</div></> : null}
                      {rule.minColor ? <>
                        <div
                          style={{
                            width: "18px",
                            height: "18px",
                            backgroundColor: `${rule.minColor}`,
                            borderRadius: "4px",
                            marginLeft: "5px",
                            marginRight: "5px",
                          }}
                        ></div>
                        <div>{rule.minColor}</div></> : null}
                      {rule.maxColor ? <>
                        <div
                          style={{
                            width: "18px",
                            height: "18px",
                            backgroundColor: `${rule.maxColor}`,
                            borderRadius: "4px",
                            marginLeft: "5px",
                            marginRight: "5px",
                          }}
                        ></div>
                        <div>{rule.maxColor}</div></> : null}
                    </div>
                    <div style={{ marginLeft: "12px", marginRight: "12px" }}>
                      {rule.width}
                    </div>
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => onDeleteCondition(idx)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                ))}
              </div>
              <AddCondtion addRule={addRule} attribute={attribute} />
            </div>
            <div className={classes.saveButtonContainer}>
              <Button variant="contained" onClick={() => onAddBtnClick()}>
                {edit ? "Update" : "Save"}
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

function AddCondtion({ attribute, addRule }) {
  const [currentColor, setCurrentColor] = useState("");
  const [maxColor, setMaxColor] = useState("");
  const [minColor, setMinColor] = useState("");
  const [currentWidth, setCurrentWidth] = useState("");
  const [colorType, setColorType] = useState("");
  const [valueType, setValueType] = useState("");
  const [value, setValue] = useState("");
  const [min, setMin] = useState("");
  const [max, setMax] = useState("");
  const classes = useStyles();

  function onAddConditionBtnClick() {
    addRule(valueType, value, min, max, colorType, currentColor, minColor, maxColor, currentWidth);
    setCurrentColor("");
    setColorType("");
    setValueType("");
    setValue("");
    setMaxColor("");
    setMinColor("");
    setCurrentWidth("");
    setMin("");
    setMax("");

  };
  return (<>
    <div style={{ marginTop: "15px" }}>
      {attribute ? <div style={{ display: "flex", flexDirection: "column" }}>
        <TextField
          select
          style={{
            marginLeft: "5px",
            marginRight: "5px",
          }}
          // defaultValue=""
          label="Value Type"
          variant="outlined"
          id="value_type"
          name="Value_Type"
          value={valueType}
          onChange={(e) => setValueType(e.target.value)}
        >{['range', 'equal'].map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}</TextField>
        {
          valueType == "range" ?
            <>        <TextField
              type="number"
              label="Min"
              variant="outlined"
              id="mui-theme-provider-outlined-input"
              value={min}
              name="min"
              onChange={(e) => setMin(e.target.value)}
              style={{
                marginLeft: "5px",
                marginRight: "5px",
              }}
              InputProps={{
                className: classes.textField,
              }}
            />
              <TextField
                type="number"
                label="Max"
                variant="outlined"
                id="mui-theme-provider-outlined-input"
                value={max}
                name="max"
                onChange={(e) => setMax(e.target.value)}
                style={{
                  marginLeft: "5px",
                  marginRight: "5px",
                }}
                InputProps={{
                  className: classes.textField,
                }}
              /></> :
            <TextField
              type="number"
              label="Value"
              variant="outlined"
              id="mui-theme-provider-outlined-input"
              value={value}
              name="value"
              onChange={(e) => setValue(e.target.value)}
              style={{
                marginLeft: "5px",
                marginRight: "5px",
              }}
              InputProps={{
                className: classes.textField,
              }}
            />

        }

        <TextField
          select
          style={{
            marginLeft: "5px",
            marginRight: "5px",
          }}
          // defaultValue=""
          label="Color Type"
          variant="outlined"
          id="color_type"
          name="Color_Type"
          value={colorType}
          onChange={(e) => setColorType(e.target.value)}
        >{['gradient', 'fill'].map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}</TextField>
        {
          colorType == 'gradient' ? (
            <>
              <ColorPicker
                id="mui-theme-provider-outlined"
                style={{
                  marginLeft: "5px",
                  marginRight: "5px",
                }}
                // defaultValue=""
                autoComplete="off"
                label="Color Min"
                variant="outlined"
                name="color min"
                value={minColor}
                InputProps={{ value: minColor }}
                onChange={(color) => {
                  setMinColor(color);
                }}
              /><ColorPicker
                id="mui-theme-provider-outlined"
                style={{
                  marginLeft: "5px",
                  marginRight: "5px",
                }}
                // defaultValue=""
                autoComplete="off"
                label="Color Max"
                variant="outlined"
                name="color max"
                value={maxColor}
                InputProps={{ value: maxColor }}
                onChange={(color) => {
                  setMaxColor(color);
                }}
              /></>
          ) :
            <ColorPicker
              id="mui-theme-provider-outlined"
              style={{
                marginLeft: "5px",
                marginRight: "5px",
              }}
              // defaultValue=""
              autoComplete="off"
              label="Color"
              variant="outlined"
              name="color"
              value={currentColor}
              InputProps={{ value: currentColor }}
              onChange={(color) => {
                setCurrentColor(color);
              }}
            />}
        <TextField
          style={{
            marginLeft: "5px",
            marginRight: "5px",
          }}
          //  defaultValue=""
          label="Width"
          variant="outlined"
          id="width_input"
          value={currentWidth}
          name="Width"
          onChange={(e) => setCurrentWidth(e.target.value)}
        />
      </div> : null}
    </div>
    <Button
      variant="contained"
      style={{ marginTop: "5px" }}
      onClick={onAddConditionBtnClick}
    >
      Add Condition
    </Button></>
  )
}
