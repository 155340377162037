import React, { Component } from 'react';
import TableList from './TableList';
import axios from 'axios';
import moment from 'moment';

class UserInteractionComponent extends Component {

    state = {
        userInteractionDuration: [],
    }

    componentDidMount(){
        axios.get("/getUserInteraction")
        .then(response => {
            if(response.status === 200){
                response.data.forEach((element,i) => {
                    let userDuration = [];
                    userDuration.push(element.uname);
                    userDuration.push(element.totalLogins);
                    userDuration.push(moment.utc(element.totaltime*1000).format('HH:mm:ss'));
                    this.setState({userInteractionDuration : [...this.state.userInteractionDuration, userDuration]});
                })
            } else{
                console.log("Error");
            }
        })
        .catch(error => {
            console.log(error);
        })
    }

    render(){
        return (
            <div>
                <TableList
                    userInteractionData={this.state.userInteractionDuration}
                />
            </div>
        )
    }
}

export default UserInteractionComponent;