import React from "react";
import MappingTable from "../components/MappingTable";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

export default function LayerWFSMapping({
  mappingData,
  dropdownValues,
  config,
  setMappingData,
}) {
  const { prefix, suffix, mapping } = config;

  const convertFilterData = (data) => {
    var rows = [];
    data.forEach((childData) => {
      const { children } = childData;

      children.forEach((childData2,idx) => {
        const { text: section, children } = childData2;

        children.forEach((childData3) => {
          const { id, text, children } = childData3;
          if(children){
            children.forEach(({ id: lane, text: laneTxt }) => {
              rows.push({
                section,
                name: text,
                lane,
                laneTxt,
                mappingOptions: dropdownValues,
                mapValue:
                  mapping[lane] && mapping[lane][0] &&
                  mapping[lane][0].replace(prefix, "").replace(suffix, ""),
                id,
              });
            });
          }
        });
      });
    });
    return rows;
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <MappingTable
            tableHeaderColor="primary"
            tableHead={["Level", "Survey Name", "Lanes", "SHP File"]}
            tableData={convertFilterData(mappingData)}
            tableTitle="Lane - SHP Mapping"
            setMappingData={setMappingData}
          />
        </GridItem>
      </GridContainer>
    </>
  );
}
