import axios from "axios";

class UploadDocsService {
  upload(hostName, endPoint, project, folder, file, onUploadProgress) {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('projectName', project);
    formData.append('folderName', folder);
    return axios.post(hostName + endPoint, formData, {
      headers: { 
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress
    });
  }
}
export default new UploadDocsService();