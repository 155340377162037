import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TablePagination from "@material-ui/core/TablePagination";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Switch from "@material-ui/core/Switch";
import { green } from "@material-ui/core/colors";

// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
// import { ExpandLess, ExpandMore } from "@material-ui/icons";

const useStyles = makeStyles(styles);

const FilterSwitch = withStyles({
  switchBase: {
    color: green[300],
    "&$checked": {
      color: green[500],
    },
    "&$checked + $track": {
      backgroundColor: green[500],
    },
  },
  checked: {},
  track: {},
})(Switch);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function DistressTable(props) {
  const classes = useStyles();
  const { tableHead, tableData, tableHeaderColor, tableCellOnClick } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("District Name");
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                    sortDirection={orderBy === key ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === key}
                      direction={orderBy === key ? order : "asc"}
                      onClick={(event) => handleRequestSort(event, key)}
                    >
                      {prop}
                    </TableSortLabel>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {stableSort(tableData, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((propx, key) => {
              const [
                distName,
                layerName,
                attrName,
                rules,
              ] = propx;
              return (
                <React.Fragment key={key}>
                  <TableRow key={key} className={classes.tableBodyRow}>
                    <TableCell className={classes.tableCell} key={key}>
                      {distName}
                    </TableCell>

                    <TableCell className={classes.tableCell} key={key}>
                      {layerName}
                    </TableCell>

                    <TableCell className={classes.tableCell} key={key}>
                      {attrName}
                    </TableCell>

                    <TableCell className={classes.tableCell} key={key}>
                      {rules.length}
                    </TableCell>

                     {/* <TableCell className={classes.tableCell}>
                      <FilterSwitch
                        checked={Active}
                        onClick={(e) =>
                          props.handleUpdateSwitch(
                            page * rowsPerPage + key,
                            propx,
                            e.target.checked
                          )
                        }
                      />
                    </TableCell>  */}

                    {props.hideEditDelete ? null : (
                      <TableCell className={classes.tableCell}>
                        <EditIcon
                          style={{ marginRight: "20px", color: "skyblue" }}
                          onClick={() =>
                            props.handleEditRow(page * rowsPerPage + key, propx)
                          }
                        />
                        <DeleteIcon
                          style={{ marginRight: "20px", color: "red" }}
                          onClick={() =>
                            props.handleDeleteRow(
                              page * rowsPerPage + key,
                              propx
                            )
                          }
                        />
                      </TableCell>
                    )}
                    {props.showDelete ? (
                      <TableCell className={classes.tableCell}>
                        <DeleteIcon
                          style={{ marginRight: "20px", color: "red" }}
                          onClick={() =>
                            props.handleDeleteRow(
                              page * rowsPerPage + key,
                              propx
                            )
                          }
                        />
                      </TableCell>
                    ) : null}
                    {props.showExpantion ? (
                      <TableCell className={classes.tableCell}>
                        <ExitToAppIcon
                          onClick={() => tableCellOnClick(propx)}
                        />
                      </TableCell>
                    ) : null}
                  </TableRow>
                </React.Fragment>
              );
            })}
        </TableBody>
      </Table>
      <TablePagination
        className={classes[tableHeaderColor + "TableHeader"]}
        rowsPerPageOptions={[2, 5, 10, 25]}
        component="div"
        count={tableData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
}

DistressTable.defaultProps = {
  tableHeaderColor: "gray",
};

DistressTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  // tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};
