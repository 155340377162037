import React from "react";
import MappingTable from "../components/MappingTable";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

export default function SchemaLayerMapping({
  mappingData,
  dropdownValues,
  config,
  setMappingData,
}) {
  const { prefix, suffix, mapping } = config;

  const convertFilterData = (data) => {
    var rows = [];
    data.forEach((childData) => {
      const { children } = childData;

      children && children.forEach((childData2) => {
        const { text: section, children } = childData2;

        children && children.forEach((childData3) => {
          const { id, text, children } = childData3;
          children && children.forEach(({ id: lane, text: laneTxt }) => {
            rows.push({
              section,
              name: text,
              lane,
              laneTxt,
              mappingOptions: dropdownValues,
              mapValue:
                mapping[lane] && mapping[lane][0] &&
                mapping[lane][0]?.replace(prefix, "")?.replace(suffix, ""),
              mapValue2:
                mapping[lane] &&
                mapping[lane].length > 1 && mapping[lane][1] &&
                mapping[lane][1]?.replace(prefix, "")?.replace(suffix, ""),
              id,
            });
          });
        });
      });
    });
    return rows;
  };

  const schemeTableData = convertFilterData(mappingData);

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <MappingTable
            tableHeaderColor="primary"
            tableHead={[
              "Level",
              "Survey Name",
              "Lanes",
              "Left Lane CSV",
              "Right Lane CSV",
            ]}
            tableData={schemeTableData}
            tableTitle="Lane - Distress Mapping"
            showRightDropdown={true}
            setMappingData={setMappingData}
          />
        </GridItem>
      </GridContainer>
    </>
  );
}
