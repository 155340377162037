import React, { useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
// import Tooltip from "@material-ui/core/Tooltip";
// import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from '@material-ui/core/TablePagination';
import Button from '@material-ui/core/Button';
// @material-ui/icons
import Check from "@material-ui/icons/Check";
// core components
import styles from "assets/jss/material-dashboard-react/components/tasksStyle.js";

const useStyles = makeStyles(styles);

export default function Tasks(props) {
  const classes = useStyles();
  const tableHeaderColor = "primary";
  const [checked, setChecked] = React.useState([...props.checkedIndexes]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleToggle = value => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  useEffect(function(){
    setChecked(props.checkedIndexes);
  }, [props.checkedIndexes]);

  // if(checked !== props.checkedIndexes){
  //   setChecked(props.checkedIndexes);
  // }

  const { tasksIndexes, tasks, rtlActive } = props;
  const tableCellClasses = classnames(classes.tableCell, {
    [classes.tableCellRTL]: rtlActive
  });
  return (
    <div>
    <Table className={classes.table}>
      <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
        <TableRow className={classes.tableHeadRow}>
          <TableCell
            className={classes.tableCell + " " + classes.tableHeadCell}
          >
            Access  
          </TableCell>
          <TableCell
            className={classes.tableCell + " " + classes.tableHeadCell}
          >
            UserName  
          </TableCell>  
        </TableRow>
      </TableHead>
      <TableBody>
        {tasksIndexes
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map(value => (
          <TableRow key={value} className={classes.tableRow}>
            <TableCell className={tableCellClasses}>
              <Checkbox
                checked={checked.indexOf(value) !== -1}
                tabIndex={-1}
                onClick={() => handleToggle(value)}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.root
                }}
              />
            </TableCell>
            <TableCell className={tableCellClasses}>{tasks[value]}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
    <TablePagination
    className={classes[tableHeaderColor + "TableHeader"]}
    rowsPerPageOptions={[2, 5, 10, 25]}
    component="div"
    count={tasksIndexes.length}
    rowsPerPage={rowsPerPage}
    page={page}
    onChangePage={handleChangePage}
    onChangeRowsPerPage={handleChangeRowsPerPage}
  />
  <Button variant="outlined" size="small" color="primary" style={{marginTop: "10px"}} onClick={() => props.handleChange(checked)}>Save Changes</Button>
  </div>
  );
}

Tasks.propTypes = {
  tasksIndexes: PropTypes.arrayOf(PropTypes.number),
  tasks: PropTypes.arrayOf(PropTypes.node),
  rtlActive: PropTypes.bool,
  checkedIndexes: PropTypes.array
};
