import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Component } from 'react';
import Copyright from 'components/Copyright/Copyright.js';
import PropTypes from 'prop-types';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import Admin from "layouts/Admin.js";
import logo from 'assets/img/adapts-logo.png';

const useStyles = theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundColor: 'rgb(21, 45, 90)',
    backgroundImage: 'url(' + logo + ')',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: '650px'
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1), 
    backgroundColor: '#01579B',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#01579B"
  },
});

class AdminLogin extends Component {

  state = {
    UName : '',
    Password: '',
    showIncorrectAlert: false,
    adminLoggedin: false
  }

  componentDidMount(){
    this.setState({
      adminLoggedin: false
    })
  }

  handleUNameChange = (evt) => {
    this.setState({'UName' : evt.target.value});
  }

  handlePasswordChange = (evt) => {
    this.setState({'Password': evt.target.value});
  }  

  handleLogin = () => {
    const data = {
      'uname': this.state.UName,
      'password': this.state.Password
    }
    axios.post("/admin-authenticate", data)
    .then(response => {
      if(response.status === 200){
        console.log("Admin authenticated");
        this.setState({
            adminLoggedin: true
        })
      } else {
        this.setState({
            showIncorrectAlert: true
        });
      }
    })
    .catch(error => {
      this.setState({
        showIncorrectAlert: true
      });
    });
  }

  onConfirm = () => {
    this.setState({
      showIncorrectAlert: false
    })
  } 
  
  onCancel = () => {
    this.setState({
      showIncorrectAlert: false
    })
  }

  changeAdminState = () => {
      this.setState({
        adminLoggedin: false
      });
  }
  
  render(){
    const { history, classes } = this.props;
    let incorrect = <SweetAlert title="Username/Password Incorrect !" onConfirm={this.onConfirm} onCancel={this.onCancel} />
    let adminLoginPage = <Admin changeAdminState={this.changeAdminState} />;
    console.log(this.state);
    return (
        (this.state.adminLoggedin ? adminLoginPage : 
            <Grid container component="main" className={classes.root}>
        {this.state.showExpiredAlert?alert: null}
        {this.state.showIncorrectAlert?incorrect: null}
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Admin Sign in
            </Typography>
            <form className={classes.form}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="userId"
                label="User Id"
                name="userId"
                autoFocus
                onChange={this.handleUNameChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={this.handlePasswordChange}
              />
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={this.handleLogin}
              >
                Sign In
              </Button>
              <Box mt={5}>
                <Copyright />
              </Box>
            </form>
          </div>
        </Grid>
      </Grid>)
      
    );
  }
}

AdminLogin.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(useStyles)(AdminLogin));