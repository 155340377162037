import React from "react";
import { find } from "lodash";
import MappingTable from "../components/MappingTable";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

export default function SCRIMDataMapping({
  mappingData,
  dropdownValues,
  config,
  setMappingData,
}) {
  const { prefix, suffix, mapping } = config;

  const convertFilterData = (data) => {
    //console.log("SCRIM Data " , data);
    var rows = [];
    data.forEach((childData) => {
      const { children } = childData;
      const dataObj = find(children, { text: "Sample Level Survey" });

      if (dataObj) {
        const { text: section, children } = dataObj;
        children.forEach((childData3) => {
          const { id, text, children } = childData3;
          children.forEach(({ id: lane, text: laneTxt }) => {
            rows.push({
              section: null,
              name: text,
              lane,
              laneTxt,
              mappingOptions: dropdownValues,
              mapValue:
                mapping[lane] && mapping[lane][0] &&
                mapping[lane][0]?.replace(prefix, "")?.replace(suffix, ""),
              id,
            });
          });
        });
      }
    });
    return rows;
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <MappingTable
            tableHeaderColor="primary"
            tableHead={["Survey Name" , "Lanes", "File"]}
            tableData={convertFilterData(mappingData)}
            tableTitle="Lane - SCRIM Data Mapping"
            setMappingData={setMappingData}
          />
        </GridItem>
      </GridContainer>
    </>
  );
}
