import React, { Component } from 'react'; 
import Dashboard from './Dashboard';
import axios from 'axios';
import ReactLoading from 'react-loading';
import SweetAlert from 'react-bootstrap-sweetalert';
import Calendar from 'react-calendar';
import TextField from '@material-ui/core/TextField';


class DashboardComponent extends Component {

    state = {
        userData: [],
        loading: true,
        deleteMessagePopup: false,
        createMessagePopup: false,
        editMessagePopup: false,
        loadingMessagePopup: false,
        deleteId: null,
        newUserName: "",
        newUserPassword: "",
        newUserExpiry: new Date(),
        date: new Date(),
        showCalender: false,
        editUserId: "",
        editUserName: "",
        editUserPass: "",
        editUserExpiry: new Date()
    }

    getall(){
        axios.get('/user/all')
        .then(response =>{
            if(response.status === 200){
                const resUserData = [];
                response.data.forEach(element => {
                    resUserData.push([
                        element.uid,
                        element.uname,
                        element.password,
                        element.expiry
                    ]);
                });
                this.setState({
                    userData: resUserData, 
                    loading: false,
                    deleteMessagePopup: false,
                    createMessagePopup: false,
                    editMessagePopup: false,
                });
            } else{
                this.setState({
                    loadingMessagePopup: true
                });
            }
        })
        .catch(error => {
            this.setState({
                loadingMessagePopup: true
            });
        });
    }

    componentDidMount(){
        this.getall();
    }

    deleteUser = () => {
        const uid = this.state.userData[this.state.deleteId][0];
        const url = "/user/" + uid;
        axios.delete(url)
        .then(response => {
            if(response.status === 200){
                const uData = [...this.state.userData];
                uData.splice(this.state.deleteId, 1);
                this.setState({
                    userData: uData,
                    deleteId: null,
                    deleteMessagePopup: false
                });
            } else{
                this.setState({
                    deleteId: null,
                    deleteMessagePopup: false
                });
            }
        })
        .catch(error => {
            this.setState({
                deleteId: null,
                deleteMessagePopup: false
            });
        })
    }

    handleDeleteRow = (values) => {
        this.setState({deleteMessagePopup: true, deleteId: values});
    }

    handleEditRow = (values) => {
        const uData = [...this.state.userData][values];
        this.setState({
            editUserId: uData[0],
            editUserName: uData[1],
            editUserPass: uData[2],
            editUserExpiry: new Date(uData[3]),
            editMessagePopup: true
        });
    }

    handleCreate = () => {
        this.setState({createMessagePopup: true});
    }

    cancelDelete = () => {
        this.setState({
            deleteMessagePopup: false,
            deleteId: null
        });
    }

    onCancelCreate = () => {
        this.setState({
            createMessagePopup: false,
            newUserName:"",
            newUserPassword:"",
            newUserExpiry:new Date()
        });
    }

    onCancelEdit = () => {
        this.setState({
            editMessagePopup: false
        });
    }

    onConfirmCreate = () => {
        const data = {
            "uname" : this.state.newUserName,
            "password" : this.state.newUserPassword,
            "expiry" : this.state.newUserExpiry
        }
        this.setState({
            createMessagePopup:false,
            newUserName: "",
            newUserPassword: "",
            newUserExpiry: new Date()
        });
        axios.post("/user", data)
        .then(response => {
            if(response.status === 200){
                const uData = this.state.userData;
                uData.push([response.data.uid, response.data.uname, response.data.password, response.data.expiry]);
                this.setState({
                    userData: uData
                });
            } else{
                console.log("error");
            }
        })
        .catch(error => {
            console.log("Error", error);
        });
    }

    onConfirmEdit = () => {
        const data = {
            "uid":this.state.editUserId,
            "uname" : this.state.editUserName,
            "password" : this.state.editUserPass,
            "expiry" : this.state.editUserExpiry
        }
        axios.put("/user", data)
        .then(response => {
            if(response.status === 200){
                this.getall();
            } else{
                console.log("Error in updating user..");
            }
        })
        .catch(error => {
            console.log("Error in updating user..");
        })
    }

    onChange = fundate => {
        this.setState({ 
            date: fundate, 
            showCalender: false,
            newUserExpiry: fundate
        });

    }

    onChangeEdit = fundate => {
        this.setState({ 
            date: fundate, 
            showCalender: false,
            editUserExpiry: fundate
        });

    }

    openCalender = () => {
        this.setState({
            showCalender: true
        })
    }

    errorCancel = () => {
        this.setState({
            deleteMessagePopup: false,
            createMessagePopup: false,
            editMessagePopup: false,
            loadingMessagePopup: false
        });
    }

    render(){
        let dashboard = <div style={{position:"relative", left:"50%"}}>
        <ReactLoading color={"#00ACC1"} height='10%' width='10%'/>
        </div>;
        if(this.state.loading === false){
            dashboard = 
            <Dashboard 
                resData={this.state.userData}
                handleEditRow={this.handleEditRow}
                handleDeleteRow={this.handleDeleteRow}
                handleCreate={this.handleCreate}
            />
        }

        let deleteMessage = 
        <SweetAlert
            warning
            showCancel
            confirmBtnText="Yes, delete it!"
            title = "Are you sure you want to delete ?"
            onConfirm={this.deleteUser}
            onCancel={this.cancelDelete}
            focusCancelBtn
        />;

        let createMessage = 
        <SweetAlert
            showCancel
            title={"Create New User"}
            onConfirm={this.onConfirmCreate}
            onCancel={this.onCancelCreate}
            type={'controlled'}
            dependencies={[this.state.newUserName, this.state.newUserPassword, this.state.newUserExpiry]}
            >
            <form>
                <TextField
                    label="Name"
                    variant="outlined"
                    color="secondary"
                    value={this.state.newUserName}
                    onChange={(e) => this.setState({ newUserName: e.target.value })}
                />
                <br />
                <br />
                <TextField
                    label="Password"
                    variant="outlined"
                    color="secondary"
                    value={this.state.newUserPassword}
                    onChange={(e) => this.setState({ newUserPassword: e.target.value })}
                />
                <br />
                <br />
                <TextField
                    variant="outlined"
                    label="Expiry Date"
                    color="secondary"
                    value={this.state.newUserExpiry}
                    onClick={this.openCalender}
                />
                {this.state.showCalender?<Calendar
                    onChange={this.onChange}
                    value={this.state.date}
                    style={{
                        width: "300px"
                    }}
                />:null }
                <hr/>
            </form>
        </SweetAlert>


        let editMessage = 
        <SweetAlert
            showCancel
            title={"Edit User"}
            onConfirm={this.onConfirmEdit}
            onCancel={this.onCancelEdit}
            type={'controlled'}
            dependencies={[this.state.editUserName, this.state.editUserPass, this.state.editUserExpiry]}
            >
            <form>
                <TextField
                    label="Name"
                    variant="outlined"
                    color="secondary"
                    value={this.state.editUserName}
                    onChange={(e) => this.setState({ editUserName: e.target.value })}
                />
                <br />
                <br />
                <TextField
                    label="Password"
                    variant="outlined"
                    color="secondary"
                    value={this.state.editUserPass}
                    onChange={(e) => this.setState({ editUserPass: e.target.value })}
                />
                <br />
                <br />
                <TextField
                    variant="outlined"
                    label="Expiry Date"
                    color="secondary"
                    value={this.state.editUserExpiry}
                    onClick={this.openCalender}
                />
                {this.state.showCalender?<Calendar
                    onChange={this.onChangeEdit}
                    value={this.state.editUserExpiry}
                    style={{
                        width: "300px"
                    }}
                />:null }
                <hr/>
            </form>
        </SweetAlert>

        let errorMessage = 
        <SweetAlert 
            error
            title="Error in connecting backend !!"
            onCancel={this.errorCancel}
            onConfirm={() => null}

        />
        return(
            <div>
                {this.state.deleteMessagePopup ? deleteMessage : null}
                {this.state.createMessagePopup ? createMessage: null}
                {this.state.editMessagePopup ? editMessage: null}
                {this.state.loadingMessagePopup ? errorMessage: null}
                {dashboard}
            </div>  
        );
    }
}

export default DashboardComponent;