import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Component } from 'react';
import Copyright from 'components/Copyright/Copyright.js';
import PropTypes from 'prop-types';
import axios from 'axios';
import cookie from 'react-cookies';
import { withRouter } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ShareIcon from '@material-ui/icons/Share';
import Divider from '@material-ui/core/Divider';
import logo from '../../assets/img/adapts-logo.png';

const useStyles = theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundColor: 'rgb(21, 45, 90)',
    backgroundImage: 'url(' + logo + ')',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: '650px'
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1), 
    backgroundColor: '#01579B',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#01579B"
  },
});

class UserLogin extends Component {

  componentDidMount(){
  }

  state = {
    UName : '',
    Password: '',
    showExpiredAlert: false,
    showIncorrectAlert: false,
    userLoggedIn: false,
    availableProj: [],
    projectFetched: false
  }

  handleUNameChange = (evt) => {
    this.setState({'UName' : evt.target.value});
  }

  handlePasswordChange = (evt) => {
    this.setState({'Password': evt.target.value});
  } 
  
  fetchProjects = () => {
    const data = {
      'username' : this.state.UName
    }
    axios.post("/getUserProject2", data)
    .then(response => {
      if(response.status === 200){
        this.setState({
          availableProj: [...response.data.projects],
          projectFetched: true
        });
      } else {
        console.log("Error in fetching projects for user");
      }
    }).catch(error => {
      console.log(error);
      console.log("Error in fetching projects for user");
    });
  }

  randomIntFromInterval=(min, max) =>{ 
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  getCurrentDateTime=()=>{
    return new Date().toISOString().replace(", ","-");
  }

  handleLogin = () => {
   const sessionId = `${
     this.state.UName
   }-${this.getCurrentDateTime()}-${this.randomIntFromInterval(0, 100)}`;

    const data = {
      uname: this.state.UName,
      password: this.state.Password,
      sessionId,
      loginTime: this.getCurrentDateTime(),
    };
    axios.post("/authenticate", data)
    .then(response => {
      console.log('1', response)
      if(response.status === 200){
        console.log('2')
        const currentTime = new Date().getTime();
        const expiryTime = new Date(response.data.expiry).getTime();
        if(expiryTime > currentTime){
          console.log('3')
          cookie.save('VideoAppUserId' , response.data.uname);
          cookie.save('VideoAppExpiryDate', response.data.uname + "_" + (currentTime + (72*60*60*1000)));
          this.setState({
            userLoggedIn: true,
            projectFetched: false
          });
          this.fetchProjects();
        } else {
          console.log('4')
          this.setState({
            showExpiredAlert: true
          })
        }
        cookie.save('sessionId' , sessionId);
      } else {
        console.log('5')
        this.setState({
          showIncorrectAlert: true
        })
      }
    })
    .catch(error => {
          console.log('4')
          console.log("Error", error);
      this.setState({
        showIncorrectAlert: true
      })
    });
  }

  onConfirm = () => {
    this.setState({
      showExpiredAlert: false,
      showIncorrectAlert: false
    })
  } 
  
  onCancel = () => {
    this.setState({
      showExpiredAlert: false,
      showIncorrectAlert: false
    })
  }

  handleProjectClick = (clickProject) => {
    console.log(clickProject); 
    cookie.save('ProjectName' , clickProject);
    this.props.history.push("/success");
  }
  
  render(){
    const { classes } = this.props;
    let alert = <SweetAlert title="Login Expired !" onConfirm={this.onConfirm} onCancel={this.onCancel} />
    let incorrect = <SweetAlert title="UserId/Password Incorrect !" onConfirm={this.onConfirm} onCancel={this.onCancel} />
    let projectToDisplay = 
    <List>
      {
        this.state.availableProj.map( (val, idx) => {
          return (
            <div key={idx}>
              <ListItem button onClick={() => this.handleProjectClick(val)}>
                <ListItemIcon>
                  <ShareIcon />
                </ListItemIcon>
              <ListItemText primary={val} />
              </ListItem>
              <Divider />
            </div>
          )
        })
      }
    </List>

    return (
      (this.state.userLoggedIn ? 
      <div>
        <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOpenOutlinedIcon />
            </Avatar>
            <Typography component="h4" variant="h5">
              Welcome {this.state.UName}
            </Typography>
            <Typography variant="subtitle2" align="left" style={{ marginTop: '80px'}}>
              Available Projects :
            </Typography>
            <Paper style={{maxHeight: 300, overflow: 'auto', marginTop: '30px'}}>
            {
              this.state.projectFetched ? projectToDisplay
              :<p>Fetching Projects...</p>
            }
          </Paper>
          </div>
        </Grid>
      </Grid>

      </div> : 
        <Grid container component="main" className={classes.root}>
        {this.state.showExpiredAlert?alert: null}
        {this.state.showIncorrectAlert?incorrect: null}
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <form className={classes.form}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="userId"
                label="User Id"
                name="userId"
                autoFocus
                onChange={this.handleUNameChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={this.handlePasswordChange}
              />
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={this.handleLogin}
              >
                Sign In
              </Button>
              <Box mt={5}>
                <Copyright />
              </Box>
            </form>
          </div>
        </Grid>
      </Grid>)
    );
  }
}

UserLogin.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(useStyles)(UserLogin));