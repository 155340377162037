import axios from "axios";
import React, { Component } from "react";
import { cloneDeep, map, max, min, compact, sortBy, findIndex } from "lodash";
import ReactLoading from "react-loading";
import { Button, Snackbar } from "@material-ui/core";
import { TextField, MenuItem } from "@material-ui/core";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import SCRIMDataMapping from "./mapping/SCRIMDataMapping";

import ProjectFilterDashboard from "./ProjectFilterDashboard";
import ProjectLayerDashboard from "./ProjectLayerDashboard";
import ProjectDistressDashboard from "./ProjectDistressDashboard";
import AssetNeedsRegister from "./AssetNeedsRegister";
import LayerWFSMapping from "./mapping/LayerWFSMapping";
import ImportDataMapping from "./mapping/ImportDataMapping";
import ParameterCSVMapping from "./mapping/ParameterCSVMapping";
import SectionLevelLayerMapping from "./mapping/SectionLevelLayerMapping";
import SampleLevelLayerMapping from "./mapping/SampleLevelLayerMapping";

import SchemaLayerMapping from "./mapping/SchemaLayerMapping";
import ModelingDataMapping from "./mapping/ModelingDataMapping";
const hostname = "https://ptsviewer.co.uk:10000";
const surfaceDistressOptions = [
  "Bleeding",
  "L&T Cracking",
  "Potholes",
  "Beeding",
  "Edge cracking",
  "Longitudinal and Transverse cracking",
  "Polished aggregate",
  "Ravelling",
  "Weathering",
];
const structureDistressOptions = [
  "Bumps and Sags",
  "Rutting",
  "Swell",
  "Depressions",
  "Wheel track cracking",
  "Block cracking",
  "Bumps",
  "Corrugation",
  "Depressions",
  "Patching and utility cuts",
  "Rutting",
  "Shoving",
  "Swelling",
];
const scrimDistressOptions =[
  "AVG_CSC",
"IL",
"AVG_SCRIM",
"NO_ACCIDENT",
"RANK",
"SITE_RATING"
]

const surfaceStructureDistressIdMap = {
  "Wheel track cracking": 1,
  Bleeding: 2,
  "Block cracking": 3,
  "Bumps and Sags": 4,
  Corrugation: 5,
  Depressions: 6,
  "Edge Cracking": 7,
  "L&T Cracking": 10,
  "Patching and utility cuts": 11,
  "Polished aggregate": 12,
  Potholes: 13,
  Rutting: 15,
  Shoving: 16,
  Swell: 18,
  Ravelling: 19,
  Weathering: 20,
};

const initialSampleLayerData = [
  {
    id: 0,
    text: "Surveys",
    state: {
      selected: false,
    },
    icon: "./assets/map.png",
    children: [
      {
        id: 1,
        text: "Section Level Survey",
        state: {
          selected: false,
        },
        icon: "./assets/map.png",
        children: [],
      },
      {
        id: 10,
        text: "Sample Level Survey",
        state: {
          selected: false,
        },
        icon: "./assets/map.png",
        children: [],
      },
    ],
  },
];

class ProjectUploadComponent extends Component {
  state = {
    openNotification: false,
    isSaving: false,
    filterData: [],
    layerData: [],
    sampleLayerData: initialSampleLayerData,
    layerWFSData: [],
    levelLayerData: [],
    schemaLayerData: [],
    dropdownValues: [],
    schemeDropdownValues: [],
    sectionLevelDropdownValues: [],
    sampleLevelDropdownValues: [],
    importDataDropdownValues: [],
    scrimDataDropdownValues: [],
    modelingDataDropdownValues: [],
    parameterCSVDropdownValues: [],
    layerWFSConfig: {
      prefix: `https://ptsviewer.com/geoserver/adapts-devbox/wfs?service=WFS&version=2.0.0&request=GetFeature&typename=adapts-devbox:`,
      suffix: "&outputFormat=application/json&srsname=EPSG:3857&bbox=",
      mapping: {},
    },
    importDataConfig: {
      prefix: `data/Area-14/ImportData/`,
      suffix: ".csv",
      mapping: {},
    },
    scrimDataConfig: {
      prefix: `data/Area-14/SCRIMData/`,
      suffix: ".csv",
      mapping: {},
    },
    modelingDataConfig: {
      prefix: `data/Area-14/Modeling/`,
      suffix: ".csv",
      mapping: {},
    },
    parameterCSVDataConfig: {
      prefix: `data/Area-14/ImportData/`,
      suffix: ".csv",
      mapping: {},
    },
    levelLayerConfig: {
      prefix: "data/Area14/Section/",
      samplePrefix: "data/Area14/Sample/",
      suffix: ".csv",
      mapping: {},
    },
    distressLayerData: null,
    schemaLayerConfig: {
      prefix: "data/Area14/Scheme-Lane/",
      suffix: ".csv",
      mapping: {},
    },
    projectList: [],
    project: "",
    buffering: true,
    fetchingProjectLayer: false,
    fetchingImportData: false,
    fetchingSCRIMData: false,
    fetchingModelingData: false,
    fetchingParameterCSVData: false,
    fetchingProjectLevelLayer: false,
    fetchingProjectSchemaLayer: false,
    fetchingProjectLayerWFS: false,
    fetchingProjectFilter: false,
    fetchingDropdownList: false,
    fetchingCSVFiles: false,
  };

  transformProjectFilterDataForView = (data) => {
    const updatedData = [];
    data.forEach((grandParentObj) => {
      const {
        name: grandParentName,
        ranges: grandParentRanges,
      } = grandParentObj;

      if (["Surface Defects", "Structure Defects", "Parameter Data", "SCRIM"].includes(grandParentName)) {
        grandParentRanges.forEach((parentObj) => {
          if (parentObj.ranges && parentObj.ranges.length) {
            updatedData.push(parentObj);
          }
        });
      } else {
        updatedData.push(grandParentObj);
      }
    });
    return updatedData;
  };

  fetchProjectFilter = (project) => {
    const data = {
      projectName: project,
    };

    this.setState(
      {
        fetchingProjectFilter: true,
      },
      () => {
        axios
          .post(`${hostname}/getProjectFilter`, data)
          .then((response) => {
            this.setState({
              fetchingProjectFilter: false,
              filterData: this.transformProjectFilterDataForView(response.data),
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    );
  };

  fetchProjectLayer = (project) => {
    const data = {
      projectName: project,
    };
    const { sampleLayerData } = this.state;
    this.setState(
      {
        fetchingProjectLayer: true,
      },
      () => {
        axios
          .post(`${hostname}/getProjectLayers`, data)
          .then((response) => {
            let layerData = response.data;
            if (Object.keys(layerData)?.length === 0) {
              layerData = sampleLayerData;
            }
            if (layerData?.length) {
              let distressLayerData = null;
              let distressIdx = findIndex(layerData[0].children, {
                id: 100,
              });

              if (distressIdx !== -1 || layerData[0].children[2] === null) {
                distressLayerData = layerData[0].children.splice(distressIdx,1);
              }

              if (Array.isArray(distressLayerData)) {
                distressLayerData = distressLayerData[0];
              }

              this.setState(
                {
                  layerData,
                  distressLayerData,
                  fetchingProjectLayer: false,
                },
                () => {
                  this.fetchProjectLayerWFS({ project });
                  this.fetchImportData({ project });
                  this.fetchSCRIMData({ project });
                  this.fetchParameterCSVData({ project });
                  this.fetchProjectLevelLayer({ project });
                  this.fetchProjectSchemaLayer({ project });
                  this.fetchModelingData({ project });
                }
              );
            }
          })
          // .catch((error) => {
          //   console.log(error);
          // });
      }
    );
  };

  fetchDropdownList = (project) => {
    const token = `admin:geoserver`;
    const encodedToken = Buffer.from(token).toString("base64");

    let workSpaceName = project === "Anglesey" ? "Anglesey_1" : project;

    const config = {
      method: "get",
      url: `https://ptsviewer.com/geoserver/rest/workspaces/${workSpaceName}/datastores`,
      headers: { Authorization: "Basic " + encodedToken },
    };

    this.setState(
      {
        fetchingDropdownList: true,
      },
      () => {
        axios(config)
          .then((response) => {
            const { dataStores } = response.data;
            this.setState({
              fetchingDropdownList: false,
              dropdownValues: map(dataStores.dataStore, "name"),
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    );

    const data = {
      projectName: project,
    };

    this.setState(
      {
        fetchingCSVFiles: true,
      },
      () => {
        axios
          .post(`${hostname}/getCSVFiles`, data)
          .then((response) => {
            const schemeDropdownValues = [];
            const sectionLevelDropdownValues = [];
            const sampleLevelDropdownValues = [];
            const importDataDropdownValues = [];
            const scrimDataDropdownValues = [];
            const modelingDataDropdownValues = [];
            const parameterCSVDropdownValues = [];

            response.data["Scheme-Lane"].forEach((val) => {
              let updatedVal = val;
              updatedVal = val.replace(".csv", "");
              updatedVal = updatedVal.replace(".xlsx", "");
              schemeDropdownValues.push(updatedVal);
            });

            response.data["Section"].forEach((val) => {
              let updatedVal = val;
              updatedVal = val.replace(".csv", "");
              updatedVal = updatedVal.replace(".xlsx", "");
              sectionLevelDropdownValues.push(updatedVal);
            });

            response.data["Sample"].forEach((val) => {
              let updatedVal = val;
              updatedVal = val.replace(".csv", "");
              updatedVal = updatedVal.replace(".xlsx", "");
              sampleLevelDropdownValues.push(updatedVal);
            });

            response.data["ImportData"].forEach((val) => {
              let updatedVal = val;
              updatedVal = val.replace(".csv", "");
              updatedVal = updatedVal.replace(".xlsx", "");
              importDataDropdownValues.push(updatedVal);
            });
            response.data["SCRIMData"].forEach((val) => {
              let updatedVal = val;
              updatedVal = val.replace(".csv", "");
              updatedVal = updatedVal.replace(".xlsx", "");
              scrimDataDropdownValues.push(updatedVal);
            });
            response.data["Modeling"].forEach((val) => {
              let updatedVal = val;
              updatedVal = val.replace(".csv", "");
              updatedVal = updatedVal.replace(".xlsx", "");
              modelingDataDropdownValues.push(updatedVal);
            });

            response.data["ParameterData"].forEach((val) => {
              let updatedVal = val;
              updatedVal = val.replace(".csv", "");
              updatedVal = updatedVal.replace(".xlsx", "");
              parameterCSVDropdownValues.push(updatedVal);
            });

            this.setState({
              schemeDropdownValues,
              sectionLevelDropdownValues,
              sampleLevelDropdownValues,
              importDataDropdownValues,
              scrimDataDropdownValues,
              modelingDataDropdownValues,
              parameterCSVDropdownValues,
              fetchingCSVFiles: false,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    );
  };

  fetchProjectLayerWFS = ({ project }) => {
    const data = {
      projectName: project,
    };
    const { layerWFSConfig } = this.state;
    this.setState(
      {
        fetchingProjectLayerWFS: true,
      },
      () => {
        axios
          .post(`${hostname}/getProjectLayerWFS`, data)
          .then((response) => {
            this.setState({
              fetchingProjectLayerWFS: false,
              layerWFSConfig: {
                ...layerWFSConfig,
                mapping: response.data,
              },
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    );
  };

  fetchParameterCSVData = ({ project }) => {
    const data = {
      projectName: project,
    };
    const { parameterCSVDataConfig } = this.state;
    this.setState(
      {
        fetchingParameterCSVData: true,
      },
      () => {
        axios
          .post(`${hostname}/getProjectParameterData`, data)
          .then((response) => {
            this.setState({
              fetchingParameterCSVData: false,
              parameterCSVDataConfig: {
                ...parameterCSVDataConfig,
                mapping: response.data,
              },
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    );
  };

  fetchImportData = ({ project }) => {
    const data = {
      projectName: project,
    };
    const { importDataConfig } = this.state;
    this.setState(
      {
        fetchingImportData: true,
      },
      () => {
        axios
          .post(`${hostname}/getProjectImportData`, data)
          .then((response) => {
            this.setState({
              fetchingImportData: false,
              importDataConfig: {
                ...importDataConfig,
                mapping: response.data,
              },
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    );
  };
  fetchSCRIMData = ({ project }) => {
    const data = {
      projectName: project,
    };
    const { scrimDataConfig } = this.state;
    this.setState(
      {
        fetchingSCRIMData: true,
      },
      () => {
        axios
          .post(`${hostname}/getProjectSCRIMData`, data)
          .then((response) => {
            this.setState({
              fetchingSCRIMData: false,
              scrimDataConfig: {
                ...scrimDataConfig,
                mapping: response.data,
              },
            });
          })
          .catch((error) => {
            this.setState({fetchSCRIMData: false})
            console.log(error);
          });
      }
    );
  };

  fetchModelingData = ({ project }) => {
    const data = {
      projectName: project,
    };
    const { modelingDataConfig } = this.state;
    this.setState(
      {
        fetchingModelingData: true,
      },
      () => {
        axios
          .post(`${hostname}/getProjectModelingData`, data)
          .then((response) => {
            this.setState({
              fetchingModelingData: false,
              modelingDataConfig: {
                ...modelingDataConfig,
                mapping: response.data,
              },
            });
          })
          .catch((error) => {
            this.setState({fetchModelingData: false})
            console.log(error);
          });
      }
    );
  };

  fetchProjectLevelLayer = ({ project }) => {
    const data = {
      projectName: project,
    };
    const { levelLayerConfig } = this.state;
    this.setState(
      {
        fetchingProjectLevelLayer: true,
      },
      () => {
        axios
          .post(`${hostname}/getProjectLevelLayer`, data)
          .then((response) => {
            this.setState({
              fetchingProjectLevelLayer: false,
              levelLayerConfig: {
                ...levelLayerConfig,
                mapping: response.data,
              },
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    );
  };

  fetchProjectSchemaLayer = ({ project }) => {
    const data = {
      projectName: project,
    };

    const { schemaLayerConfig } = this.state;
    this.setState(
      {
        fetchingProjectSchemaLayer: true,
      },
      () => {
        axios
          .post(`${hostname}/getProjectSchemeLayer`, data)
          .then((response) => {
            this.setState({
              fetchingProjectSchemaLayer: false,
              schemaLayerConfig: {
                ...schemaLayerConfig,
                mapping: response.data,
              },
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    );
  };

  getFilterMaxMinVal = (data) => {
    const allMinValues = map(data.ranges, "min");
    let allMaxValues = compact(map(data.ranges, "max"));
    allMaxValues = allMaxValues.sort(function (a, b) {
      return a - b;
    });
    const globalMax = max(allMaxValues);
    const globalMin = min(allMinValues);
    allMaxValues.splice(allMaxValues.indexOf(globalMax), 1);
    // allMaxValues.splice(allMaxValues.indexOf(globalMin), 1);
    return { globalMax, globalMin, allMaxValues };
  };

  transformProjectFilterDataToSave = (filterData) => {
    const updatedFilterData = [];
    const surfaceDistressRanges = [];
    const structureDistressRanges = [];
    const scrimDistressRanges = [];
    const parameterDataRanges = [];

    filterData.forEach((data) => {
      const {
        globalMax,
        globalMin,
        allMaxValues: threshold,
      } = this.getFilterMaxMinVal(data);

      const obj = { ...data, globalMax, globalMin, threshold };

      if (surfaceDistressOptions.includes(data.name)) {
        obj.id = surfaceStructureDistressIdMap[data.name];
        surfaceDistressRanges.push(obj);
      } else if (structureDistressOptions.includes(data.name)) {
        obj.id = surfaceStructureDistressIdMap[data.name];
        structureDistressRanges.push(obj);
      }else if (scrimDistressOptions.includes(data.name)) {
        scrimDistressRanges.push(obj);
      } else if (data.name !== "PCI" && data.name !== "Schemes") {
        //obj.id = surfaceStructureDistressIdMap[data.name];
        if(obj?.ranges.length){
          parameterDataRanges.push(obj);
        }
      } else if (
        !["Surface Defects", "Structure Defects", "Parameter Data"].includes(
          data.name
        )
      ) {
        updatedFilterData.push(obj);
      }
    });

    const surfaceDefectsIdx = findIndex(filterData, {
      name: "Surface Defects",
    });
    const structureDefectsIdx = findIndex(filterData, {
      name: "Structure Defects",
    });
    const scrimDefectsIdx = findIndex(filterData, {
      name: "SCRIM",
    });
    const parameterDefectsIdx = findIndex(filterData, {
      name: "Parameter Data",
    });

    if (surfaceDefectsIdx !== -1) {
      const updateObj = filterData[surfaceDefectsIdx];
      updateObj.ranges = [...updateObj.ranges, ...surfaceDistressRanges];

      updatedFilterData.push(updateObj);
    } else {
      const {
        globalMax,
        globalMin,
        allMaxValues: threshold,
      } = this.getFilterMaxMinVal(surfaceDistressRanges);

      updatedFilterData.push({
        globalMax,
        globalMin,
        threshold,
        name: "Surface Defects",
        ranges: [...surfaceDistressRanges],
      });
    }

    if (structureDefectsIdx !== -1) {
      const updateObj1 = filterData[structureDefectsIdx];
      updateObj1.ranges = [...updateObj1.ranges, ...structureDistressRanges];
      updatedFilterData.push(updateObj1);
    } else {
      const {
        globalMax,
        globalMin,
        allMaxValues: threshold,
      } = this.getFilterMaxMinVal(structureDistressRanges);

      updatedFilterData.push({
        globalMax,
        globalMin,
        threshold,
        name: "Structure Defects",
        ranges: [...structureDistressRanges],
      });
    }
    if (scrimDefectsIdx !== -1) {
      const updateObj1 = filterData[scrimDefectsIdx];
      updateObj1.ranges = [...updateObj1.ranges, ...scrimDistressRanges];
      updatedFilterData.push(updateObj1);
    } else {
      const {
        globalMax,
        globalMin,
        allMaxValues: threshold,
      } = this.getFilterMaxMinVal(scrimDistressRanges);

      updatedFilterData.push({
        globalMax,
        globalMin,
        threshold,
        name: "SCRIM",
        ranges: [...scrimDistressRanges],
      });
    }

    if (parameterDefectsIdx !== -1) {
      const updateObj2 = filterData[parameterDefectsIdx];
      updateObj2.ranges = [...updateObj2.ranges, ...parameterDataRanges];
      updatedFilterData.push(updateObj2);
    } else {
      const {
        globalMax,
        globalMin,
        allMaxValues: threshold,
      } = this.getFilterMaxMinVal(parameterDataRanges);

      updatedFilterData.push({
        globalMax,
        globalMin,
        threshold,
        name: "Parameter Data",
        ranges: [...parameterDataRanges],
      });
    }
    return updatedFilterData;
  };

  saveProjectFilter = () => {
    const { filterData, project } = this.state;
    const updatedFilterData = this.transformProjectFilterDataToSave(filterData);

    const data = {
      projectName: project,
      data: JSON.stringify(cloneDeep(updatedFilterData)),
    };

    axios
      .put(`${hostname}/postProjectFilter`, data)
      .then((response) => {
        this.saveProjectLayer();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  transformLayerData = (layerData, distressLayerData) => {
    const updatedLayerData = cloneDeep(layerData);
    const distressObjIdx = findIndex(updatedLayerData[0].children, {
      text: "Distress SHP",
    });
    if (distressObjIdx === -1 && distressLayerData !== null) {
      updatedLayerData[0].children.push(distressLayerData);
    }
    return updatedLayerData;
  };

  saveProjectLayer = () => {
    const { layerData, distressLayerData, project } = this.state;
    const updatedLayerData = this.transformLayerData(
      layerData,
      distressLayerData
    );

    const data = {
      projectName: project,
      data: JSON.stringify(updatedLayerData),
    };
    axios
      .put(`${hostname}/postProjectLayers`, data)
      .then((response) => {
        this.saveProjectLayerWFS();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  transformData = (config) => {
    const { prefix, suffix, mapping } = config;
    let updatedData = {};
    Object.entries(mapping).forEach(([key, value]) => {
      const updatedValues = [];
      value.forEach((value) => {
        if (value) {
          let val = value.replace(prefix, "");
          val = val.replace(suffix, "");
          updatedValues.push(`${prefix}${val}${suffix}`);
        }
      });
      updatedData = { ...updatedData, [key]: [...updatedValues] };
    });
    return updatedData;
  };

  saveProjectLayerWFS = () => {
    const { layerWFSConfig, project } = this.state;
    const data = {
      projectName: project,
      data: JSON.stringify(this.transformData(layerWFSConfig)),
    };

    axios
      .put(`${hostname}/postProjectLayerWFS`, data)
      .then((response) => {
        this.saveImportData();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  transformLevelData = (config) => {
    const { samplePrefix, prefix, suffix, mapping } = config;
    let updatedData = {};
    Object.entries(mapping).forEach(([key, value]) => {
      const updatedValues = [];
      value.forEach((value) => {
        if (value) {
          if (key.includes("Sample")) {
            let val = value.replace(samplePrefix, "");
            val = val.replace(suffix, "");
            updatedValues.push(`${samplePrefix}${val}${suffix}`);
          } else {
            let val = value.replace(prefix, "");
            val = val.replace(suffix, "");
            updatedValues.push(`${prefix}${val}${suffix}`);
          }
        }
      });
      updatedData = { ...updatedData, [key]: [...updatedValues] };
    });

    return updatedData;
  };

  saveProjectLevelLayer = () => {
    const { levelLayerConfig, project } = this.state;
    const data = {
      projectName: project,
      data: JSON.stringify(this.transformLevelData(levelLayerConfig)),
    };
    axios
      .put(`${hostname}/postProjectLevelLayer`, data)
      .then((response) => {
        this.saveProjectSchemaLayer();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  saveImportData = () => {
    const { importDataConfig, project } = this.state;
    const data = {
      projectName: project,
      data: JSON.stringify(this.transformData(importDataConfig)),
    };
    axios
      .put(`${hostname}/postProjectImportData`, data)
      .then((response) => {
        this.saveProjectLevelLayer();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  saveSCRIMData = () => {
    const { scrimDataConfig, project } = this.state;
    const data = {
      projectName: project,
      data: JSON.stringify(this.transformData(scrimDataConfig)),
    };
    axios
      .put(`${hostname}/postProjectSCRIMData`, data)
      .then((response) => {
        this.saveModelingData();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  saveModelingData = () => {
    const { modelingDataConfig, project } = this.state;
    const data = {
      projectName: project,
      data: JSON.stringify(this.transformData(modelingDataConfig)),
    };
    axios
      .put(`${hostname}/postProjectModelingData`, data)
      .then((response) => {
      })
      .catch((error) => {
        console.log(error);
      });
  };

  saveProjectSchemaLayer = () => {
    const { schemaLayerConfig, project } = this.state;
    const data = {
      projectName: project,
      data: JSON.stringify(this.transformData(schemaLayerConfig)),
    };

    axios
      .put(`${hostname}/postProjectSchemeLayer`, data)
      .then((response) => {
        this.saveParameterCSVData();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  saveParameterCSVData = () => {
    const { parameterCSVDataConfig, project } = this.state;
    const data = {
      projectName: project,
      data: JSON.stringify(this.transformData(parameterCSVDataConfig)),
    };
    axios
      .put(`${hostname}/postProjectParameterData`, data)
      .then((response) => {
        this.saveSCRIMData();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  setFilterData = (filterData) => {
    this.setState({
      filterData,
    });
  };

  setLayerData = (layerData) => {
    this.setState({
      layerData,
    });
  };

  setDistressLayerData = (distressLayerData) => {
    this.setState({
      distressLayerData,
    });
  };

  setLayerWFSData = (id, values) => {
    const { layerWFSConfig } = this.state;
    layerWFSConfig.mapping = { ...layerWFSConfig.mapping, [id]: values };
    this.setState({
      layerWFSConfig: { ...layerWFSConfig },
    });
  };

  setImportData = (id, values) => {
    const { importDataConfig } = this.state;
    importDataConfig.mapping = { ...importDataConfig.mapping, [id]: values };
    this.setState({
      importDataConfig: { ...importDataConfig },
    });
  };
  setSCRIMData = (id, values) => {
    const { scrimDataConfig } = this.state;
    scrimDataConfig.mapping = { ...scrimDataConfig.mapping, [id]: values };
    this.setState({
      scrimDataConfig: { ...scrimDataConfig },
    });
  };

  setModelingData = (id, values) => {
    const { modelingDataConfig } = this.state;
    modelingDataConfig.mapping = { ...modelingDataConfig.mapping, [id]: values };
    this.setState({
      modelingDataConfig: { ...modelingDataConfig },
    });
  };

  setParameterCSVData = (id, values) => {
    const { parameterCSVDataConfig } = this.state;
    parameterCSVDataConfig.mapping = {
      ...parameterCSVDataConfig.mapping,
      [id]: values,
    };
    this.setState({
      parameterCSVDataConfig: { ...parameterCSVDataConfig },
    });
  };

  setLevelLayerData = (id, values) => {
    const { levelLayerConfig } = this.state;
    levelLayerConfig.mapping = { ...levelLayerConfig.mapping, [id]: values };
    this.setState({
      levelLayerConfig: { ...levelLayerConfig },
    });
  };

  setSchemaLayerData = (id, values) => {
    const { schemaLayerConfig } = this.state;
    schemaLayerConfig.mapping = { ...schemaLayerConfig.mapping, [id]: values };
    this.setState({
      schemaLayerConfig: { ...schemaLayerConfig },
    });
  };

  setProjectFn = (e) => {
    const {
      levelLayerConfig,
      schemaLayerConfig,
      importDataConfig,
      scrimDataConfig,
      modelingDataConfig,
      parameterCSVDataConfig,
      layerWFSConfig,
    } = this.state;
    const projectName = e?.target.value;

    let workSpaceName = projectName;
    if(projectName === "Anglesey") workSpaceName = "Anglesey_1";

    this.setState(
      {
        project: projectName,
        levelLayerConfig: {
          ...levelLayerConfig,
          prefix: `data/${projectName}/Section/`,
          samplePrefix: `data/${projectName}/Sample/`,
        },
        schemaLayerConfig: {
          ...schemaLayerConfig,
          prefix: `data/${projectName}/Scheme-Lane/`,
        },
        importDataConfig: {
          ...importDataConfig,
          prefix: `data/${projectName}/ImportData/`,
        },
        scrimDataConfig: {
          ...scrimDataConfig,
          prefix: `data/${projectName}/SCRIMData/`,
        },
        modelingDataConfig: {
          ...modelingDataConfig,
          prefix: `data/${projectName}/ModelingData/`,
        },
        //ImportData needs to change after API is done
        parameterCSVDataConfig: {
          ...parameterCSVDataConfig,
          prefix: `data/${projectName}/ParameterData/`,
        },
        layerWFSConfig: {
          ...layerWFSConfig,
          prefix: `https://ptsviewer.com/geoserver/${workSpaceName}/wfs?service=WFS&version=2.0.0&request=GetFeature&typename=${projectName}:`,
        },
      },
      () => {
        this.fetchProjectFilter(projectName);
        this.fetchProjectLayer(projectName);
        this.fetchDropdownList(projectName);
      }
    );
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({
      openNotification: false,
    });
  };

  onSaveCompleteData = () => {
    this.setState({
      isSaving: true,
    });
    this.saveProjectFilter();

    this.setState({
      openNotification: true,
      isSaving: false,
    });
  };

  componentDidMount() {
    axios
      .get(`${hostname}/getProjects`)
      .then((response) => {
        if (response.status === 200) {
          const vData = [];
          response.data.forEach((val) => {
            vData.push(val.name);
          });
          this.setState({
            projectList: vData,
            buffering: false,
          });
        } else {
          console.log("error occurred");
        }
      })
      .catch((error) => {
        console.log(error);
      });
      // this.setProjectFn({target:{
      //   value:'NH-Area14'
      // }})
  }

  renderLoading = () => (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <ReactLoading color={"#00ACC1"} height="10%" width="10%" />
    </div>
  );

  render() {
    const {
      filterData,
      layerData,
      buffering,
      projectList,
      project,
      dropdownValues,
      layerWFSConfig,
      importDataConfig,
      scrimDataConfig,
      modelingDataConfig,
      levelLayerConfig,
      schemaLayerConfig,
      schemeDropdownValues,
      importDataDropdownValues,
      scrimDataDropdownValues,
      modelingDataDropdownValues,
      sectionLevelDropdownValues,
      sampleLevelDropdownValues,
      openNotification,
      isSaving,
      fetchingProjectLayer,
      fetchingImportData,
      fetchingSCRIMData,
      fetchingModelingData,
      fetchingProjectLevelLayer,
      fetchingProjectSchemaLayer,
      fetchingProjectLayerWFS,
      fetchingProjectFilter,
      fetchingDropdownList,
      fetchingCSVFiles,
      distressLayerData,
      fetchingParameterCSVData,
      parameterCSVDropdownValues,
      parameterCSVDataConfig,
    } = this.state;

    if (buffering) return this.renderLoading();

    return (
      <>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={openNotification}
          autoHideDuration={6000}
          onClose={this.handleClose}
          message="Project Saved !"
        ></Snackbar>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <TextField
                select
                label="Select Project"
                variant="outlined"
                id="mui-theme-provider-outlined-input"
                value={project}
                onChange={(e) => this.setProjectFn(e)}
              >
                {projectList.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Card>
          </GridItem>
          {project !== "" ? (
            <>
              <GridItem xs={12} sm={12} md={12}>
                <AssetNeedsRegister
                  config={parameterCSVDataConfig}
                  hostname={hostname}
                  projectName={project}
                  key="assetNeedsRegister"
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                {fetchingProjectFilter ? (
                  this.renderLoading()
                ) : (
                  <ProjectFilterDashboard
                    filterData={filterData}
                    setFilterData={this.setFilterData}
                    key="ProjectFilterDashboard"
                  />
                )}
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                {fetchingProjectLayer ? (
                  this.renderLoading()
                ) : (
                  <ProjectLayerDashboard
                    layerData={layerData}
                    setLayerData={this.setLayerData}
                    key="ProjectLayeredDashboard"
                  />
                )}
              </GridItem>

              <GridItem xs={12} sm={12} md={12}>
                {fetchingProjectLayer ? (
                  this.renderLoading()
                ) : (
                  <ProjectDistressDashboard
                    distressLayerData={distressLayerData}
                    layerData={layerData}
                    project={project}
                    dropdownValues={dropdownValues}
                    config={layerWFSConfig}
                    setDistressLayerData={this.setDistressLayerData}
                    key="ProjectDistressDashboard"
                  />
                )}
              </GridItem>

              <GridItem xs={12} sm={12} md={12}>
                {fetchingProjectLayer || fetchingProjectLayerWFS ? (
                  this.renderLoading()
                ) : (
                  <LayerWFSMapping
                    mappingData={layerData}
                    setMappingData={this.setLayerWFSData}
                    dropdownValues={dropdownValues}
                    config={layerWFSConfig}
                    key="layerData"
                  />
                )}
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                {fetchingProjectLayer || fetchingParameterCSVData ? (
                  this.renderLoading()
                ) : (
                  <ParameterCSVMapping
                    mappingData={layerData}
                    setMappingData={this.setParameterCSVData}
                    dropdownValues={parameterCSVDropdownValues}
                    config={parameterCSVDataConfig}
                    key="parameterCSV"
                  />
                )}
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                {fetchingProjectLayer || fetchingImportData ? (
                  this.renderLoading()
                ) : (
                  <ImportDataMapping
                    mappingData={layerData}
                    setMappingData={this.setImportData}
                    dropdownValues={importDataDropdownValues}
                    config={importDataConfig}
                    key="importData"
                  />
                )}
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                {fetchingProjectLayer || fetchingSCRIMData ? (
                  this.renderLoading()
                ) : (
                  <SCRIMDataMapping
                    mappingData={layerData}
                    setMappingData={this.setSCRIMData}
                    dropdownValues={scrimDataDropdownValues}
                    config={scrimDataConfig}
                    key="scrimData"
                  />
                )}
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                {fetchingProjectLayer || fetchingModelingData ? (
                  this.renderLoading()
                ) : (
                  <ModelingDataMapping
                    mappingData={layerData}
                    setMappingData={this.setModelingData}
                    dropdownValues={modelingDataDropdownValues}
                    config={modelingDataConfig}
                    key="modelingData"
                  />
                )}
              </GridItem>
              
              <GridItem xs={12} sm={12} md={12}>
                {fetchingProjectLayer || fetchingProjectLevelLayer ? (
                  this.renderLoading()
                ) : (
                  <SectionLevelLayerMapping
                    mappingData={layerData}
                    setMappingData={this.setLevelLayerData}
                    dropdownValues={sectionLevelDropdownValues}
                    config={levelLayerConfig}
                    key="sectionData"
                  />
                )}
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                {fetchingProjectLayer || fetchingProjectLevelLayer ? (
                  this.renderLoading()
                ) : (
                  <SampleLevelLayerMapping
                    mappingData={layerData}
                    setMappingData={this.setLevelLayerData}
                    dropdownValues={sampleLevelDropdownValues}
                    config={levelLayerConfig}
                    key="sampleData"
                  />
                )}
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                {fetchingProjectLayer || fetchingProjectSchemaLayer ? (
                  this.renderLoading()
                ) : (
                  <SchemaLayerMapping
                    mappingData={layerData}
                    setMappingData={this.setSchemaLayerData}
                    dropdownValues={schemeDropdownValues}
                    config={schemaLayerConfig}
                    key="schemeLayerData"
                  />
                )}
              </GridItem>

              <GridItem
                xs={12}
                sm={12}
                md={12}
                style={{
                  textAlign: "center",
                  position: "fixed",
                  width: "78%",
                  bottom: "0",
                  background: "white",
                  zIndex: "10",
                  boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                }}
              >
                <Button
                  variant="outlined"
                  style={{
                    textTransform: "capitalize",
                    background: "#49a751",
                    color: "white",
                    border: "none",
                    fontSize: "20px",
                    padding: "5px 25px",
                    marginTop: "20px",
                  }}
                  onClick={() => this.onSaveCompleteData()}
                >
                  Save{isSaving ? "ing..." : ""}
                </Button>
                <div style={{ margin: "10px" }}></div>
              </GridItem>
            </>
          ) : null}
        </GridContainer>
      </>
    );
  }
}

export default ProjectUploadComponent;
