import React, { Component } from 'react';
import VideoDashboard from "./VideoDashboard";
import ManageProject from "./ManageProject";
import axios from 'axios';
import ReactLoading from 'react-loading';
import SweetAlert from 'react-bootstrap-sweetalert';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
// import Tasks from "components/Tasks/Tasks.js";

class VideoDashboardComponent extends Component {

    state = {
        videoData : [],
        buffering: true,
        activeProject: "",
        allPersons: [],
        checkIndexes: [],
        allIndex: [],
        showProjectDeleteMessage: false,
        projectToDelete: null
    }

    componentDidMount(){
        axios.get("/getProjects")
        .then(response => {
            if(response.status === 200){
                const vData = [];
                response.data.forEach((val) => {
                    const arr = [];
                    arr.push(val.name);
                    arr.push("OK");
                    vData.push(arr);
                });
                this.setState({
                    videoData: vData,
                    buffering: false,
                    activeProject: "Select project to view details"
                });
            } else{
                console.log("error occured");
            }
        })
        .catch(error => {
            console.log(error);
        });
    }

    getIndexes = (upTo) => {
        const ret = [];
        for(let i=0;i<upTo;i+=1){
            ret.push(i);
        }
        return ret;
    }

    getPersonForProject = (pname) => {
        const data = {
            "username" : pname
        };
        axios.post("/getUserProject", data)
        .then(response => {
            const checkedPerson = response.data.projects;
            console.log(checkedPerson);
            const resUserData = [...checkedPerson];
            axios.get("/user/all")
            .then(response => {
                response.data.forEach(element => {
                    let addThis = false;
                    for(let i=0;i<checkedPerson.length;i+=1) {
                        if(element.uname === checkedPerson[i]){
                            addThis = true;
                        }
                    }
                    if(addThis === false)
                        resUserData.push(element.uname);
                });
                this.setState({
                    allPersons: [...resUserData],
                    allIndex: [...this.getIndexes(resUserData.length)],
                    checkIndexes: [...this.getIndexes(checkedPerson.length)]
                });
                
            }).catch(error => {
                console.log(error);
            })
        }).catch(error => {
            console.log(error);
        })
    }

    handleRowClick = (values) => {
        console.log(values);
        this.setState({
            activeProject: values[0]
        });
        this.getPersonForProject(values[0]);
    }

    handleChange = (val) => {
        const pname = this.state.activeProject;
        const prevCheck = [...this.state.checkIndexes];
        console.log(this.state.allPersons);
        for(let i=0;i<prevCheck.length;i+=1){
            if(val.includes(prevCheck[i])){
            } else {
                console.log("Deleting", prevCheck[i]);
                console.log(this.state.allPersons[prevCheck[i]]);
                const data = {
                    "userName": this.state.allPersons[prevCheck[i]],
                    "projectName": pname
                }
                axios.post("/deleteUserProject", data)
                .then(response => {
                    console.log(response);
                }).catch(error => {
                    console.log(error);
                })
            }
        }
        for(let i=val.length-1;i>=0;i-=1){
            if(prevCheck.includes(val[i])){
                break;
            } else {
                const data = {
                    "userName": this.state.allPersons[val[i]],
                    "projectName": pname
                }
                axios.post("/addUserProject", data)
                .then(response => {
                    console.log(response);
                }).catch(error => {
                    console.log("error");
                });
            }
        }
        this.setState({
            checkIndexes: val
        });
    }

    handleDeleteProject = (val) => {
        this.setState({
            showProjectDeleteMessage: true,
            projectToDelete: val
        });
        
    }

    confirmProjectDelete = () => {
        const markedForDel = this.state.projectToDelete;
        const data = {
            "projectName" : this.state.videoData[markedForDel][0]
        };
        //console.log(data);
        axios.post("/deleteProject", data)
        .then(response => {
            const uData = [...this.state.videoData];
            uData.splice(markedForDel, 1);
            this.setState({
                videoData: uData,
                projectToDelete: null,
                showProjectDeleteMessage: false
            });
        }).catch(error => {
            console.log(error);
        });
    }

    cancelProjectDelete = () => {
        this.setState({
            projectToDelete: null,
            showProjectDeleteMessage: false
        });
    }

    render(){
        let dashboard = <div style={{position:"relative", left:"50%"}}>
        <ReactLoading color={"#00ACC1"} height='10%' width='10%'/>
        </div>;

        let dashboard2 = <div style={{position:"relative", left:"50%"}}>
        <ReactLoading color={"#00ACC1"} height='10%' width='10%'/>
        </div>;

        let deleteProjectMessage = 
        <SweetAlert
            warning
            showCancel
            confirmBtnText="Yes, delete it!"
            title = "Are you sure you want to delete ?"
            onConfirm={this.confirmProjectDelete}
            onCancel={this.cancelProjectDelete}
            focusCancelBtn
        />;
        if(this.state.buffering === false){
            dashboard = <VideoDashboard
                videoData={this.state.videoData}
                handleRowClick={this.handleRowClick}
                handleDeleteRow={this.handleDeleteProject}
            />
            dashboard2 = <ManageProject
                allPersons={this.state.allPersons}
                activeProject={this.state.activeProject}
                checkedIndexes={this.state.checkIndexes}
                tasksIndexes={this.state.allIndex}
                handleChange={this.handleChange}
            />
        }

        return(
            <GridContainer>
                {this.state.showProjectDeleteMessage ? deleteProjectMessage : null}
                <GridItem xs={12} sm={12} md={6}>
                    {dashboard}
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                    {dashboard2} 
                </GridItem>
            </GridContainer>
        )
    }
}

export default VideoDashboardComponent;