import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { cloneDeep } from "lodash";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableSortLabel,
  TablePagination,
  TextField,
  MenuItem,
  Box,
  Paper,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";

const useStyles = makeStyles(styles);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function MappingTable(props) {
  const classes = useStyles();
  const { tableTitle, tableHead, tableData, tableHeaderColor } = props;
  const [page] = React.useState(0);
  const [rowsPerPage] = React.useState(150);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("District Name");
  const [stateTableData, setStateTableData] = React.useState(tableData);
  useEffect(() => {
    setStateTableData([...tableData]);
  }, [tableData]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const setStateTableDataFn = (val, index, field, id) => {
    stateTableData[index][field] = val;
    setStateTableData(cloneDeep(stateTableData));

    const mappedLane = [stateTableData[index]["mapValue"]];
    if (props.showRightDropdown) {
      mappedLane.push(stateTableData[index]["mapValue2"]);
    }
    props.setMappingData(id, mappedLane);
  };

  //console.log("Table Data " , stateTableData);

  return (
    <Card>
      <CardHeader color="success">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <span>
            <b>{tableTitle}</b>
          </span>
        </Box>
      </CardHeader>
      <CardBody>
        <Paper className={classes.paper}>
          <div className={classes.tableResponsive}>
            <Table className={classes.table} key={tableData}>
              <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
                <TableRow className={classes.tableHeadRow}>
                  {tableData.length ? (
                    tableHead.map((prop, key) => {
                      return (
                        <TableCell
                          className={
                            classes.tableCell + " " + classes.tableHeadCell
                          }
                          key={key}
                          sortDirection={orderBy === key ? order : false}
                        >
                          <TableSortLabel
                            active={orderBy === key}
                            direction={orderBy === key ? order : "asc"}
                            onClick={(event) => handleRequestSort(event, key)}
                          >
                            {prop}
                          </TableSortLabel>
                        </TableCell>
                      );
                    })
                  ) : (
                    <b>No Survey exist</b>
                  )}
                </TableRow>
              </TableHead>

              <TableBody>
                {stableSort(stateTableData, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((propx, idx) => {
                    const {
                      section,
                      name,
                      lane,
                      laneTxt,
                      mappingOptions: options,
                      mapValue,
                      mapValue2,
                      id,
                    } = propx;
                    return (
                      <TableRow key={idx} className={classes.tableBodyRow}>
                        {section ? (
                          <TableCell
                            className={classes.tableCell}
                            key={`${idx}-${section}${Math.random()}`}
                          >
                            {section}
                          </TableCell>
                        ) : null}

                        {name ? (
                          <TableCell
                            className={classes.tableCell}
                            key={`${idx}-${name}${Math.random()}`}
                          >
                            {name}
                          </TableCell>
                        ) : null}

                        {laneTxt ? (
                          <TableCell
                            className={classes.tableCell}
                            key={`${idx}-${lane}${Math.random()}`}
                          >
                            {laneTxt}
                          </TableCell>
                        ) : null}

                        <TableCell
                          className={classes.tableCell}
                          key={`${idx}-${options}${Math.random()}`}
                        >
                          {options.length ? (
                            <TextField
                              select
                              label=""
                              variant="outlined"
                              id="mui-theme-provider-outlined-input"
                              value={mapValue}
                              defaultValue=""
                              onChange={(e) =>
                                setStateTableDataFn(
                                  e.target.value,
                                  idx,
                                  "mapValue",
                                  lane
                                )
                              }
                              style={{ minWidth: "200px" }}
                              InputProps={{
                                className: classes.textField,
                              }}
                            >
                              {options.map((option) => (
                                <MenuItem key={option} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                            </TextField>
                          ) : null}
                        </TableCell>
                        {props.showRightDropdown ? (
                          <TableCell
                            className={classes.tableCell}
                            key={`${idx}-${options}${Math.random()}`}
                          >
                            {options.length ? (
                              <TextField
                                select
                                label=""
                                variant="outlined"
                                id="mui-theme-provider-outlined-input-2"
                                value={mapValue2}
                                defaultValue=""
                                onChange={(e) =>
                                  setStateTableDataFn(
                                    e.target.value,
                                    idx,
                                    "mapValue2",
                                    lane
                                  )
                                }
                                style={{ minWidth: "200px" }}
                                InputProps={{
                                  className: classes.textField,
                                }}
                              >
                                {options.map((option) => (
                                  <MenuItem key={option} value={option}>
                                    {option}
                                  </MenuItem>
                                ))}
                              </TextField>
                            ) : null}
                          </TableCell>
                        ) : null}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </div>
        </Paper>
      </CardBody>
    </Card>
  );
}

MappingTable.defaultProps = {
  tableHeaderColor: "gray",
};

MappingTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};
