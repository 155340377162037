import { find } from "lodash";
import axios from "axios";
import MappingTable from "./components/MappingTable";
import Switch from "@material-ui/core/Switch";
import { AddCircle, Edit, Delete } from "@material-ui/icons";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { cloneDeep } from "lodash";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableSortLabel,
  TablePagination,
  TextField,
  MenuItem,
  Box,
  Paper,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { green } from "@material-ui/core/colors";
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import AddFormFieldModal from "./components/AddFormFieldModal.js";
import ReactLoading from "react-loading";

const useStyles = makeStyles(styles);

const FilterSwitch = withStyles({
  switchBase: {
    color: green[300],
    "&$checked": {
      color: green[500],
    },
    "&$checked + $track": {
      backgroundColor: green[500],
    },
  },
  checked: {},
  track: {},
})(Switch);

export default function AssetNeedsRegister({hostname, config, projectName}) {
  const { prefix, suffix, mapping } = config;
  const classes = useStyles();

  const [formEnabled, setFormEnabled] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [fields, setFields] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [editField, setEditField] = useState('');

  useEffect(async() => {
    setIsLoading(true);
    await axios
      .get(`${hostname}/getAssetNeedsRegister?projectName=${projectName}`)
      .then((res) => {
        if(res.data.feilds){
          res.data['fields'] = res.data.feilds;
        }
        setFields(res.data.fields)
      })
      .catch((error) => {
        console.log(error);
      });
    setIsLoading(false);
  }, [projectName])

  const updateFields = (updated_fields=fields, disabled=(!formEnabled)) => {
    setIsLoading(true);
    axios
      .put(`${hostname}/saveAssetNeedsRegister`, {
        projectName: projectName, 
        data: {
          disabled: disabled,
          fields: updated_fields
        }
      })
      .then((res) => {
        if(res.data.feilds){
          res.data['fields'] = res.data.feilds;
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }
  
  const onSwitchChange = (e) => {
    setFormEnabled(e.target.checked);
    updateFields(undefined, !e.target.checked);
  }

  const onModalClose = () => {
    setModalOpen(false);
    setEditField('');
  }
 
  const openModal = () => {
    if(formEnabled){
      setModalOpen(true);
    }
  }

  const onEdit = (key) => {
    setModalOpen(true);
    setEditField(key);
  }

  const onDelete = (key) => {
    var fields_copy = fields;
    delete fields_copy[key]
    setFields({...fields_copy});
    updateFields(fields_copy);
  }

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card style={{position: 'relative'}}>
            <CardHeader color={'success'} style={!formEnabled ? {background: '#b3b3b3', boxShadow: 'none'} : {}}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box>
                  <b>Asset Needs Register</b>
                </Box>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Box onClick={openModal} style={!formEnabled ? {cursor: 'not-allowed'} : {cursor: 'pointer'}}>
                    <AddCircle
                      style={{
                        verticalAlign: "bottom",
                        marginRight: "10px",
                      }}
                    />
                    Add Field
                  </Box>
                  <Box>
                    <Switch
                      color='default'
                      checked={formEnabled}
                      style={{cursor: 'pointer'}}
                      onChange={onSwitchChange}
                    />
                  </Box>
                </Box>
              </Box>
            </CardHeader>
            <CardBody>
              {formEnabled ?
              <Paper className={classes.paper}>
                <div className={classes.tableResponsive}>
                  <Table className={classes.table}>
                    {/* <TableHead className={classes['' + "TableHeader"]}>
                      <TableRow className={classes.tableHeadRow}>
                        <TableCell>Label</TableCell>
                        <TableCell>Options</TableCell>
                      </TableRow>
                    </TableHead> */}

                    <TableBody>

                      {fields && Object.keys(fields).sort().map((field,i)=>(
                        <TableRow className={classes.tableBodyRow}>
                          <TableCell
                            className={classes.tableCell}
                          >
                            {field}
                          </TableCell>

                          <TableCell
                            className={classes.tableCell}
                          >
                            {fields[field]?.options?.length ?
                              <TextField
                                select
                                label=""
                                variant="outlined"
                                id="mui-theme-provider-outlined-input"
                                value={fields[field].options ? fields[field].options[0] : ""}
                                disabled={!fields[field].options}
                                SelectProps={{
                                  displayEmpty: true,
                                }}
                                style={{ minWidth: "200px" }}
                                InputProps={{
                                  className: classes.textField,
                                }}
                              >
                                {fields[field].options.map(option=>(
                                  <MenuItem key={option} value={option}>
                                    {option}
                                  </MenuItem>
                                ))}
                              </TextField>
                            :
                            <TextField
                              label=""
                              variant="outlined"
                              id="mui-theme-provider-outlined-input"
                              value={"Text Field"}
                              disabled
                              style={{ minWidth: "200px" }}
                              InputProps={{
                                className: classes.textField,
                              }}
                            >
                            </TextField>
                            
                            }
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                          >
                            {fields[field]?.options?.length ?
                              <Edit
                                onClick={()=>onEdit(field)}
                                style={{
                                  marginRight: "20px",
                                  color: "skyblue",
                                  cursor: 'pointer'
                                }}
                              />
                            :
                             <Edit 
                                style={{
                                  marginRight: "20px",
                                  color: "rgba(0,0,0,.1)",
                                  cursor: 'not-allowed'
                                }}
                              />
                            }
                            <Delete
                              onClick={()=>onDelete(field)}
                              style={{ marginRight: "20px", color: "red", cursor: 'pointer' }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}

                    </TableBody>
                  </Table>
                </div>
              </Paper>
              :
                <Box style={{textAlign: 'center', fontSize: '1.3rem', padding: '0px', color: 'rgba(0,0,0,.5)'}}>
                  Disabled
                </Box>
              }
            </CardBody>

            {isLoading && 
              <div className="bgLoader" style={{ display: "flex", justifyContent: "center" }}>
                <ReactLoading color={"#00ACC1"} height="10%" width="10%" />
              </div>
            }
          </Card>
  
        </GridItem>
      </GridContainer>

      <AddFormFieldModal
        visibility={modalOpen}
        handleClose={onModalClose}
        editField={editField}
        fields={fields}
        setFields={setFields}
        updateFields={updateFields}
      />
    </>
  );
}
