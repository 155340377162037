export const worksProgramFields = {
  "Need ID": {
    "type": "text"
  },
  "Area": {
    "type": "select",
  },
  "Route": {
    "type": "select",
    "dependsOn": "Area",
  },
  "Need Name": {
    "type": "text"
  },
  "Maintenance Section Location": {
    "type": "select",
  },
  "Delivery Plan": {
    "type": "select",
  },
  "Sub Delivery Plan": {
    "type": "select",
    "dependsOn": "Delivery Plan"
  },
  "Predominant Intervention": {
    "type": "select",
  },
  "Risk": {
    "type": "text"
  },
  "Primary Risk Category": {
    "type": "select",
  },
  "Impact of Risk": {
    "type": "select",
  },
  "Likelihood of Risk Occuring": {
    "type": "select",
  },
  "Risk Score": {
    "type": "text"
  },
  "Proposed Year of Need": {
    "type": "select",
  },
  "Raised By": {
    "type": "text"
  },
  "PIN": {
    "type": "text"
  },
  "Scheme Name": {
    "type": "text"
  },
  "Status": {
    "type": "select",
  }
};


