import React, { useState } from "react";
import { map, cloneDeep } from "lodash";

import { makeStyles } from "@material-ui/core/styles";
import { Button, Chip, Paper, Box } from "@material-ui/core";
import { AddCircle, Edit, Delete } from "@material-ui/icons";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import ConfirmModal from "./components/ConfirmModal";
import AddSurveyModal from "./components/AddSurveyModal";

const styles = {
  surveyCard: {
    display: "flex",
    justifyContent: "left",
    flexWrap: "wrap",
    listStyle: "none",
    alignItems: "center",
    padding: "5px 10px",
    marginBottom: "10px",
    border: "1px solid whitesmoke",
  },
  surveyInfoCard: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "80%",
  },
  surveyActionCard: {
    display: "flex",
    justifyContent: "flex-end",
    width: "20%",
  },
  root: {
    display: "flex",
    justifyContent: "left",
    flexWrap: "wrap",
    listStyle: "none",
    flexDirection: "row",
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  gap: {
    marginTop: "60px",
  },
  cardTitleWhite: {
    cursor: "pointer",
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  chip: {
    margin: "8px",
  },
  text: {
    fontWeight: "bolder",
    marginRight: "20px",
  },
};
const useStyles = makeStyles(styles);

export default function LayerDashboard({ layerData, setLayerData }) {
  const classes = useStyles();
  const [addSurveyModalOpen, setAddSurveyModalOpen] = useState(false);
  const [deleteSurveyModalOpen, setDeleteSurveyModalOpen] = useState(false);
  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [selectedParentIdx, setSelectedParentIdx] = useState(-1);
  const [selectedChildIdx, setSelectedChildIdx] = useState(-1);
  const [selectedDeepestIdx, setSelectedDeepestIdx] = useState(-1);

  const checkForDuplicate = (surveyName, editMode) => {
    const allOccurrence = map(
      layerData[selectedParentIdx].children[selectedChildIdx].children,
      "text"
    );
    if (allOccurrence.includes(surveyName)) {
      if (editMode) {
        allOccurrence.splice(allOccurrence.indexOf(surveyName), 1);
      }
      if (allOccurrence.includes(surveyName)) return true;
    }

    return false;
  };

  const missingIndex = (a, n) => {
    let total = ((n + 1) * (n + 2)) / 2;
    for (let i = 0; i < n; i++) {
      let laneNumber = a[i].replace(/[^0-9.]/g, "");
      total -= parseInt(laneNumber);
    }
    if (total === 0) total = 1;
    return total;
  };

  const handleChipDelete = (idx, parentIdx, childIdx, deepestIdx) => {
    layerData[idx].children[parentIdx].children[childIdx].children.splice(
      deepestIdx,
      1
    );
    const updatedData = cloneDeep(layerData);
    setLayerData(updatedData);
  };

  const handleChipAdd = (idx, parentIdx, childIdx, lanesArr) => {
    const lanesStringArr = map(lanesArr, "text");
    const LaneText = `Lane_${missingIndex(
      lanesStringArr,
      lanesStringArr.length
    )}`;
    layerData[idx].children[parentIdx].children[childIdx].children.push({
      icon: "./assets/map.png",
      id: `${layerData[idx].text}_${layerData[idx].children[parentIdx].text}_${layerData[idx].children[parentIdx].children[childIdx].text}_${LaneText}`.replace(
        " ",
        "_"
      ),
      state: { selected: false },
      text: LaneText.replace("_", " "),
    });
    const updatedData = cloneDeep(layerData);
    setLayerData(updatedData);
  };

  const handleSurveyAddRow = (parentIdx, childIdx = -1) => {
    setSelectedParentIdx(parentIdx);
    setSelectedChildIdx(childIdx);
    setSelectedSurvey(null);
    setAddSurveyModalOpen(true);
  };

  const onAddSurveyFn = (data) => {
    const { surveyName, lanes } = data;
    const lanesObjectArr = [];

    if (lanes && lanes.length) {
      lanes.forEach((lane) => {
        lanesObjectArr.push({
          icon: "./assets/map.png",
          id: `${layerData[selectedParentIdx].text}_${layerData[selectedParentIdx].children[selectedChildIdx].text}_${surveyName}_${lane}`.replaceAll(
            " ",
            "_"
          ),
          state: { selected: false },
          text: lane,
        });
      });
    }

    const inputObject = {
      icon: "./assets/map.png",
      id: `${layerData[selectedParentIdx].children[selectedChildIdx].text}_${surveyName}`.replaceAll(
        " ",
        "_"
      ),
      state: { selected: false },
      text: surveyName,
      children: [...lanesObjectArr],
    };

    layerData[selectedParentIdx].children[selectedChildIdx].children.push(
      inputObject
    );

    setLayerData(layerData);
  };

  const handleSurveyEditRow = (parentIdx, childIdx = -1, deepestIdx = -1) => {
    setSelectedParentIdx(parentIdx);
    setSelectedChildIdx(childIdx);
    setSelectedDeepestIdx(deepestIdx);
    const { children, text } = layerData[parentIdx].children[childIdx].children[
      deepestIdx
    ];

    setSelectedSurvey({ surveyName: text, lanes: map(children, "text") });
    setAddSurveyModalOpen(true);
  };

  const onEditSurveyFn = (data) => {
    const { surveyName, lanes } = data;
    const lanesObjectArr = [];

    if (lanes.length) {
      lanes.forEach((lane) => {
        lanesObjectArr.push({
          icon: "./assets/map.png",
          id: `${layerData[selectedParentIdx].text}_${layerData[selectedParentIdx].children[selectedChildIdx].text}_${surveyName}_${lane}`.replace(
            " ",
            "_"
          ),
          state: { selected: false },
          text: lane,
        });
      });
    }

    const inputObject = {
      icon: "./assets/map.png",
      id: `${layerData[selectedParentIdx].children[selectedChildIdx].text}_${surveyName}`.replace(
        " ",
        "_"
      ),
      state: { selected: false },
      text: surveyName,
      children: [...lanesObjectArr],
    };

    layerData[selectedParentIdx].children[selectedChildIdx].children[
      selectedDeepestIdx
    ] = inputObject;

    setLayerData(layerData);
  };

  const handleSurveyDeleteRow = (parentIdx, childIdx = -1, deepestIdx) => {
    setDeleteSurveyModalOpen(true);
    setSelectedParentIdx(parentIdx);
    setSelectedDeepestIdx(deepestIdx);
    setSelectedChildIdx(childIdx);
  };

  const onDeleteSurveyFn = () => {
    layerData[selectedParentIdx].children[selectedChildIdx].children.splice(
      selectedDeepestIdx,
      1
    );

    setDeleteSurveyModalOpen(false);
    setLayerData(layerData);
  };

  const renderDeleteSurveyModal = () => {
    return deleteSurveyModalOpen ? (
      <ConfirmModal
        titleText="Are you sure you want to delete this Survey ? "
        open={deleteSurveyModalOpen}
        onDelete={() => onDeleteSurveyFn()}
        onClose={() => setDeleteSurveyModalOpen(false)}
      />
    ) : null;
  };

  const renderAddFilterModal = () => {
    return addSurveyModalOpen ? (
      <AddSurveyModal
        visibility={addSurveyModalOpen}
        handleClose={() => setAddSurveyModalOpen(false)}
        onSave={(data) =>
          selectedSurvey === null ? onAddSurveyFn(data) : onEditSurveyFn(data)
        }
        selectedSurvey={selectedSurvey}
        checkForDuplicate={checkForDuplicate}
      />
    ) : null;
  };

  if (!layerData) return null;

  return (
    <GridContainer>
      {renderAddFilterModal()}
      {renderDeleteSurveyModal()}
      {layerData?.map((ParentLayerData, idx) => {
        return (
          <GridItem xs={12} sm={12} md={12} key={idx}>
            {ParentLayerData?.children?.map((childLayerData, parentIdx) => {
              return (
                <div key={`${childLayerData.key}-${parentIdx}`}>
                  <Card>
                    <CardHeader color="success">
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box>
                          <span>
                            <b>{childLayerData.text} Settings</b>
                          </span>
                        </Box>
                        <Box>
                          <span
                            onClick={() => handleSurveyAddRow(idx, parentIdx)}
                          >
                            <AddCircle
                              style={{
                                verticalAlign: "bottom",
                                marginRight: "10px",
                              }}
                            />
                            Add Survey
                          </span>
                        </Box>
                      </Box>
                    </CardHeader>
                    <CardBody>
                      <h5>
                        {childLayerData.children.length ? "" : "No "}
                        {childLayerData.text}
                        {childLayerData.children.length ? "" : " exist."}
                      </h5>
                      {childLayerData?.children?.map(
                        (sectionLevelData, childIdx) => {
                          return (
                            <Paper
                              component="div"
                              className={classes.surveyCard}
                              key={`${sectionLevelData.key} ${childIdx}`}
                            >
                              <div className={classes.surveyInfoCard}>
                                <div className={classes.text}>
                                  {sectionLevelData.text}
                                </div>
                                {sectionLevelData?.children?.map(
                                  (data, deepestIdx) => {
                                    return (
                                      <li
                                        key={`${
                                          data.key
                                        }-${deepestIdx}-${Math.random()}`}
                                      >
                                        <Chip
                                          label={data.text}
                                          clickable
                                          className={classes.chip}
                                          onDelete={() =>
                                            handleChipDelete(
                                              idx,
                                              parentIdx,
                                              childIdx,
                                              deepestIdx
                                            )
                                          }
                                          color="primary"
                                        />
                                      </li>
                                    );
                                  }
                                )}
                                <Button
                                  variant="text"
                                  size="small"
                                  color={
                                    sectionLevelData.children?.length < 2
                                      ? "secondary"
                                      : "primary"
                                  }
                                  startIcon={<AddCircle />}
                                  // here
                                  onClick={
                                    () =>
                                      handleSurveyEditRow(
                                        idx,
                                        parentIdx,
                                        childIdx
                                      )
                                    // handleChipAdd(
                                    //   idx,
                                    //   parentIdx,
                                    //   childIdx,
                                    //   sectionLevelData.children
                                    // )
                                  }
                                  disabled={
                                    sectionLevelData.children?.length > 4
                                  }
                                >
                                  Add Lane
                                </Button>
                              </div>
                              <div className={classes.surveyActionCard}>
                                <Edit
                                  style={{
                                    marginRight: "20px",
                                    color: "skyblue",
                                  }}
                                  onClick={() =>
                                    handleSurveyEditRow(
                                      idx,
                                      parentIdx,
                                      childIdx
                                    )
                                  }
                                />
                                <Delete
                                  style={{ marginRight: "20px", color: "red" }}
                                  onClick={() =>
                                    handleSurveyDeleteRow(
                                      idx,
                                      parentIdx,
                                      childIdx
                                    )
                                  }
                                />
                              </div>
                            </Paper>
                          );
                        }
                      )}
                    </CardBody>
                  </Card>
                  <div className={classes.gap}></div>
                </div>
              );
            })}
          </GridItem>
        );
      })}
    </GridContainer>
  );
}
