import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/";
import { CloseRounded } from "@material-ui/icons";
import ColorPicker from "material-ui-color-picker";

import {
  Button,
  TextField,
  MenuItem,
  Fade,
  Backdrop,
  Modal,
} from "@material-ui/core";

const FilterNameOptions = [
  {
    value: "PCI",
    label: "PCI",
  },
  {
    value: "Structure Defects",
    label: "Structure Defects",
  },
  {
    value: "Surface Defects",
    label: "Surface Defects",
  },
  {
    value: "Schemes",
    label: "Schemes",
  },
  {
    value: "Parameter Data",
    label: "Parameter Data",
  },
  {
    value: "SCRIM",
    label: "SCRIM",
  },
];

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontWeight: "bold",
    fontSize: "20px",
    borderBottom: "1px solid #dcdcdc73",
    paddingBottom: "5px",
  },
  modalContent: {
    display: "grid",
  },
  field: {
    marginTop: "10px",
  },
  errorText: {
    color: "red",
    margin: "0 5px",
  },
  minMaxErrorErrText: {
    color: "red",
    margin: "0 5px",
    fontSize: "14px",
  },
  errorBorder: {
    border: "1px solid red",
    borderRadius: "5px",
  },
  saveButtonContainer: {
    textAlign: "center",
    marginTop: "15px",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3),
    borderRadius: "5px",
    width: "35%",
  },
  disabledField: {
    background: "whitesmoke",
    cursor: "not-allowed",
    opacity: "0.1",
  },
}));

export default function AddFilterModal({
  visibility,
  handleClose,
  onSave,
  selectedProject,
  editIdx,
}) {
  const classes = useStyles();
  const [data, setData] = useState({ ...selectedProject });
  const [error, setError] = useState(false);
  const [minMaxError, setMinMaxError] = useState(false);
  const [showClrMsg, updateClrMsg] = useState(false);
  const surfaceDistressOptions = [
    "Bleeding",
    "L&T Cracking",
    "Potholes",
    "Beeding",
    "Edge cracking",
    "Longitudinal and Transverse cracking",
    "Polished aggregate",
    "Ravelling",
    "Weathering",
  ];
  const structureDistressOptions = [
    "Bumps and Sags",
    "Rutting",
    "Swell",
    "Depressions",
    "Wheel track cracking",
    "Block cracking",
    "Bumps",
    "Corrugation",
    "Depressions",
    "Patching and utility cuts",
    "Rutting",
    "Shoving",
    "Swelling",
  ];
  const scrimSubfilters =[
    "AVG_CSC",
  "IL",
  "AVG_SCRIM",
  "NO_ACCIDENT",
  "RANK",
  "SITE_RATING"
  ]
  const hexToRgb = (hex) =>
    hex
      .replace(
        /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
        (m, r, g, b) => "#" + r + r + g + g + b + b
      )
      .substring(1)
      .match(/.{2}/g)
      .map((x) => parseInt(x, 16));

  const rgbToHex = (r, g, b) =>
    "#" + [r, g, b].map((x) => x.toString(16).padStart(2, "0")).join("");

  const onAddBtnClick = () => {
    let { filterName, subFilter, levelName,colorType, color, minColor, maxColor, width, min, max } = data;
    if (
      !filterName ||
      !levelName ||
      (!color && !minColor && !maxColor) ||
      !width ||
      !colorType ||
      min === "" ||
      max === ""
    ) {
      setError(true);
    } else if (min !== "" && min < -100000) {
      setMinMaxError("Minimum value can't be smaller than -100000");
    } else if (max !== "" && max > 100000) {
      setMinMaxError("Maximum value can't be greater than 100000");
    } else {
      setError(false);
      setData({});
      handleClose();
      if (subFilter) {
        data.filterName = subFilter;
        delete data.subFilter;
      }
      onSave(data, editIdx);
    }
  };

  let currentCode = data?.color ? `${data?.color}` : undefined;
  let currentCodeMin = data?.minColor ? `${data?.minColor}` : undefined;
  let currentCodeMax = data?.maxColor ? `${data?.maxColor}` : undefined;
  let isStructureOrSurfaceDefects = [
    "Structure Defects",
    "Surface Defects",
  ].includes(data?.filterName);

  let isParameterData = ["Parameter Data"].includes(data?.filterName);
  let isScrimData = ["SCRIM"].includes(data?.filterName);
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={visibility}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={visibility}>
          <div className={classes.paper}>
            <div className={classes.modalHeader}>
              <span>
                {editIdx === -1 ? "Add Filter" : "Edit Filter"}
                {showClrMsg ? (
                  <span className="hideMe">
                    &nbsp; Color value will be update only after closing the
                    color picker
                  </span>
                ) : null}
                {error && (
                  <span className={classes.errorText}>
                    {" "}
                    All fields are required
                  </span>
                )}
                {minMaxError && (
                  <span className={classes.minMaxErrorErrText}>
                    {" "}
                    {minMaxError}
                  </span>
                )}
              </span>
              <span onClick={() => handleClose()}>
                <CloseRounded />
              </span>
            </div>
            <div className={classes.modalContent}>
              <TextField
                select
                className={`${classes.field} ${
                  error && !data?.filterName && classes.errorBorder
                }`}
                label="Filter Name"
                variant="outlined"
                id="mui-theme-provider-outlined-input"
                value={data?.filterName}
                onChange={(e) =>
                  setData({
                    ...data,
                    filterName: e.target.value,
                    levelName: "",
                  })
                }
              >
                {FilterNameOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              {isStructureOrSurfaceDefects ? (
                <TextField
                  select
                  className={`${classes.field} ${
                    error && !data?.subFilter && classes.errorBorder
                  }`}
                  label="Sub-filter Name"
                  variant="outlined"
                  id="mui-theme-provider-outlined-input"
                  value={data?.subFilter}
                  onChange={(e) =>
                    setData({ ...data, subFilter: e.target.value })
                  }
                >
                  {data?.filterName === "Surface Defects"
                    ? surfaceDistressOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))
                    : structureDistressOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                </TextField>
              ) : null}

              {isParameterData ? (
                <TextField
                  className={`${classes.field} ${
                    error && !data?.subFilter && classes.errorBorder
                  }`}
                  label="Sub-filter Name"
                  variant="outlined"
                  id="mui-theme-provider-outlined-input"
                  value={data?.subFilter}
                  onChange={(e) =>
                    setData({ ...data, subFilter: e.target.value })
                  }
                >
                </TextField>
              ) : null}
              {isScrimData ? (
                <TextField
                  select
                  className={`${classes.field} ${
                    error && !data?.subFilter && classes.errorBorder
                  }`}
                  label="Sub-filter Name"
                  variant="outlined"
                  id="mui-theme-provider-outlined-input"
                  value={data?.subFilter}
                  onChange={(e) =>
                    setData({ ...data, subFilter: e.target.value })
                  }
                >
                  {scrimSubfilters.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                </TextField>
              ) : null}

              <TextField
                className={`${classes.field} ${
                  error && !data?.levelName && classes.errorBorder
                }`}
                label="Level Name"
                variant="outlined"
                id="mui-theme-provider-outlined-input"
                value={data?.levelName}
                onChange={(e) =>
                  setData({ ...data, levelName: e.target.value })
                }
              />

              <TextField
                select
                className={`${classes.field} ${error && !data?.subFilter && classes.errorBorder
                  }`}
                label="Color Type"
                variant="outlined"
                id="mui-theme-provider-outlined-input"
                value={data?.colorType}
                onChange={(e) =>
                  setData({ ...data, colorType: e.target.value })
                }
              >
                {['gradient', 'fill'].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
              {
                data.colorType == 'gradient' ? (
                  <>
                    <ColorPicker
                      className={`${classes.field} ${error && !data?.color && classes.errorBorder
                        }`}
                      autoComplete="off"
                      label="Min Color"
                      variant="outlined"
                      name="color"
                      defaultValue={currentCodeMin}
                      key={data?.minColor}
                      onChange={(color) => {
                        if (!color) {
                          setData({ ...data, minColor: currentCodeMin });
                        } else {
                          if (!showClrMsg) {
                            updateClrMsg(true);
                          }
                          currentCodeMin = color;
                        }
                      }}
                      // id="mui-theme-provider-outlined"
                    /><ColorPicker
                      className={`${classes.field} ${error && !data?.color && classes.errorBorder
                        }`}
                      autoComplete="off"
                      label="Max Color"
                      variant="outlined"
                      name="color"
                      defaultValue={currentCodeMax}
                      key={data?.maxColor}
                      onChange={(color) => {
                        if (!color) {
                          setData({ ...data, maxColor: currentCodeMax });
                        } else {
                          if (!showClrMsg) {
                            updateClrMsg(true);
                          }
                          currentCodeMax = color;
                        }
                      }}
                      // id="mui-theme-provider-outlined"
                    /></>
                ) : <ColorPicker
                  className={`${classes.field} ${error && !data?.color && classes.errorBorder
                    }`}
                  autoComplete="off"
                  label="Color"
                  variant="outlined"
                  name="color"
                  defaultValue={currentCode}
                  key={data?.color}
                  onChange={(color) => {
                    if (!color) {
                      setData({ ...data, color: currentCode });
                    } else {
                      if (!showClrMsg) {
                        updateClrMsg(true);
                      }
                      currentCode = color;
                    }
                  }}
                  id="mui-theme-provider-outlined"
                />
              }

              <TextField
                className={`${classes.field} ${
                  error && !data?.width && classes.errorBorder
                }`}
                label="Width"
                variant="outlined"
                id="mui-theme-provider-outlined-input"
                type="number"
                value={data?.width}
                onChange={(e) => setData({ ...data, width: e.target.value })}
              />
              <TextField
                className={`${classes.field} ${
                  error && !data?.min && classes.errorBorder
                }`}
                label="Minimum value"
                variant="outlined"
                id="mui-theme-provider-outlined-input"
                type="number"
                value={data?.min}
                onChange={(e) => setData({ ...data, min: e.target.value })}
                InputProps={{ inputProps: { min: 0, max: 10 } }}
              />
              <TextField
                className={`${classes.field} ${
                  error && !data?.max && classes.errorBorder
                }`}
                label="Maximum value"
                variant="outlined"
                id="mui-theme-provider-outlined-input"
                type="number"
                value={data?.max}
                onChange={(e) => setData({ ...data, max: e.target.value })}
                InputProps={{ inputProps: { min: 0, max: 10 } }}
              />
            </div>
            <div className={classes.saveButtonContainer}>
              <Button variant="contained" onClick={() => onAddBtnClick()}>
                {editIdx === -1 ? "Save" : "Update"}{" "}
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
