import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import axios from "axios";
import cookie from "react-cookies";
import ProjectUploadComponent from "views/ProjectSetup/ProjectUploadComponent.js";

// core components
import AdminLogin from "views/AdminLogin/AdminLogin.js";

import "assets/css/material-dashboard-react.css?v=1.9.0";
import UserLogin from "views/UserLogin/UserLogin";

const hist = createBrowserHistory();

axios.defaults.baseURL = "https://ptsviewer.com:10090";

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/project-upload" exact component={ProjectUploadComponent} />
      <Route path="/login-user" exact component={UserLogin} />
      <Route
        path="/success"
        exact
        component={() => {
          const uName = cookie.load("VideoAppUserId");
          // const pName = cookie.load("ProjectName");
          window.location.href = "https://ptsviewer.co.uk:5010?userId=" + uName;
          return null;
        }}
      />
      <Route path="/admin" component={AdminLogin} />
      <Redirect from="/" to="/login-user" />
    </Switch>
  </Router>,
  document.getElementById("root")
);
