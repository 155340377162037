import React, { useState } from "react";
import { find, findIndex, cloneDeep, map } from "lodash";
// @material-ui/core components
import { AddCircle } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import ConfirmModal from "./components/ConfirmModal";
import AddFilterModal from "./components/AddFilterModal";
import FilterTable from "./components/FilterTable";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    cursor: "pointer",
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export default function FilterDashboard({ filterData, setFilterData }) {
  const classes = useStyles();

  const surfaceStructureDistressIdMap = {
    Bleeding: 2,
    "L&T Cracking": 10,
    Ravelling: 19,
    Potholes: 13,
    "Bumps and Sags": 4,
    Rutting: 15,
    Swell: 18,
    Depressions: 6,
  };

  const [addFilterModalOpen, setAddFilterModalOpen] = useState(false);
  const [deleteFilterModalOpen, setDeleteFilterModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedIdx, setSelectedIdx] = useState(-1);
  const surfaceDistressOptions = [
    "Bleeding",
    "L&T Cracking",
    "Potholes",
    "Beeding",
    "Edge cracking",
    "Longitudinal and Transverse cracking",
    "Polished aggregate",
    "Ravelling",
    "Weathering",
  ];
  const structureDistressOptions = [
    "Bumps and Sags",
    "Rutting",
    "Swell",
    "Depressions",
    "Wheel track cracking",
    "Block cracking",
    "Bumps",
    "Corrugation",
    "Depressions",
    "Patching and utility cuts",
    "Rutting",
    "Shoving",
    "Swelling",
  ];
  const scrimSubfilters =[
    "AVG_CSC",
  "IL",
  "AVG_SCRIM",
  "NO_ACCIDENT",
  "RANK",
  "SITE_RATING"
  ]

  if (!filterData.length) return null;

  const renderAddFilterModal = () => {
    let inputProjectData = { ...selectedProject };

    if (selectedIdx !== -1) {
      let filterName = "";
      const subFilter = selectedProject?.filterName;
      if (
        selectedProject.filterName !== "PCI" &&
        selectedProject.filterName !== "Schemes"
      ) {
        filterName = "Parameter Data";
      }
      if (surfaceDistressOptions.includes(selectedProject.filterName)) {
        filterName = "Surface Defects";
      }
      if (structureDistressOptions.includes(selectedProject.filterName)) {
        filterName = "Structure Defects";
      }
      if(scrimSubfilters.includes(selectedProject.filterName)) {
        filterName ="SCRIM";
      }
      if (filterName !== "") {
        inputProjectData.filterName = filterName;
        inputProjectData.subFilter = subFilter;
      }
    }

    return addFilterModalOpen ? (
      <AddFilterModal
        visibility={addFilterModalOpen}
        handleClose={() => setAddFilterModalOpen(false)}
        onSave={(data, idx) => onAddFilterSaveFn(data, idx)}
        selectedProject={inputProjectData}
        editIdx={selectedIdx}
      />
    ) : null;
  };

  const renderDeleteFilterModal = () => {
    return deleteFilterModalOpen ? (
      <ConfirmModal
        open={deleteFilterModalOpen}
        titleText="Are you sure you want to delete this filter ? "
        onDelete={() => onDeleteProject()}
        onClose={() => setDeleteFilterModalOpen(false)}
      />
    ) : null;
  };

  const convertFilterData = (data) => {
    var rows = [];
    for (let i = 0; i < data.length; i += 1) {
      for (let j = 0; j < data[i].ranges.length; j += 1) {
        var row = [];
        row.push(data[i].name);
        row.push(data[i].ranges[j].name);
        row.push(data[i].ranges[j].color);
        row.push(data[i].ranges[j].width);
        row.push(data[i].ranges[j].min);
        row.push(data[i].ranges[j].max);
        row.push(data[i].ranges[j].id);
        row.push(data[i].ranges[j].active);
        rows.push(row);
      }
    }
    return rows;
  };

  const onAddFilterSaveFn = (data, editIdx = -1) => {
    const { filterName, levelName, color,minColor, maxColor, width, min, max, id } = data;
    const inputObject = {
      name: levelName,
      color,
      minColor,
      maxColor,
      width,
      min,
      max,
      active: true,
    };

    const selectedDataIdx = findIndex(filterData, { name: filterName });
    let newFilterIdx = `${filterName}_${getFilterRangeId(
      filterName,
      0
    )}${Math.random()}`;

    if (selectedDataIdx !== -1) {
      newFilterIdx = getFilterRangeId(
        filterName,
        `${filterName}_${
          filterData[selectedDataIdx].ranges.length
        }${Math.random()}`
      );
    }

    if ([...surfaceDistressOptions, ...structureDistressOptions].includes(filterName)) {
      newFilterIdx = `${
        surfaceStructureDistressIdMap[filterName]
      }_${Math.random()}`;
    }

    if (selectedDataIdx !== -1) {
      const filterDataIdx = findIndex(filterData[selectedDataIdx].ranges, {
        id: data.id,
      });

      if (filterDataIdx !== -1) {
        filterData[selectedDataIdx].ranges[filterDataIdx] = {
          ...selectedProject,
          ...inputObject,
        };
      } else {
        filterData[selectedDataIdx].ranges.unshift({
          ...inputObject,
          id: newFilterIdx,
        });
      }
    } else {
      filterData.unshift({
        name: filterName,
        ranges: [
          {
            ...inputObject,
            id: newFilterIdx,
          },
        ],
      });
    }

    setFilterData(filterData);
  };

  const handleDeleteProject = (idx, data) => {
    setDeleteFilterModalOpen(true);
    setSelectedIdx(idx);
    setSelectedProject(data);
  };

  const onDeleteProject = () => {
    const [name, , , , , , id] = selectedProject;
    const selectedDataIdx = findIndex(filterData, { name });
    const subIndex = findIndex(filterData[selectedDataIdx].ranges, { id });
    filterData[selectedDataIdx].ranges.splice(subIndex, 1);
    setDeleteFilterModalOpen(false);
    setFilterData(filterData);
  };

  const handleEditProject = (index, data) => {
    const [filterName, levelName, color, width, min, max, id] = data;
    setAddFilterModalOpen(true);
    setSelectedIdx(index);
    setSelectedProject({
      filterName,
      levelName,
      color,
      width,
      min,
      max,
      id,
    });
  };

  const handleUpdateActive = (index, data, value) => {
    const [name, , , , , , id] = data;
    const selectedDataIdx = findIndex(filterData, { name });
    const subIndex = findIndex(filterData[selectedDataIdx].ranges, {
      id,
    });
    filterData[selectedDataIdx].ranges[subIndex].active = value;
    setFilterData(cloneDeep(filterData));
  };

  const handleAddRow = (val) => {
    setSelectedProject(null);
    setAddFilterModalOpen(true);
    setSelectedIdx(-1);
  };

  const getFilterRangeId = (name, length) => {
    const filterMap = {
      PCI: "range-pci",
    };
    return `${filterMap[name] || ""}-${length}` || "";
  };

  return (
    <>
      {renderAddFilterModal()}
      {renderDeleteFilterModal()}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <span>
                    <b>Project Filter Settings</b>
                  </span>
                </Box>
                <Box>
                  <span onClick={() => handleAddRow()}>
                    <AddCircle
                      style={{ verticalAlign: "bottom", marginRight: "10px" }}
                    />
                    Add Filter
                  </span>
                </Box>
              </Box>
            </CardHeader>
            <CardBody>
              <FilterTable
                tableHeaderColor="primary"
                tableHead={[
                  "Filter Name",
                  "Level Name",
                  "Color",
                  "Width",
                  "Min Value",
                  "Max Value",
                  "Filter ID",
                  "Active",
                  "Actions",
                ]}
                tableData={convertFilterData(filterData)}
                handleDeleteRow={handleDeleteProject}
                handleEditRow={handleEditProject}
                handleUpdateSwitch={handleUpdateActive}
                showSwitch={true}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}
